
          @import "src/styles/scss/index.scss";
        
.root {
  display: flex;
  align-items: center;
  padding: 20px 50px 20px 20px;
  gap: 20px;

  border-bottom: 1px solid var(--borderGray);

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  color: var(--darkGray);
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }
}

.tooltip {
  top: 120%;
}

.refresh {
  background-color: var(--main-static-white);
}

.tabs {
  margin-left: auto;
}
