
          @import "src/styles/scss/index.scss";
        
.tabs {
  margin: 0 auto 40px;
  display: flex;
  max-width: max-content;
  @include max-mobile {
    flex-direction: column;
    max-width: 100%;
  }
  button {
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:first-child {
      border-radius: 10px 0px 0px 10px;
    }
    &:last-child {
      border-radius: 0px 10px 10px 0px;
    }
    &:not(:first-child) {
      margin-left: -1px;
    }
    @include max-mobile {
      &:first-child {
        border-radius: 10px 10px 0px 0px;
      }
      &:last-child {
        border-radius: 0px 0px 10px 10px;
      }
      &:not(:first-child) {
        margin-top: -1px;
        margin-left: 0;
      }
    }
  }
}

.container {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    justify-content: center;
  }
}
