
          @import "src/styles/scss/index.scss";
        
.wrapper {
  padding: 19px 20px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--borderGray);
  background: var(--white);
  border-radius: 10px 10px 0 0;

  @media (max-width: 640px) {
    padding: 15px;
    min-height: 0;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin-bottom: 15px;
    background: var(--white);
  }

  &--suspend {
    @media (max-width: 640px) {
      display: none;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 32px;
    color: var(--black);

    @media (max-width: 640px) {
      display: none;
    }

    b {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.36px;
      text-align: left;
    }
  }

  &__balance {
    display: flex;
    align-items: center;
    gap: 10px;

    .balance {
      &__subtitle {
        font-size: 12px;
        color: var(--middleGray);
      }

      &__title {
        color: var(--black);
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}
