
          @import "src/styles/scss/index.scss";
        
.dashboardNews {
  padding-top: 80px;
  padding-bottom: 50px;

  &__unborder {
    border-top: none;
    padding-top: 0;
  }

  @media (max-width: 920px) {
    padding-top: 0;
    margin-top: -20px;
    border-top: 0;
  }
}

.container {
  max-width: 1100px;
  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
}

.dashboardNewsInner {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 920px) {
    flex-direction: column;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    padding: 15px 0 0;
  }
}

.dashboardNewsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 32px;
    color: var(--black);
    transition: color 0.3s;
    font-weight: 700;
    &:hover {
      color: var(--orange);

      svg {
        color: var(--main-static-white);
      }

      button {
        background-color: var(--orange);
        border-color: var(--orange);
      }
    }
  }

  @media (max-width: 1024px) {
    font-weight: 700;
    color: var(--black);
    margin: 0 auto 0 0;
    text-align: left;
    border-radius: 10px 10px 0 0;
    border: none;
    width: 100%;
    padding: 13px 15px;

    .title {
      font-size: 18px;
      gap: 5px;
      button {
        margin-left: 10px;
      }
      line-height: 14px;
    }

    & > div:last-child {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 5px 15px;

    .title {
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
      text-transform: uppercase;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.dashboardNewsTabs {
  display: flex;
  align-items: flex-start;
  gap: 30px;

  margin: 30px 0;

  @media (max-width: 920px) {
    display: none;
  }
}

.dashboardNewsTabsNewsNumber {
  display: block;
  font-size: 14px;
  margin-left: 7px;
  line-height: 1;
  color: var(--middleGray);
}

.dashboardNewsTab {
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: var(--blue);
  border: none;
  border-color: transparent;
  background-color: transparent;
  padding: 0;
  padding-inline: 0;
  text-decoration: none;

  &:not(.dashboardNewsTab__active) {
    &:hover {
      color: var(--orange);
    }
  }

  &__active {
    color: var(--black);
    span {
      position: relative;
      &:before {
        content: '';
        height: 4px;
        width: 100%;
        position: absolute;
        background-color: var(--orange);
        left: 0;
        right: 0;
        bottom: -12px;
        border-radius: 6px;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.dashboardNewsItem {
  width: 100%;
  max-width: calc((100% - 40px) / 3);
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: 1px solid var(--borderGray);
  transition: all 0.3s;
  overflow: hidden;
  border-radius: 10px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 920px) {
    width: 100%;
    max-width: none;
    margin-right: 0;
    padding: 15px;

    &:hover {
      border-bottom: 1px solid var(--borderGray);
      box-shadow: none;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.dashboardNewsContent {
  position: relative;
  max-height: 240px;
  overflow: hidden;
  background-color: var(--white);
  padding: 20px 20px 25px;
  transition: border-color 0.3s;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 26px;
    background: var(--news-gradient);
  }

  @media (max-width: 920px) {
    border: none;
    padding: 0;
    margin-top: 12px;
    height: 160px;
    max-height: none;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--black);
    transition: all 0.3s;

    @media (max-width: 920px) {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: var(--darkGray);
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    @media (max-width: 920px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--darkGray);
      margin-top: 5px;
    }
  }
}

.dashboardNewsCategory {
  border: 1px solid #a033cc;
  color: #a033cc;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 5px;
  display: inline-block;
  margin-bottom: 15px;
  text-transform: uppercase;

  @media (max-width: 920px) {
    margin-bottom: 9px;
  }
}

.dashboardNewsCategoryBlue {
  color: var(--blue);
  border: 1px solid var(--blue);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  padding: 3px 5px;
  display: inline-block;
  margin-bottom: 15px;
  text-transform: uppercase;

  @media (max-width: 920px) {
    margin-bottom: 9px;
  }
}

.dashboardNewsImage {
  width: 100%;
  height: 170px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 920px) {
    height: 250px;
  }

  @media (max-width: 920px) {
    img {
      border-radius: 5.2994px;
    }
  }

  @media (max-width: 768px) {
    height: auto;

    img {
      height: auto;
      object-fit: normal;
    }
  }
}

.dashboardNewsSubtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;

  color: var(--darkGray);

  @media (max-width: 920px) {
    display: none;
  }
}

.dashboardNewsFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--white);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: border-color 0.3s;
  color: var(--darkGray);
  padding: 20px;

  @media (max-width: 920px) {
    border: none;
    padding: 0;
  }
}

.dashboardNewsTags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--blue);
    text-decoration: none;
    padding-right: 15px;
    transition: all 0.3s;

    @media (max-width: 920px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
    }

    &:hover {
      color: var(--orange);
    }
  }
}

.dashboardNewsPublished {
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 10px 0 0;

  color: var(--darkGray);

  span {
    font-weight: 400;
    padding-left: 10px;
  }
}

.dashboardNewsItemLink {
  text-decoration: none;
  flex: 1;
  transition: all 0.3s;

  &:hover {
    h3 {
      color: var(--orange);
    }
  }
}

.dashboardShowMore {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: var(--blue);
  margin: 30px auto;
  display: none;
  width: fit-content;

  svg {
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
    color: var(--orange);
  }

  @media (min-width: 920px) {
    display: block;
  }
}

.dashboardSocials {
  @media (max-width: 768px) {
    padding: 15px;
    margin: 0 -15px;
    border-bottom: 1px solid var(--borderGray);

    * {
      gap: 15px !important;
    }
  }

  @media (max-width: 370px) {
    * {
      gap: 0 !important;
      justify-content: space-between;
    }
  }
}

.dashboardNewsCenter {
  display: none;

  @media (max-width: 920px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04px;
    text-transform: uppercase;
    color: var(--blue);
    padding: 16px 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.3s;

    &:hover {
      color: var(--orange);

      svg {
        fill: var(--orange);
      }
    }

    svg {
      transition: all 0.3s;
      margin-left: 10px;
      width: 20px;
      height: 14px;
      fill: var(--blue);
    }
  }
}

.slice {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px -20px 0;
  border-radius: 0 0 10px 10px;
  cursor: pointer;
  padding: 30px;
  border-top: 1px solid var(--borderGray);
  background: var(--lightGray);
  svg {
    transition: color 0.3s;
  }

  &:hover {
    span,
    svg {
      color: var(--orange);
    }
  }

  span {
    transition: all 0.3s;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: var(--blue);
    margin-right: 8px;
  }

  @media (max-width: 920px) {
    margin: 20px -10px 0;
    padding: 20px;
  }
}
.wrapper .card {
  border-radius: 10px 10px 0 0;
  box-shadow: none;
  padding-bottom: 15px;

  @media (min-width: 1024px) {
    padding-bottom: 30px;
  }
}

.wrapper {
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  margin-bottom: 60px;
}
.buttons {
  padding: 15px;
  width: 100%;
  background: var(--alpha-grey-065);
  display: flex;
  margin-top: -15px;
  border-radius: 0 0 10px 10px;

  button {
    border: none;
  }

  @media (min-width: 768px) {
    margin-top: -20px;
  }
  a {
    flex-grow: 1;
    @media (min-width: 1024px) {
      flex-grow: unset;
      min-width: 300px;
    }
  }
  @media (min-width: 1024px) {
    padding: 20px;
    justify-content: center;
    background: var(--alpha-grey-065);
    border-top: 1px solid var(--borderGray);
  }
}
