
          @import "src/styles/scss/index.scss";
        
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 20px;
    width: 90px;
    height: 90px;
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  }
  h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 48px;
    line-height: 125%;
    text-align: center;
    color: var(--black);
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 131%;
      margin-bottom: 15px;
    }
  }
  p {
    text-align: center;
    color: var(--darkGray);
    font-size: 18px;
    line-height: 155%;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 171%;
    }
  }
}
