
          @import "src/styles/scss/index.scss";
        
.list__container {
  max-width: 260px;
}

@media (max-width: 1024px) {
  .list__container {
    padding: 0;
    border: none;
    background: transparent;
    max-width: none;
  }

  .date {
    width: 100%;
    display: none;
    & > * {
      margin: 0;
      height: 40px;
      min-width: 300px;
    }
  }
}
.cardHeader {
  @media (max-width: 1024px) {
    border-bottom: none;
    padding-bottom: 0;
    & > span {
      padding: 20px 15px;
    }
  }
}
.header {
  display: flex;
  gap: 20px;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    padding: 0 15px;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
  }
  & > div {
    min-width: 260px;
  }
}
.title {
  display: flex;
  justify-content: space-between;
}
.total_mob {
  color: var(--black);
  font-size: 20px;
  @media (min-width: 1024px) {
    display: none;
  }
}
