
          @import "src/styles/scss/index.scss";
        
.container {
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: var(--card-shadow);
  margin-bottom: 20px;
}

.wrapper {
  padding: 0 20px 40px 20px;
  @media (min-width: 1024px) {
    padding: 40px;
  }
}

.id {
  text-align: center;
  color: var(--black);
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;

  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  background: var(--lightGray);
  flex-shrink: 0;
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 670px;
  }
}

.center {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    gap: 20px;
  }

  .buttons {
    flex-grow: 1;
  }

  button {
    width: 100%;
  }
}

.btn {
  display: flex;

  width: 100%;
  margin: 0 auto;

  &:last-child {
    margin-top: 10px;
    @media (min-width: 1024px) {
      margin-top: 20px;
    }
  }
}

@media (max-width: 500px) {
  .id {
    font-size: 48px;
    line-height: 48px;
  }
}

.bottom {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  margin-top: 40px;
}

.top {
  display: flex;
  padding: 20px;

  @media (min-width: 1024px) {
    border-bottom: 1px solid var(--borderGray);
  }
}

.label {
  color: var(--black, #000);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  @media (min-width: 768px) {
    font-size: 32px;
  }
}
.back {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--main-static-white);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  background: var(--alpha-grey-065);
  border-radius: 30px;
  padding: 5.5px 10px;
  &:hover {
    color: var(--background-color, #f7931a);
  }
}
