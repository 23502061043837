
          @import "src/styles/scss/index.scss";
        
.block {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
}
