
          @import "src/styles/scss/index.scss";
        
.img {
  // display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  animation: fadeEffect 1s;
  background-color: var(--borderDark);
  object-fit: cover;
}

.container {
  animation: fadeEffect 1s;
  position: relative;
  &:hover {
    .hover {
      opacity: 1;
      pointer-events: all;
    }
  }
}
.icon {
  margin-right: 8px;
  color: var(--blue);
}
.hover {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--blue);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
}
