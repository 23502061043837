
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.sm_image {
  min-height: 191px;
}

@media (max-width: 1100px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
}
.linkStyle {
  padding-top: 56.25%; /* 16:9 */
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-top: -1px;
}
