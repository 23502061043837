
          @import "src/styles/scss/index.scss";
        
.card {
  --card-radius: 10px;
  background-color: var(--white);
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
  margin-bottom: 20px;

  &_header {
    border-top-right-radius: var(--card-radius);
    border-top-left-radius: var(--card-radius);
  }

  &.with_footer {
    margin: 0;
    margin-bottom: 20px;
    background: none;
    .card_content {
      background: var(--white);
      border-radius: 0;
    }
    .header {
      background: var(--white);
      border-top-right-radius: var(--card-radius);
      border-top-left-radius: var(--card-radius);
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
}
.header {
  border-bottom: 1px solid var(--borderGray);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > span {
    font-weight: 700;
    width: 100%;
    font-size: 32px;
    color: var(--black);
    line-height: 37px;
    width: fit-content;
  }
}

.footer {
  background: var(--alpha-grey-065);
  border-radius: 0 0 var(--card-radius) var(--card-radius);
  padding: 20px;
  button {
    border: none;
  }
}

@media (max-width: 1024px) {
  .header {
    text-align-last: left;
    padding: 0;

    & > span {
      font-size: 14px;
      line-height: 14px;
      padding: 13px 15px;
      text-transform: uppercase;
      width: 100%;
    }
    flex-direction: column;
    align-items: flex-start;
  }
}
