
          @import "src/styles/scss/index.scss";
        
.list__container {
  max-width: 260px;
}

@media (max-width: 1024px) {
  .list__container {
    padding: 0;
    border: none;
    background: transparent;
    max-width: none;
  }
}
