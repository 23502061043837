
          @import "src/styles/scss/index.scss";
        
.top {
  padding: 0 20px;
}

.container {
  text-align: center;
  padding: 40px 40px 0;
}

.section {
  max-width: 1100px;
  text-align: center;
  background: var(--white);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
}
.title {
  font-size: 48px;
  line-height: 64px;
  font-weight: bold;
  color: var(--black);
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  line-height: 28px;
  color: var(--darkGray);
  margin: 0 auto 40px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  a {
    background: var(--white);
    text-decoration: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--blue);
    border: 1px solid var(--borderGray);
    border-radius: 6px;
    transition-property: border-color, background, color;
    transition-duration: 0.3s;

    &:hover {
      border-color: var(--orange);
      background: var(--orange);
      color: var(--white);
    }
  }
}

.img {
  position: relative;
  display: block;
  left: 50%;
  width: 100%;
  max-width: none;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .container {
    padding: 30px 15px;
  }

  .title {
    font-size: 28px;
    line-height: 31px;
  }

  .text {
    font-size: 16px;
    line-height: 155%;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 350px;
    margin: 0 auto;
    gap: 10px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
  }
}
