
          @import "src/styles/scss/index.scss";
        
.withdrawal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 540px) {
    padding: 20px 10px;
  }

  .decor {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__card {
    max-width: 700px;
    position: relative;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    z-index: 1;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      border-radius: 10px 10px 0 0;

      @media screen and (max-width: 540px) {
        padding: 15px;
      }

      &__title {
        font-size: 32px;
        line-height: 36px;
        font-weight: 700;
        color: var(--black);
      }
    }

    &__warning {
      font-size: 14px;
      line-height: 26px;
      font-weight: 400;
      color: var(--black);
    }

    &__header + &__body {
      border-top: 1px solid var(--borderGray);
    }

    &__header,
    &__body {
      background: var(--white);
    }

    &__body {
      padding: 30px;

      @media screen and (max-width: 540px) {
        padding: 15px;
      }
    }

    &__body + &__footer {
      border-top: 1px solid var(--borderGray);
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      padding: 20px;
      background-color: var(--alpha-grey-065);
      border: none;
      border-radius: 0 0 10px 10px;

      @media screen and (max-width: 540px) {
        flex-direction: column;
      }
    }
  }

  &__transaction {
    margin-top: 20px;
    padding: 20px 30px;
    border: 2px solid;
    font-size: 16px;
    line-height: 25px;
    color: var(--black);
    word-break: break-all;
    border-radius: 10px;

    a {
      color: var(--blue);
      text-decoration: none;
    }

    @media screen and (max-width: 480px) {
      font-size: 12px;
    }

    &__row {
      position: relative;
      color: var(--gray-2);
    }

    b {
      font-weight: 700;
    }

    > * + * {
      margin-top: 15px;
    }
  }

  &__btn {
    min-width: 300px;

    svg {
      transform: rotate(180deg);
    }

    &__history {
      margin-left: -20px;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__note {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    font-weight: 700;
    font-size: 16px;
    color: var(--black);
    line-height: 26px;

    @media screen and (max-width: 540px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }

  &__blockchain_link {
    max-width: none;
    margin: 20px 0 0;
    padding-inline: 0;
    text-transform: none;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #4285f4;
    font-weight: 400;
    text-decoration: underline;
    overflow: hidden;
  }
}

.label__logo {
  margin-left: 10px;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 0;
}

@media (max-width: 640px) {
  .withdrawal {
    background: transparent;
    align-items: flex-start;
    padding: 15px;
    min-height: auto;

    &__transaction {
      margin-top: 0;
    }

    &__card {
      background: transparent;

      &__body {
        background: var(--white);
        padding: 15px;
        border-top: none !important;
      }

      &__header {
        background: var(--white);
        border-radius: 10px 10px 0 0;
        padding: 15px 15px 0;
        border: none;

        &__title {
          display: none;
        }
      }

      &__footer {
        border-radius: 0 0 10px 10px;
        padding: 15px;
        flex-direction: column-reverse;
        background: var(--static-transparent-bg);

        button,
        a {
          width: 100%;
        }
      }
    }
  }
}

.blue {
  background-color: var(--Blue_BG);
  border-color: var(--blue);
}
.red {
  background-color: var(--Red_BG);
  border-color: var(--error);
}
.gold {
  background-color: var(--Orange_BG);
  border-color: var(--orange);
}
.gray,
.default {
  background-color: var(--gray-8);
  border-color: var(--gray-4);
}
.success {
  background-color: var(--Green_BG);
  border-color: var(--success);
}
