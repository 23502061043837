
          @import "src/styles/scss/index.scss";
        
.back {
  display: none;
  @include max-mobile {
    position: absolute;
    align-self: flex-end;
    background: none;
    border: none;
    color: var(--primary-white);
    background: var(--alpha-grey-065);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    height: 35px;
    width: 40px;
  }
}
