
          @import "src/styles/scss/index.scss";
        
.icon {
  display: block;
  margin: 0 auto 20px;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 15px;
  text-align: center;
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;
  color: var(--darkGray);

  a {
    font-weight: bold;
    color: var(--blue);
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: var(--orange);
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 155%;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .btn {
    min-width: 320px;
  }

  @media (max-width: 768px) {
    div[class*='arrow'] {
      display: none;
    }

    .btn {
      width: 100%;
      min-width: auto;
    }
  }
}
