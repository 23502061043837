
          @import "src/styles/scss/index.scss";
        
.container {
  position: relative;
  background-color: var(--pergament);
  padding: 50px 0 50px 50px;
  overflow: hidden;
  text-align: left;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.cross {
  top: 15px;
  right: 15px;
  position: absolute;
  color: var(--textGray);
  cursor: pointer;
}

.logo {
  color: var(--black);
}

.title {
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 10px;
  margin-top: 30px;
  font-weight: bold;
  color: var(--black);
}

.text {
  font-size: 16px;
  line-height: 26px;
  color: var(--middleGray);
  margin-bottom: 30px;

  & + a {
    max-width: 300px;
    width: 100%;
  }
}

.screen {
  margin-right: -140px;
}

.new {
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 1;
  background-color: var(--success);
  color: var(--white);
  width: 70px;
  min-height: 26px;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    display: block;
    border-top: 13px solid var(--success);
    border-bottom: 13px solid var(--success);
    border-right: 10px solid transparent;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 40px 30px;
  }

  .screen {
    margin-right: 0;
    margin-top: 40px;
    max-width: 380px;
    width: 100%;
  }

  .text + a {
    max-width: none;
    width: 100%;
  }

  .title {
    font-size: 28px;
    line-height: 40px;
  }
}
.section {
  margin-top: 20px;
}
