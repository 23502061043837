
          @import "src/styles/scss/index.scss";
        
.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 50px;
  padding-block: 14px;
  padding-inline: 20px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  background-color: var(--white);
  color: var(--blue);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 700;
  gap: 10px;
  text-decoration: none;
  text-align: center;
  transition-property: background-color, border-color, color, opacity;
  transition: 0.1s linear;

  > * {
    margin: 0;
  }

  &:visited,
  &:focus {
    outline: none;
  }

  &:not(.btn__text):hover {
    color: var(--primary-white);
    border-color: var(--orange);
    background: var(--orange);
  }

  &:not(.btn__text, .btn__primary, .btn__active, .no_svg_colorized):hover {
    svg path[stroke] {
      stroke: var(--primary-white);
    }
  }

  &:not(.btn__text):active {
    opacity: 0.8;
  }

  &__inverted {
    border: 1px solid var(--borderGray);
    background-color: var(--main-static-white);
    color: var(--blue);
  }
  &__danger {
    border: none;
    background-color: var(--error);
    color: var(--main-static-white);
  }

  &__primary,
  &__active {
    border-color: var(--blue);
    background-color: var(--blue);
    color: var(--primary-white);

    &:not(.btn__text):hover {
      border-color: var(--orange);
      background-color: var(--orange);
      color: var(--primary-white);
    }
  }

  &__tab_active {
    background-color: transparent;
    position: relative;
    border: none;
    border-color: transparent;

    span {
      color: var(--black);
    }

    &:before {
      content: '';
      height: 4px;
      width: 100%;
      position: absolute;
      background-color: var(--orange);
      left: 0;
      right: 0;
      bottom: -12px;
      border-radius: 6px;
    }
  }

  &__text {
    border-color: transparent;
    background-color: transparent;
    color: var(--blue);

    &:hover {
      color: var(--orange);
    }
  }

  &:disabled {
    pointer-events: none;
    color: var(--primary-white);
    border-color: var(--borderGray);
    background: var(--gray-6);
  }
  &__transparent {
    border-color: rgba(117, 117, 117, 0.7);
    background-color: transparent;
    color: var(--blue);
    &:disabled {
      border-color: rgba(117, 117, 117, 0.7);
      background-color: transparent;
      color: rgba(117, 117, 117, 0.7);
    }
  }
  &__only_icon {
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-width: 20px;
    }
    @media (max-width: 768px) {
      border: none;
    }
  }
  &:hover:disabled {
    pointer-events: none;
    color: #b3b3b3;
    border-color: #d6d6d6;
    background: #d6d6d6;
  }

  &__disabled_dark {
    border-color: var(--gray-3);
    background-color: var(--gray-3);
    color: var(--white);
    pointer-events: none;
  }

  &__is_loading {
    background-color: var(--gray-3);
    pointer-events: none;

    &:hover {
      background-color: var(--gray-3);
    }

    .is_loading {
      &__spinner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;

        i {
          border-radius: 100%;
          width: 9.6px;
          height: 9.6px;
          opacity: 0.6;
          background-color: var(--white);

          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              animation: loading 1s #{$i * 0.2}s infinite linear alternate;
            }
          }

          @keyframes loading {
            from {
              opacity: 0.1;
            }
            to {
              transform: scale(1.5);
              opacity: 1;
            }
          }
        }
      }
    }

    svg,
    span {
      display: none;
    }
  }
}

.btn__medium {
  padding-block: 0;
  height: 50px;
}
.btn__small {
  padding-block: 10px;
  padding-inline: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 6px;
  height: 40px;
}
