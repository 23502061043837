
          @import "src/styles/scss/index.scss";
        
.container {
  margin-top: auto;
  position: relative;
  // z-index: 2;
}

.row {
  display: flex;
  gap: 10px;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;
}

.link {
  background: var(--lightGray);
  border: 1px solid var(--borderGray);
  color: var(--black);
  border-radius: 6px;
  padding: 16px 10px;
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  b {
    font-size: 16px;
  }
}

.common_list {
  background: var(--white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100% !important;
  min-width: unset;
  z-index: 1000;
  padding: 0;
  top: 30px;
  left: 0;

  & > * {
    padding: 10px 20px;
    border-bottom: 1px solid var(--borderGray);
    font-size: 14px;
    line-height: 16px;
    transition: all 0.3s;
    cursor: pointer;
    color: var(--black);

    &:hover {
      color: var(--white);
      background-color: var(--blue);

      svg path {
        fill: var(--white);
      }
    }
  }

  &_tag {
    background: var(--lightGray);
    border: 1px solid var(--borderGray);
    border-radius: 20px;
    padding: 4px 10px;
    color: var(--middleGray);
    text-transform: uppercase;
    transition: background-color 0.3s;
    cursor: pointer;

    div[class*='title'] {
      span {
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
      }
    }

    &_browser {
      background-color: var(--white);
      color: var(--blue);
    }

    &:hover {
      background-color: var(--orange);
      border-color: var(--orange);
      color: var(--white);
      & > * {
        color: var(--white);
      }
      div[class*='title'] > svg path {
        fill: #ffdfb8;
      }
    }
  }
}

.static {
  position: static;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  background: var(--lightGray);
  border: 1px solid var(--borderGray);
  border-radius: 20px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: var(--middleGray);
  text-transform: uppercase;
  transition: background-color 0.3s;
  cursor: pointer;

  &:not(.tab__disabled):hover {
    background-color: var(--orange);
    border-color: var(--orange);
    color: var(--white);
    & > * {
      color: var(--white);
    }
    div[class*='title'] > svg path {
      fill: #ffdfb8;
    }
  }

  &__active {
    background-color: var(--blue);
    border-color: var(--blue);
    color: var(--white);
    & > * {
      color: var(--primary-white);
    }
    div[class*='title'] > svg path {
      fill: #aacaff;
    }
  }

  &__disabled {
    color: var(--middleGray);
    pointer-events: none;
  }
}

.farmLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 12px;
  gap: 5px;
  &__link {
    color: var(--black);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__product {
    color: var(--middleGray);
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &:hover {
    .farmLink__link {
      color: var(--white);
    }
    .farmLink__product {
      color: #aacaff;
    }
  }
}

.landLink {
  display: flex;
  flex-direction: column;
  min-height: 45px;
  padding: 10px 12px;
  justify-content: center;

  &:hover {
    .landLink__id {
      color: var(--white);
    }
    .landLink__name {
      color: var(--white);
    }
    .landLink__comment {
      color: #97beff;
    }
  }
}

.landLink__id {
  color: var(--black);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.landLink__top {
  display: flex;
  align-items: center;
  gap: 36px;
}

.landLink__name {
  color: var(--darkGray);
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.landLink__comment {
  color: var(--middleGray);
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  color: var(--blue) !important;
  text-transform: uppercase;
  padding: 15px;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;

  &:hover {
    color: var(--white) !important;
  }
}
