
          @import "src/styles/scss/index.scss";
        
// @import '@shared/Button/Button.module.scss';

.container {
  padding: 20px 20px 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: clip;
  flex-grow: 1;
  position: relative;

  @media (max-width: 768px) {
    padding: 15px;
  }
}

.withdrawBannerDesktop {
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: none;
  }
}

.withdrawBannerMobile {
  display: none;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.decoration {
  position: absolute;
  top: 40px;
  opacity: 0;
  transition: opacity 0.5s;

  &--show {
    opacity: 1;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.label_banner {
  width: 57px;
  height: 32px;
  position: absolute;
  top: 10px;
  left: 50%;

  transform: translateX(-115%);

  & + img {
    position: relative;
    z-index: 2;
  }
}

.history_btn {
  padding: 0;
  font-size: 14px;
}

.placeholder {
  position: absolute;
  padding: 2px 8px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  font-size: 11px;
  line-height: 16px;
  color: var(--white);
  opacity: 0;
  visibility: hidden;
  text-transform: capitalize;
  transition: opacity 0.3s;
  width: max-content;
  font-weight: normal;
  z-index: 10;
}

.arrow {
  transform: rotate(180deg);
}

.back {
  border-radius: 10px;
}

.label__logo {
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  &_mob {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
}

.card {
  max-width: 700px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background: none;
  position: relative;
  z-index: 2;

  &__header,
  &__body {
    background: var(--white);
  }

  &__header {
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid var(--borderGray);
    padding: 20px 30px;
    color: var(--black);
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .header {
      &__text {
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;
      }
    }
  }

  &__balance {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .balance {
      &__svg {
        width: 40px;
        height: 40px;
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &__label {
        font-size: 12px;
        color: var(--middleGray);
      }

      &__total {
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 20px;
      }
    }
  }

  &__body {
    height: auto;
    padding: 30px 100px 10px;
  }

  &__footer {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 15px 15px;
    background-color: var(--alpha-grey-065);

    &--suspend {
      justify-content: center;
    }

    button,
    .suspend_btn {
      display: flex;
      max-width: 300px;
      width: 100%;
    }

    .suspend_icon {
      width: 20px;
      height: 20px;
    }
  }
}

button.withdraw__btn {
  color: var(--white);
  background-color: var(--blue);

  &:disabled {
    color: var(--white);

    svg path {
      color: var(--white);
    }
  }
}

.error {
  &__icon {
    display: block;
    margin: 0 auto 20px;
  }

  &__title {
    padding: 0 15px;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: var(--black);
    margin-bottom: 10px;
  }

  &__text {
    padding: 0 15px;
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    color: var(--middleGray);
    margin-bottom: 30px;
  }

  &__banner {
    @media (max-width: 640px) {
      padding: 0 15px 15px;
    }
  }
}

.subtitle {
  max-width: 600px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  line-height: 20px;
  margin-top: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: var(--alpha-grey);

  span {
    margin-right: 5px;
  }

  a {
    color: var(--white);
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: var(--orange);
    }
  }
}

.ncw {
  &__subtitle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
    color: var(--black);
    overflow: hidden;

    &_red {
      background: var(--error);
      border-radius: 10px;
      border: 1px solid var(--error);
      padding-left: 10px;

      p {
        padding: 10px;
        background: var(--Red_BG);
      }
    }

    svg {
      flex-shrink: 0;
    }
    a {
      font-weight: bold;

      &:hover {
        color: var(--orange);
      }
    }
  }
}

.group {
  margin-bottom: 30px;
  -webkit-animation: show 0.5s;
  -o-animation: show 0.5s;
  animation: show 0.5s;

  &__loader {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &_last {
    margin-bottom: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .buttons {
      &__svg {
        width: 20px;
        height: 20px;
      }

      &__switch {
        width: 160px;
        font-size: 14px;
        line-height: 16.95px;

        &--inactive {
          color: var(--black);
        }

        &--left,
        &--right {
          height: 40px;
        }

        &--left {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
        }

        &--right {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        }
      }
    }
  }

  &__money {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }

  &__min {
    width: max-content;
    flex-shrink: 0;
    font-size: 12px;
    color: var(--textGray2);
    line-height: 14.52px;
    font-weight: 400;
    text-align: left;

    &_btc {
      transition: color 0.3s;

      &:hover {
        color: var(--darkGray);
        cursor: pointer;
      }
    }

    .question {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      &__lock {
        width: 16px;
        height: 16px;
      }

      &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      &__logo {
        width: 12px;
        height: 12px;
        cursor: pointer;

        &:hover + .question__prompt {
          opacity: 1;
        }
      }

      &__prompt {
        border-radius: 6px;
        padding: 2px 8px;
        width: max-content;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20px;
        left: 4px;
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        color: var(--white);
        background: var(--gray1);
        opacity: 0;
        transition: 0.3s opacity;
        z-index: 1;
        pointer-events: none;

        @media (max-width: 640px) {
          left: -90px;
        }

        &_next {
          color: var(--orange);
        }
      }
    }
  }

  &__max,
  .question__logo {
    transition: 0.3s color;

    &:hover {
      color: var(--orange);
    }
  }

  &__labelWrapper {
    position: relative;
  }

  &__badge {
    display: none;

    @media (max-width: 768px) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      border-radius: 10px;
      border: 1px solid var(--orange);
      color: var(--orange);
      padding: 2px 5px;

      font-size: 10px;
      font-weight: 700;

      text-transform: uppercase;
    }
  }

  label {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--black);
    margin-bottom: 10px;
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    background: transparent;
    color: var(--black);
    line-height: 18px;

    &.input_count {
      font-weight: bold;
      font-size: 20px;

      &--error {
        color: var(--error);
      }
    }

    &:disabled {
      background-color: var(--lightGray);
    }

    &::placeholder {
      color: var(--textGray);
      font-weight: normal;
    }
  }

  &__select_arr {
    transition: transform 0.5s;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 10px;
  }

  &__select {
    padding: 15px 13px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: end;
    align-self: stretch;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 15;

    &_arr {
      path {
        transition: stroke 0.3s;
      }
    }

    &:hover {
      .group__select_arr path {
        stroke: var(--orange);
      }
    }

    @media (max-width: 768px) {
      &_arr {
        path {
          color: var(--borderGray);
        }
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 12px 14px;
    border-radius: 10px;
    border: 1px solid var(--borderGray);
    position: relative;
    transition: border 0.3s;

    &:has(input:disabled) {
      background-color: var(--lightGray);
    }

    &:has(input:focus) {
      border: 1px solid var(--blue);
      background-color: var(--white);
    }
  }

  &__max {
    width: 100%;
    text-align: end;
    font-size: 12px;
    font-weight: bold;
    color: var(--black);
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
      color: var(--orange);

      .placeholder {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__info {
    font-size: 12px;
    line-height: 14px;
    margin-top: 12px;
    color: var(--middleGray);

    &_error {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      color: var(--error);

      &--left {
        justify-content: flex-start;
      }
      &--center {
        justify-content: center;
      }
      &--right {
        justify-content: flex-end;
      }
    }

    .info_error {
      &__svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__list {
    position: absolute;
    display: block;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: 0;
    overflow: scroll;
    border-radius: 6px;
    background: var(--white);
    z-index: 11;
    opacity: 0;
    transition: max-height 0.3s, opacity 0.5s;

    &--show {
      max-height: 300px;
      opacity: 1;
      box-shadow: 0 2px 8px rgb(0 0 0 / 20%);

      @media (max-width: 768px) {
        max-height: 140px;
      }
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-moz-scrollbar {
      width: 0;
      height: 0;
    }

    &_item {
      padding: 16px 19px;
      border-bottom: 1px solid var(--borderGray);
      font-size: 16px;
      line-height: 19.36px;
      cursor: pointer;
      color: var(--black);
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: background 0.3s;

      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }

      &:not(.group__list_item_text):hover {
        background-color: var(--blue);
        color: var(--primary-white);
        svg {
          color: var(--primary-white);
        }

        .recent {
          color: var(--primary-white);
          border-color: var(--primary-white);
        }
      }

      &_text {
        font-size: 14px;
        line-height: 24px;
        padding: 20px 15px;
      }

      &:last-child {
        border: none;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        color: var(--blue);
        transition: color 0.3s;
        text-transform: uppercase;
        font-size: 14px;
        svg {
          width: 16px;
          height: 16px;
        }
        svg path {
          transition: fill 0.3s;
        }

        &:hover {
          background-color: transparent;
          color: var(--orange);
          svg path {
            fill: var(--orange);
          }
        }
      }

      &_btn_disabled {
        pointer-events: none;
        color: var(--borderGray) !important;
        cursor: auto;

        svg path {
          fill: var(--borderGray);
        }
      }
    }

    &_address {
      max-width: calc(100%);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_container {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 10px;

      &--recent {
        max-width: 80%;

        @media (max-width: 768px) {
          max-width: 65%;
        }
      }
    }
  }
}

.recent {
  padding: 2px 5px;
  height: 16px;
  width: max-content;
  border: 1px solid var(--orange);
  text-transform: uppercase;
  color: var(--orange);
  border-radius: 13px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  text-align: left;
}

.wait {
  border-bottom: 1px solid var(--borderGray);
  background-color: var(--lightGray);
  padding: 20px 30px;

  @media (max-width: 768px) {
    padding: 15px 20px;
  }

  &__icon {
    width: 22px;
    height: 22px;
    display: block;
    margin-right: 8px;
  }

  &__icon_info {
    margin-right: 0;
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }

  &__title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--black);
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    font-weight: 700;
    height: 40px;
    font-size: 16px;
    line-height: 18px;
    background-color: var(--white);
    border-radius: 30px;
    border: 1px solid var(--borderGray);
    transition: border-color 0.3s;

    &:hover {
      button svg path {
        fill: var(--orange);
      }
    }

    &_link {
      position: relative;

      .placeholder {
        top: calc(100% + 7px);
        right: 0;
      }

      &:hover {
        .placeholder {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_cross {
      position: relative;

      .placeholder {
        top: calc(100% + 7px);
        left: 0;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--black);
    }

    a > svg {
      width: 22px;
      height: 22px;
      display: block;
      margin-right: 8px;
    }

    button {
      border: none;
      outline: none;
      background: none;
      margin-left: 8px;
      padding: 14px 4px 14px 10px;
      border-left: 1px solid var(--borderGray);
      transition: border-color 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 10px;
        height: 10px;
      }
      svg path {
        fill: var(--secondGray);
        transition: fill 0.3s;
      }

      &:hover {
        svg path {
          fill: var(--orange);
        }
      }
    }

    &:hover {
      border-color: var(--orange);

      button {
        border-color: var(--orange);
      }
    }
  }
}

.captcha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  &--disable {
    display: none;
  }

  &__error {
    font-size: 12px;
    line-height: 14px;
    margin-top: 12px;
    text-align: center;
    color: var(--error);
  }
}

.limit {
  &__icon {
    display: block;
    margin: 0 auto 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    color: var(--black);
  }

  &__description {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: var(--middleGray);
  }
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &_mob {
    margin-right: 5px;
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    display: none;
    justify-content: center;
    align-items: center;
  }
}

.label {
  margin-left: auto;
  display: none;
}

.input_error {
  border-color: var(--error);
}

@media (max-width: 640px) {
  .recent_hide {
    display: none;
  }

  .label {
    display: block;
    height: 16px;
    line-height: 12.1px;
    padding: 2px 5px;
    font-size: 10px;
  }
  .card {
    background: transparent;
    box-shadow: none;

    &__header,
    &__body {
      padding: 14px;
      border-radius: 10px 10px 0 0;
      font-size: 28px;
      background: var(--white);
    }

    &__body {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    }

    &__footer {
      border-top: none;
      flex-direction: row-reverse;
      gap: 10px;
      border-radius: 0 0 10px 10px;
      padding: 15px;
      background: var(--static-transparent-bg);
      border-top: none;

      button,
      .suspend_btn {
        border-radius: 10px;
        width: 100%;
        max-width: none;
      }

      a:not(.suspend_btn) {
        width: 50px;
        height: 50px;
        background: var(--primary-white);
        border-radius: 10px;
        flex-shrink: 0;

        span {
          display: none;
        }
      }
    }
  }

  .icon {
    display: none;

    &_mob {
      display: flex;
    }
  }

  .container {
    padding: 15px;
  }

  .group {
    &_mob {
      margin: 0 -14px 15px;
      padding: 0 14px 14px;
      border-bottom: 1px solid var(--borderGray);
    }

    &__buttons {
      .buttons {
        &__switch {
          width: 50% !important;
        }
      }
    }

    label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }

    &__container {
      padding: 12px 15px;
      background: var(--white);

      input {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__disabled {
      background: var(--lightGray);
    }

    &__select {
      margin-left: auto;
    }

    &__footer {
      margin-top: 13px;
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

[dark-theme='true'] {
  .question__prompt {
    color: var(--black);
  }

  .subtitle {
    a {
      color: var(--black);
    }
  }
}
