
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  flex-grow: 1;
}

.card {
  max-width: 600px;
  width: 100%;
  border-radius: 6px;
  background-color: var(--white);
  border: 1px solid var(--borderGray);
}

.header {
  padding: 20px 30px;
  font-weight: bold;
  color: var(--black);
  font-size: 32px;
  line-height: 40px;
  border-bottom: 1px solid var(--borderGray);
}

.body {
  padding: 20px 30px 40px;

  &__text {
    font-size: 16px;
    line-height: 26px;
    color: var(--darkGray);
    margin-bottom: 28px;
  }
}

.group {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: var(--black);
  }

  &__subtext {
    font-size: 12px;
    line-height: 14px;
    margin-top: 15px;
    color: var(--darkGray);
  }

  input {
    display: block;
    outline: none;
    width: 100%;
    color: var(--black);
    background: transparent;
    border-radius: 6px;
    border: 1px solid var(--borderGray);
    padding: 15px;
    font-size: 16px;
    transition: border-color 0.3s;

    &:focus {
      border-color: var(--blue);
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 0px 0px 6px 6px;
  background-color: var(--lightGray);
  border-top: 1px solid var(--borderGray);

  &__btn {
    max-width: 300px;
    display: flex;
    width: 100%;
  }
}

.error {
  height: 220px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  span {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0;
  }

  .card {
    max-width: none;
    border: none;
    border-radius: 0px;
  }

  .header {
    padding: 15px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    font-size: 18px;
    line-height: 22px;
  }

  .body {
    padding: 20px 15px;
  }

  .footer {
    &__btn {
      max-width: none;
    }
  }
}
