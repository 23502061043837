
          @import "src/styles/scss/index.scss";
        
.container {
  box-shadow: 0 2px 8px 0 #00000033;
  display: flex;
  padding: 15px;
  border-radius: 10px;
  min-width: 345px;
  gap: 15px;
  align-items: center;

  & > svg:first-child {
    flex-shrink: 0;
  }
}

.info {
  background: var(--additional-blue);
}

.success {
  background: var(--additional-success);
}

.error {
  background: var(--additional-error);
}

.warning {
  background: var(--additional-warning);
}

.text {
  font-weight: bold;
  color: var(--main-static-white);
  font-size: 14px;
  line-height: 16px;
}

.cross {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  flex-shrink: 0;
  border: 2px solid rgba(190, 193, 200, 0.2);
  margin-left: auto;
  cursor: pointer;
  position: relative;
}

.svg {
  width: 24px;
  height: 24px;
  top: -2px;
  left: -2px;
  position: absolute;
}
