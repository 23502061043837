
          @import "src/styles/scss/index.scss";
        
.lang {
  display: block;
  width: 70px;
  position: relative;
  z-index: 100;
  text-align: left;
  flex-shrink: 0;
  -ms-flex-negative: 0;
}

.lang__current {
  display: flex;
  align-items: center;
  min-height: 30px;
  position: relative;
  line-height: 30px;
  padding-left: 30px;
  background: var(--white);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  font-size: 13px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: #323232;
  cursor: pointer;
  text-transform: uppercase;
  overflow: hidden;
}

.lang__current:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 13px;
  right: 7px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #d0d0d0;
}

.opened .lang__current:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 12px;
  right: 7px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: none;
  border-bottom: 4px solid #d0d0d0;
}

.lang__current:hover {
  color: var(--blue);
}

.lang__current img {
  display: block;
  position: absolute;
  top: 9px;
  left: 9px;
}

.lang__list {
  display: none;
  list-style-type: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  background: var(--white);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  position: absolute;
  width: 100%;
  margin-top: 5px;
}

.lang.opened .lang__list {
  display: block;
}

.lang__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 28px;
  padding-left: 9px;
  line-height: 28px;
  color: #323232;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  img {
    width: 16px !important;
  }
}

.lang__item:hover {
  color: var(--white);
  background-color: var(--blue);
}

.lang__item img {
  margin-right: 6px;
  vertical-align: baseline;
}

.footer {
  .lang__list {
    transform: translateY(calc(-100% - 40px));
  }

  .lang__current {
    color: var(--white);
    background: #282828;
    border: 1px solid var(--middleGray);
  }

  .lang__item {
    img {
      width: 16px !important;
    }
  }
}

.forMobile {
  @media (min-width: 801px) {
    display: none;
  }
}

@media (max-width: 801px) {
  .lang__list {
    transform: translateY(calc(-100% - 40px));
    margin-top: 0;
  }

  .footer:not(.forMobile) {
    display: none;
  }
}
