
          @import "src/styles/scss/index.scss";
        
// @import '@shared/Button/Button.module.scss';

.wrapper {
  padding: 60px 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px 15px;
    justify-content: start;
  }

  &__container,
  &__footer,
  &__subtitle {
    width: 100%;
    max-width: 700px;
    position: relative;
    z-index: 1;
  }

  .decoration {
    position: absolute;
    top: 40px;
    -webkit-animation: show 0.5s;
    -o-animation: show 0.5s;
    animation: show 0.5s;

    @keyframes show {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__container {
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);

    @media (max-width: 768px) {
      border-radius: 10px 10px 0 0;
    }
  }

  &__body {
    padding: 35px 100px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
      padding: 35px 15px 33px;
    }
  }

  &__logo {
    width: 70px;
    height: 70px;
  }

  &__title {
    margin-top: 26px;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.09142857044935226px;
    color: var(--black);

    @media (max-width: 768px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      text-align: center;
    }
  }

  &__text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--darkGray);
    text-align: center;

    @media (max-width: 768px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    & > b {
      color: var(--black);
    }
  }

  &__input {
    margin-top: 20px;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid var(--borderGray);
    width: 100%;
    height: 50px;
    background: transparent;
    color: var(--black);

    &:focus {
      border-color: var(--blue);
    }

    &--error {
      border-color: var(--error);
    }
  }

  &__btc-connect {
    margin-top: 20px;
    padding: 0;
    border: none;
    background: none !important;

    &:hover {
      color: var(--orange) !important;
    }
  }

  &__footer {
    padding: 18px;
    border-radius: 0 0 15px 15px;
    background: var(--alpha-grey-065);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    & > * {
      width: 100%;
      max-height: 50px;
    }

    & > *:disabled {
      color: var(--white);
    }

    @media (max-width: 768px) {
      padding: 15px;
      border: none;
      flex-direction: column-reverse;
      background: #171717a6;
    }
  }

  &__connect {
    &:disabled {
      border: none;
      background-color: #757575b2;
      color: var(--gray-6);
    }
  }

  &__subtitle {
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #171717a6;

    .subtitle {
      &__text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: var(--textGray);

        & > a {
          color: var(--white);
          text-decoration: none;
        }
      }
    }
  }
}

[dark-theme='true'] {
  .wrapper {
    .subtitle {
      &__text {
        & > a {
          color: var(--black);
        }
      }
    }
  }
}
