
          @import "src/styles/scss/index.scss";
        
.storeBtn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 60px;
  background-color: var(--primary-black);
  color: var(--primary-white);
  border-radius: 6px;
  transition: background-color 0.1s linear;

  &:not(&__disabled):hover {
    background-color: var(--orange);
  }

  &__disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &__label {
    position: absolute;
    top: -15%;
    right: 0;
    display: inline-block;
    padding: 5px 8px;
    border-radius: 10px;
    background-color: var(--darkGray);
    font-size: 10px;
    font-weight: 700;
  }

  &__text {
    color: var(--primary-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    margin-left: 10px;
  }
}
