
          @import "src/styles/scss/index.scss";
        
@import './color';
@import './animate';
@import './reset.scss';
@import './odometr';

// .ios {
//   .android-btn {
//     display: none;
//   }
// }

// .android {
//   .apple-btn {
//     display: none;
//   }
// }

.opacityFooterButtons {
  > button {
    border: none;
    &:disabled {
      background: var(--alpha-grey-065);
      color: var(--alpha-grey-static);
      svg {
        color: var(--alpha-grey-static);
      }
    }
  }

  > a {
    color: var(--primary-white);
    svg {
      color: var(--blue);
    }
  }
}

.bot {
  &.no-layout {
    @media (max-width: 700px) {
      div[class*='Header_'] {
        display: none;
      }
      div[class*='Subheader_'] {
        display: none;
      }
      div[class*='Footer_'] {
        display: none;
      }
    }
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html {
  background: #000;
}

input {
  user-select: all;
}

#root {
  min-height: 100vh;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;

  & > div {
    width: 100%;
  }
}
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.social-btns {
  row-gap: 10px;
}

.social-btn_fb {
  background-color: #1877f2 !important;

  &:before {
    width: 40px !important;
    height: 37px !important;
    top: auto !important;
    transform: translateX(-50%) translateY(0%) !important;
    bottom: -3px;
  }
}

div[data-size='l'] {
  .social-btn_fb {
    overflow: hidden;
    &:before {
      width: 60px !important;
      height: 57px !important;
      top: auto !important;
      transform: translateX(-50%) translateY(0%) !important;
      bottom: -5px;

      @media (max-width: 768px) {
        width: 40px !important;
        height: 37px !important;
        bottom: -3px;
      }
    }
  }
}

#commonAboutShare {
  .social-btn_eml {
    border: none;
    background-color: var(--orange);
    &:before {
      background-image: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z' fill='white' fill-rule='evenodd'/></svg>");
    }
  }

  .social-btn_pt {
    border: none;
    background: #bd081c;
    &:before {
      width: 36px;
      height: 36px;
      background-image: url("data:image/svg+xml,<svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='19.9831' cy='19.9936' r='18.7487' fill='%23BD081C' stroke='white' stroke-width='1.49989'/><path fill-rule='evenodd' clip-rule='evenodd' d='M13.7116 30.4477L15.4899 22.9972C15.4899 22.9972 15.6698 22.0972 15.2102 21.2939C15.2102 21.2939 14.1864 18.7348 16.2094 16.5653C16.2094 16.5653 17.8668 14.6884 19.9874 16.1024C19.9874 16.1024 21.1969 17.054 20.7095 18.8575L19.2696 23.3571C19.2696 23.3571 18.3902 26.7769 21.6447 27.0708C21.6447 27.0708 25.0817 27.5337 26.739 22.8781C28.3964 18.223 26.4691 15.2577 26.4691 15.2577C26.4691 15.2577 23.9903 10.9059 18.3902 11.8219C12.79 12.7379 12.082 18.0804 12.082 18.0804C12.082 18.0804 11.6615 21.3706 13.4178 23.7698L12.5769 26.6395C12.5769 26.6395 8.29037 23.8971 8.47036 18.971C8.47036 18.971 8.65034 10.758 17.0507 8.77817C17.0507 8.77817 24.6596 6.86205 29.1634 12.9743C31.6832 16.3941 31.5032 20.1174 30.9632 22.8781C30.4233 25.6389 28.3838 27.4063 28.3838 27.4063C28.3838 27.4063 22.8693 33.2564 18.0723 28.0935L17.0507 32.0291C17.0507 32.0291 15.852 36.2932 14.2299 37.3961L13.4841 37.2051C13.4841 37.2051 13.1129 32.4553 13.7116 30.4477Z' fill='white'/></svg>");
    }
  }

  .social-btn {
    &_tw {
      &:before {
        width: 40px;
        height: 40px;
        @media (max-width: 768px) {
          width: 26px;
          height: 26px;
        }
      }
    }

    &_wa {
      &:before {
        width: 40px;
        height: 40px;
        @media (max-width: 768px) {
          width: 26px;
          height: 26px;
        }
      }
    }

    &_tg {
      &:before {
        width: 37px;
        height: 37px;
        transform: translateX(calc(-50% - 2px)) translateY(-50%);
        @media (max-width: 768px) {
          width: 26px;
          height: 26px;
        }
      }
    }

    &_li {
      &:before {
        width: 35px;
        height: 35px;
      }
    }

    &_vk,
    &_th,
    &_ln,
    &_flb {
      &:before {
        width: 46px;
        height: 46px;
        @media (max-width: 768px) {
          width: 26px;
          height: 26px;
        }
      }
    }

    &_vb {
      &:before {
        width: 36px;
        height: 36px;
        @media (max-width: 768px) {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}

#all_share {
  .social-btn_fb {
    &:before {
      width: 40px !important;
      height: 40px !important;
      top: 50% !important;
      transform: translateX(-50%) translateY(-50%) !important;
      bottom: auto !important;
    }
  }
}

.swiper-pagination-bullet {
  background-color: var(--primary-white);
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-horizontal-gap: 10px;
}

.swiper-pagination-bullet-active {
  background-color: var(--orange);
}

.recharts-brush {
  border-radius: 50px;

  rect:first-child {
    stroke: transparent;
    fill: #ebebeb;
    border-radius: 50px;
    rx: 4px;
  }

  &-slide {
    border-radius: 50px;
    fill-opacity: 1;
    rx: 4px;
  }

  &-texts {
    display: none !important;
  }
}

.recharts-brush-traveller line {
  display: none !important;

  & > * {
    display: none !important;
  }
}

.swiper {
  height: 100%;
}

.swiper-slide {
  box-sizing: border-box;
  height: auto !important;
}
.swiper-content {
  height: 100% !important;
}

.rdrDefinedRangesWrapper,
.rdrStaticRange,
.rdrCalendarWrapper,
.rdrDateDisplayWrapper {
  color: var(--black);
  background: var(--white);
}

.rdrStaticRange,
.rdrDefinedRangesWrapper {
  border-color: var(--borderGray);
}

.rdrStaticRange span {
  color: var(--black);
}

.rdrMonthPicker select,
.rdrYearPicker select {
  color: var(--black);
}

.rdrDateDisplayItem {
  background: var(--white);
  border-color: var(--borderGray);
}

.rdrDateDisplayItemActive {
  border-color: var(--orange);
}

[dark-theme='true'] .rdrNextPrevButton {
  background: var(--gray-2);
}

.rdrDay,
.rdrDayNumber span {
  color: var(--black);
}
