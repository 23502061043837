
          @import "src/styles/scss/index.scss";
        
.tag {
  margin-left: auto;
  padding: 4px 8px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  width: max-content;

  &_created,
  &_canceled {
    background: var(--borderGray);
    color: var(--middleGray);
  }

  &_confirmed,
  &_processed,
  &_reconfirm,
  &_reconfirmed {
    background: var(--blue);
    color: var(--white);
  }

  &_complete {
    background: var(--success);
    color: var(--white);
  }
}
