
          @import "src/styles/scss/index.scss";
        
.title {
  display: flex;
  align-items: center;

  span {
    text-decoration: none;
    color: var(--black);
    font-size: 32px;
    line-height: 37px;
    margin-right: 20px;
    font-weight: bold;
  }

  svg {
    path {
      fill: var(--blue);
      transition: fill 0.3s;
    }

    cursor: pointer;
  }
}

.container {
  .id_list {
    right: auto;
    left: 0;
    flex-grow: 1;
    width: 100%;

    @include max-tablet {
      padding: 0;
      border: none;
      margin: 0;
      margin-bottom: 10px;
    }
    @media (min-width: 1024px) {
      max-width: 200px;
    }
  }

  .switch {
    display: flex;
    gap: 10px;
    flex-grow: 1;
    justify-content: flex-end;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }
}
.body {
  padding: 20px;
  background: var(--lightGray);

  @include max-tablet {
    background: transparent;
    padding: 0;
    border: 1px solid var(--gray-6);
    margin: 0 15px 10px;
    border-radius: 10px;
  }
}

.card {
  width: 100%;
  box-shadow: var(--card-shadow);
  text-decoration: none;
  height: 140px;
  padding: 25px 20px 20px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: border-color 0.3s;
  justify-content: space-between;
  border-radius: 10px;
  width: 20%;

  &__label {
    color: var(--darkGray);
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-right: 45px;
    width: 100%;

    span {
      max-width: 170px;
      display: block;

      @include max-tablet {
        max-width: none;
        display: inline;
      }
    }

    svg {
      position: absolute;
      top: -10px;
      right: -6px;
      @include max-tablet {
        display: none;
      }
    }
  }

  &__number {
    font-size: 38px;
    font-weight: bold;
    color: var(--black);
    transition: color 0.3s;
    margin-top: auto;

    i {
      font-style: normal;
      font-weight: 700;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      display: none;
      margin-right: 5px;
      @include max-tablet {
        display: block;
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @include max-tablet {
      overflow: hidden;
      gap: 0;
      box-shadow: none;
      border-radius: 10px;
      border: 1px solid var(--gray-6, #d6d6d6);
    }
  }

  &__subtitle {
    font-size: 12px;
    color: var(--middleGray);
    @include max-tablet {
      font-size: 10px;
      line-height: 20px;
      order: 1;
      text-align: center;
    }
  }

  &[href]:hover {
    border-color: var(--blue);

    .card__number {
      color: var(--blue);
    }
  }

  &:last-child {
    max-width: none;
    width: max-content;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.levels {
  &__tooltip {
    position: absolute;
    z-index: 10;
    text-align: left;
    right: 0;
    top: calc(100% + 10px);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    max-width: 260px;
    color: var(--main-static-white);
    font-size: 12px;
    border-radius: 3px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    line-height: 110%;
    text-transform: none;
  }

  &__row {
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 20px;
    border-top: 1px solid var(--borderGray);
    height: 50px;

    &_total {
      background: var(--second-orange);
      border-radius: 0 0 10px 10px;
      font-size: 16px;
      letter-spacing: 0.11px;
      height: 60px;
      @include max-tablet {
        border-bottom: 1px solid var(--borderGray);
      }

      span:first-child {
        letter-spacing: 0.05px;
        font-size: 20px;
        @include max-tablet {
          font-size: 14px;
        }
      }
    }

    &_first {
      span {
        font-size: 13px;
      }
    }

    span {
      width: 100%;
      font-weight: bold;
      text-align: center;
      position: relative;
      color: var(--black);

      svg {
        margin-left: 5px;
        cursor: pointer;

        &:hover {
          & + .levels__tooltip {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.card__header {
  @include max-tablet {
    border: none;
  }
}

span.orange {
  color: var(--orange);
}

.refresh {
  &:hover {
    path {
      fill: var(--main-static-white);
    }
  }

  @include max-mobile {
    margin-left: auto;
  }
}

@media (max-width: 1100px) {
  .card {
    &__container {
      flex-direction: column;
    }

    max-width: none;
    width: 100% !important;
  }
}

.desc {
  margin-bottom: 10px;
  color: var(--darkGray);
  font-size: 14px;
  border-radius: 10px;
  background: var(--lightGray);
  padding: 10px;
  line-height: 22px;
  display: none;
}

@include max-tablet {
  .body {
    & > div:first-child {
      padding: 0;
      border: none;
    }
  }
  .desc {
    display: block;
  }

  .title {
    width: 100%;
    padding: 13px 15px;

    & + div,
    & + div + div {
      margin-right: 15px;
      margin-left: 15px;
      width: calc(100% - 30px);
    }

    & > span {
      font-size: 14px;
      text-transform: uppercase;
      margin-right: 10px;
      line-height: 100%;

      & + svg {
        width: 22px;
        height: 20px;
        margin-top: -2px;
        margin-bottom: -2px;
      }
    }
  }
  .levels__wrapper {
    padding: 0 15px;
    padding-bottom: 20px;
  }
  .levels__row {
    border-left: 1px solid var(--borderGray);
    border-right: 1px solid var(--borderGray);
    height: 40px;

    span {
      &:first-child {
        text-align: start;
        font-size: 13px;
        font-weight: 700;
      }

      &:nth-child(2) {
        color: var(--middleGray);
      }

      &:last-child {
        text-align: end;
      }
    }
  }
  .levels__row_first {
    padding: 0 15px;
    border-radius: 10px 10px 0 0;
    color: var(--black);

    span:nth-child(2) {
      color: var(--black);
    }
  }
  .big {
    font-size: 20px;
  }
}

@include max-mobile {
  .card {
    padding: 20px;
    height: auto;
    border: none;

    &__number {
      font-size: 24px;
      line-height: 28px;
    }

    &__label {
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.127px;
      text-transform: uppercase;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .id_list {
    margin-bottom: 10px;
  }

  .title {
    padding: 5px 5px 5px 15px;

    & > a {
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 23px;
      text-transform: uppercase;
      margin-right: 10px;
    }
  }

  .levels {
    &__row {
      padding: 10px 15px;

      span {
        font-size: 13px;
      }

      .big {
        font-size: 20px;
      }
    }
  }
}

.load {
  height: 315px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  @include max-tablet {
    box-shadow: none;
    border-radius: 00px;
    border-bottom: 1px solid var(--borderGray);
    height: 50px;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .card__label {
      color: var(--middleGray);
      font-size: 12px;
      letter-spacing: 0.127px;
      text-transform: uppercase;
    }
    .card__number {
      font-size: 16px;

      margin: 0;
    }
    svg {
      display: none;
    }
  }
}

.total {
  @include max-tablet {
    padding: 15px 15px 12px 15px;
    box-shadow: none;
    height: auto;
    gap: 5px;
    .card__label {
      text-align: center;
      padding: 0;
      color: var(--middleGray);
      text-transform: uppercase;
    }
    .card__number {
      font-size: 20px;
    }
  }
}

.buttons {
  padding: 15px;
  width: 100%;
  background: var(--alpha-grey-065);
  display: none;
  margin-top: -15px;
  border-radius: 0 0 10px 10px;
  @media (min-width: 768px) {
    margin-top: -20px;
  }
  a {
    flex-grow: 1;
  }

  @include max-tablet {
    display: flex;
  }
}

.container {
  margin-bottom: 20px;
  border-radius: 10px;
  @include max-mobile {
    margin-bottom: 15px;
  }
}
.borderRadius {
  border-radius: 10px;
}
.cardContent {
  @include max-tablet {
    border-radius: 10px 10px 0 0;
  }
}
