
          @import "src/styles/scss/index.scss";
        
.footer {
  display: block;
  background-color: var(--primary-black);
  min-height: 100px;
  padding-top: 30px;
  position: relative;
  z-index: 1;
  margin-top: auto;

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1100px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 35px;
  }

  &__left-side {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  .lang {
    &__current {
      background-color: #282828;
      border: 1px solid var(--middleGray);
      color: var(--white);
      border-radius: 6px;

      &:before {
        top: 12px;
        right: 7px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: none;
        border-bottom: 4px solid #d0d0d0;
      }

      &:hover {
        background-color: var(--white);
        border-color: var(--white);
        color: var(--blue);
      }
    }

    &.opened {
      .lang__current:before {
        top: 13px;
        right: 7px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #d0d0d0;
        border-bottom: none;
      }
    }

    &__list {
      transform: translateY(calc(-100% - 40px));
      position: absolute;
      width: 100%;
      border-radius: 6px;
    }
  }

  &__right-side {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;

    img {
      display: block;
    }
  }

  &__bottom {
    display: block;
    width: 100%;
    border-top: 1px solid #212121;
    padding-top: 15px;
    padding-bottom: 15px;

    .footer__content {
      padding-bottom: 0;
      align-items: center;
    }
  }

  &__copyright {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: var(--middleGray);
  }

  &__btns {
    margin-right: 20px;
    padding-top: 13px;

    .fb-like {
      margin-bottom: 20px;
    }
  }

  &__menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      padding-right: 0;
      width: 100%;
    }
  }
}

.mob {
  &_container {
    background: var(--white);
    z-index: 99;
    padding: 13px 20px;
    box-shadow: 0 0 5px 0 #0000004d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: fixed;
    bottom: -1px;
    right: 0;
    left: 0;

    a {
      svg {
        color: var(--middleGray);
      }

      &:hover {
        svg {
          color: var(--blue);
        }
      }
    }
  }

  &_active {
    svg {
      color: var(--blue) !important;
    }
  }
}

.footer__storelinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storelinks {
  display: flex;
  max-width: 118px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .store {
  border-color: var(--middleGray);
  background-color: #282828;
}

.footer .store:hover {
  border-color: var(--blue);
  background-color: var(--blue);
}

.footer .store:active {
  background-color: var(--darkGray);
  border-color: var(--darkGray);
}

.footer .store__firstline,
.footer .store__secondline {
  display: none !important;
}

.footer .googleplay {
  width: 165px;
}

.footer .store_cryptosearch:before {
  left: 14px;
}

.page_withcoins .footer {
  position: relative;
  z-index: 10;
}

.bonus {
  display: inline-block;
  padding: 3px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: var(--blue);
  color: var(--white);

  &_orange {
    background-color: var(--orange);
  }

  &_red {
    background-color: var(--error);
  }

  &_turq {
    background-color: var(--hydro);
  }

  &_purple {
    background-color: var(--purple);
  }

  &_green {
    background-color: var(--green);
  }
}

.menu {
  display: block;
  margin-right: 20px;
  padding-top: 10px;

  &:nth-of-type(3) {
    min-width: 190px;
  }

  &__item {
    display: block;

    a {
      color: var(--white);
      font-size: 13px;
      line-height: 18px;
      text-decoration: none;
      // white-space: nowrap;

      &:hover {
        color: var(--orange);
      }
    }

    @media only screen and (max-width: 480px) {
      text-align: center;
      justify-content: center;
    }

    & + .menu__item {
      margin-top: 18px;
    }

    span:not(.bonus) {
      color: var(--blue);
      font-size: 13px;
      line-height: 18px;
      text-decoration: none;
    }

    &_email {
      margin-top: 20px;
      padding-left: 27px;
      background-size: 17px 14px;
      background-repeat: no-repeat;
      background-position: left center;

      a {
        color: var(--white);
        border-bottom: 1px solid var(--orange);
      }
    }

    b {
      font-weight: bold;
    }
  }

  &__label {
    display: block;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: var(--middleGray);
    margin-bottom: 18px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.feedbacklink {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  a {
    color: #4285f4;
    &:hover {
      color: #f7931a;
    }
  }
}
.otherinquiries {
  color: #757575;
  font-size: 13px;
  line-height: 15px;
  a {
    color: #4285f4;
    &:hover {
      color: #f7931a;
    }
  }
}

.menu__item_join {
  display: flex;
  align-items: center;
  color: var(--middleGray);
  white-space: nowrap;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 20px;

  span:not(.bonus) {
    color: var(--middleGray);
  }
}

.sociallinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sociallink {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #757575;
  transition: all 0.3s;

  &:hover {
    border-color: var(--orange);
    background-color: var(--orange);
  }

  &:active {
    background-color: var(--darkGray);
    border-color: var(--darkGray);
    svg {
      opacity: 0.5;
    }
  }
}
.header__socials {
  .sociallinks {
    margin-left: 10px;
  }
  .social-link_telegram svg path {
    fill: #31a5e0;
  }
  .sociallink_vk {
    svg path {
      fill: #577ca4;
    }
  }
  .sociallink_youtube svg path {
    fill: #e12a21;
  }
  .sociallink_twitter svg path {
    fill: #1da1f2;
  }
  .sociallink_instagram:hover svg path {
    fill: #f7931a;
  }
  @media only screen and (max-width: 1235px) {
    span {
      display: none;
    }
    .sociallinks {
      margin-left: 0;
    }
  }
}

.menu__item_join {
  .sociallinks {
    margin-right: 15px;
    @media only screen and (max-width: 480px) {
      margin-right: 0;
    }
    & + span {
      @media only screen and (max-width: 480px) {
        margin-left: 10px;
      }
    }
  }
}

.page_theme_black {
  .sociallink_instagram:not(:hover) {
    svg path {
      fill: #fff;
    }
  }
}

.footer {
  .sociallinks a svg path {
    fill: #fff;
  }
  .sociallink_instagram {
    svg path {
      fill: #fff;
    }
  }
  .sociallinks a + a {
    margin-left: 10px;
  }
  @media only screen and (max-width: 1200px) {
    .sociallinks + span {
      display: none;
    }
  }
}

@media only screen and (max-width: 1040px) {
  .page_ru .footer .footer__storelinks {
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .footer .footer__storelinks {
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .page_ru .footer .menu__col {
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    padding-top: 0;
    position: relative;
    &__content {
      padding: 20px 20px 40px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }
    &__bottom .footer__content {
      padding-top: 0;
    }
    .lang {
      position: absolute;
      top: 25px;
      right: 20px;
    }
    &__btns {
      width: 100%;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 4;
      .fb-like {
        margin-bottom: 0;
      }
    }
    &__store-links {
      order: 2;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    .menu {
      margin-bottom: 20px;
      margin-right: 0;
      text-align: center;
      &__item,
      &__label {
        text-align: left;
      }
      &__item {
        &_join {
          justify-content: flex-start;
        }
      }
    }
    &__social-links {
      display: flex;
      order: 3;
      color: var(--middleGray);
      white-space: nowrap;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 12px;
    }
    &__menu {
      flex-direction: column;
      width: auto;
    }
  }
}
@media screen and (max-width: 380px) {
  .footer__logo {
    align-self: flex-start;
  }
  .footer__logo > img {
    width: 190px;
    height: auto;
  }
}
