
          @import "src/styles/scss/index.scss";
        
.browserBalance {
  padding: 50px 0 50px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &__decoration {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    z-index: -1;
  }

  &__card {
    min-height: 459px;
  }

  .wrap {
    position: relative;
    z-index: 2;
  }

  &__card__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 580px;
  }

  &__cardHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    gap: 10px;

    @media (max-width: 1024px) {
      padding: 15px;
      svg {
        width: 21px;
        height: 18px;
      }
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  &__cardTitle {
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: 24px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__cardSwitch {
    @media (max-width: 768px) {
      width: 100%;

      & > button {
        width: 50% !important;
      }
    }
  }

  &__balanceTable {
    &_Header {
      display: flex;
      padding: 25px 20px 15px;

      &_cell {
        flex: 2;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }

      &_cell:last-child {
        flex: 1;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 20px;
    &:before {
      top: 0;
    }
  }
}

@media (max-width: 768px) {
  .card {
    margin-bottom: 0;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
}
