
          @import "src/styles/scss/index.scss";
        
.header {
  padding: 20px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-6, #d6d6d6);
  background-color: var(--white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @include max-tablet {
    text-align: center;
    font-size: 24px;
    line-height: 20px;
    padding: 15px;
  }

  .id {
    border-radius: 10px;
    border: 1px solid var(--gray-6, #d6d6d6);
    background: var(--gray-8, #f7f7f7);
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    margin-left: 15px;

    @include max-tablet {
      font-size: 16px;
    }

    &__text {
      border-right: 1px solid var(--gray-6, #d6d6d6);
      padding: 5px 10px;
      color: var(--black);
    }

    &__label {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;

      @include max-tablet {
        text-align: center;
        font-size: 24px;
        line-height: 20px;
      }
    }
  }

  .copy {
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
}

.body {
  background-color: var(--white);
  padding: 20px;

  @include max-tablet {
    padding: 15px;
  }

  p {
    color: var(--gray-2);
    font-size: 16px;
  }
}

.selects,
.inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 30px 0;

  @include max-mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}

.image {
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.vertical {
  height: 256px;
}

.img {
  min-width: 200px;
  display: flex;
  position: relative;
  max-width: fit-content;
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--gray-8);
  max-height: 100%;
  border-radius: 10px;
  justify-content: center;

  img {
    border-radius: 10px;
    max-height: 100%;
    object-fit: contain;
    max-width: 100%;
  }

  &:hover {
    .before {
      opacity: 1;
    }
  }
}

.before {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 0.3s;

  svg {
    margin-right: 8px;
  }
}

.inputs {
  margin-top: 30px;
}

.item {
  &__label {
    color: var(--black);
  }
}

.footer {
  background: var(--alpha-grey-065);
  display: flex;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .Button_btn__transparent__UCgIO {
    background: red;
  }

  @include max-tablet {
    padding: 15px;
  }
}

.button {
  padding: 10px;
  padding-inline: 10px;
  width: 50px;
}

.textarea {
  height: 190px;
}
