
          @import "src/styles/scss/index.scss";
        
.calcResult {
  &_wrapper {
    position: relative;
    min-height: 500px;
    background-color: var(--static-dark-gray);
    max-width: 900px;
    margin: 25px auto 0;
    border-radius: 10px;
    padding-bottom: 20px;
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      margin-left: -15px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid var(--orange);

      @media (max-width: 768px) {
        top: -1px;
        z-index: 2;
      }
    }

    @media screen and (max-width: 640px) {
      margin: 0 -15px;
    }
  }

  &__result {
    //padding-inline: 40px;
    color: var(--primary-white);

    @media screen and (max-width: 640px) {
      padding-inline: 10px;
      margin-top: 10px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 3px solid var(--orange);
    padding-top: 20px;
    &__cell {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      text-align: center;

      &_wide {
        text-align: center;
        flex: 2;
      }
    }

    &__icon {
      margin-right: 5px;
      color: var(--orange);
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 27px;

      @media screen and (max-width: 640px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__sublabel {
      margin-top: 5px;
      font-size: 12px;
      color: var(--textGray);
      font-weight: 300;

      @media screen and (max-width: 640px) {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }

  &__body {
    padding-bottom: 30px;
  }

  &__body__result {
    > * {
      border-bottom: 1px solid var(--middleGray);
    }
  }

  &__body__total {
    margin-top: 30px;
  }

  &__total__label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: var(--orange);
    text-transform: uppercase;
  }

  &__total__friends__label {
    margin-top: 30px;
    font-size: 16px;
    line-height: 17px;
    color: var(--borderGray);
  }

  &__total__friends__icon {
    color: var(--orange);
  }

  &__total__friends__value {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
  }

  &__body__income {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  &__body__income__label {
    font-size: 16px;
    line-height: 17px;
    color: var(--borderGray);
  }

  &__body__income__value {
    display: inline-flex;
    margin-top: 15px;
    padding: 9px 12px;
    border-radius: 6px;
    background-color: var(--orange);
    font-size: 64px;
    line-height: 72px;
    font-weight: 700;
  }

  &__body__income__coin {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &__body__modal_btn {
    align-items: center;
    justify-content: center;
    padding: 12px 15px;
    border-radius: 6px;
    border: 1px dashed #a4a4a4;
    background-color: transparent;
    color: var(--primary-white);
    transition: border-color 0.1s linear, color 0.1s linear;
    display: flex;
    margin: 30px auto 0;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.09px;

    svg {
      margin-right: 5px;
    }

    &:hover {
      border-color: var(--orange);
      color: var(--orange);
    }
  }
}
