
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  height: 40px;
  border-radius: 10px;
  max-width: 360px;
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;

  @media (max-width: 768px) {
    flex-shrink: 0;
    flex-grow: 1;
    max-width: none;
  }
}

.item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--black);
  border: 1px solid var(--borderGray);
  transition: all 0.3s;
  padding: 0 10px;
  cursor: pointer;

  &:first-child {
    border-right: none;
    border-radius: 6px 0 0 6px;
  }

  &:last-child {
    border-left: none;
    border-radius: 0 6px 6px 0;
  }

  &__active {
    background: var(--blue);
    border-color: var(--blue);
    color: var(--primary-white);
  }

  &:hover {
    background: var(--orange);
    border-color: var(--orange);
    color: var(--primary-white);
  }
}
