
          @import "src/styles/scss/index.scss";
        
.card {
  max-width: 340px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  animation: fadeIn 1s;
  border: 1px solid var(--gray-6);

  &__img {
    display: block;
    max-width: 100%;
    width: 100%;
    border-radius: 10px 10px 0 0;

    &[src] {
      height: 100%;
      max-height: 100%;
    }

    &_link {
      text-decoration: none;
      position: relative;
      width: 100%;
      padding-top: 62.25%;

      img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &:nth-of-type(3n) {
    .common_list {
      left: -200px;
    }
  }

  @media (max-width: 1100px) {
    &:nth-of-type(3n) {
      .common_list {
        left: 0;
      }
    }

    &:nth-of-type(2n) {
      .common_list {
        left: -200px;
      }
    }
  }

  @media (max-width: 768px) {
    &:nth-of-type(3n) {
      .common_list {
        left: auto;
      }
    }

    &:nth-of-type(2n) {
      .common_list {
        left: auto;
      }
    }
  }

  &__body {
    border-top: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 6px 6px;
    padding: 12px;
  }

  &__link {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    word-break: break-all;
    border: 1px solid var(--borderGray);
    padding: 14px;
    font-weight: 600;
    font-size: 14px;
    color: var(--black);
    margin-bottom: 12px;
    display: block;
    background-color: var(--lightGray);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    font-size: 14px;
    line-height: 16px;
    color: var(--black);
    font-weight: 700;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 12px;
  }

  &__open {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: bold;
    color: var(--blue);
    text-transform: uppercase;
    transition: color 0.3s;

    &:hover {
      color: var(--orange);
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-top: auto;
    gap: 10px;
  }

  &__links {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 20px;

    & > * {
      margin: 0;
    }
  }
}

.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: var(--orange);
  }

  &__success {
    color: var(--primary-white);
  }
}

.copied {
  background: var(--borderGray);
  color: var(--primary-white);
  pointer-events: none;
}

.copy,
.card__open {
  width: 100%;
  gap: 8px;
  text-transform: uppercase;
  font-size: 14px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  transition: all 0.3s;

  &:hover {
    background: var(--orange);
    border-color: var(--orange);
    color: var(--primary-white);
  }

  &:active {
    background: var(--lightGray);
    border-color: var(--lightGray);
  }
}

.select {
  position: relative;
  display: flex;
  padding: 6px 8px 6px 10px;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  transition: background 0.3s, border-color 0.3s;
  cursor: pointer;

  span {
    font-size: 11px;
    text-transform: uppercase;
    color: var(--blue);
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s;
  }

  svg {
    color: var(--blue);
    transition: color 0.3s;
  }

  &:hover {
    background: var(--orange);
    border-color: var(--orange);

    span {
      color: var(--primary-white);
    }

    & > svg {
      color: var(--primary-white);
    }

    color: var(--primary-white);
  }

  &__list {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    width: 300px;
    max-height: 400px;
    border-radius: 6px;
    overflow-y: scroll;
    background: var(--white);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;

    > * + * {
      border-top: 1px solid var(--borderGray);
    }

    &_item {
      padding: 15px 20px;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: var(--darkGray);
      cursor: pointer;
      transition: color 0.3s, background-color 0.3s, border-top-color 0.3s;

      &:hover {
        color: var(--white);
        background-color: var(--blue);
        border-top-color: var(--blue);
      }
    }
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: var(--blue);
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;

    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }
}

.landLink {
  min-height: 45px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
  color: var(--blue) !important;
  text-transform: uppercase;
  padding: 15px;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;

  &:hover {
    color: var(--white) !important;
  }
}

.common_list {
  background: var(--white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  z-index: 1000;
  padding: 0 !important;
  top: 30px;
  left: 0;

  & > * {
    padding: 10px 20px;
    border-bottom: 1px solid var(--borderGray);
    font-size: 14px;
    line-height: 16px;
    transition: all 0.3s;
    cursor: pointer;
    color: var(--black);

    &:hover {
      color: var(--white);
      background-color: var(--blue);

      svg path {
        fill: var(--white);
      }
    }
  }

  &_tag {
    background: var(--lightGray);
    border: 1px solid var(--borderGray);
    border-radius: 20px;
    padding: 4px 10px;
    color: var(--middleGray);
    text-transform: uppercase;
    transition: background-color 0.3s;
    cursor: pointer;

    div[class*='title'] {
      span {
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        color: inherit;
      }
    }

    &_browser {
      background-color: var(--white);
      color: var(--blue);
    }

    &:hover {
      background-color: var(--orange);
      border-color: var(--orange);
      color: var(--white);

      div[class*='title'] > svg path {
        fill: var(--lightOrange);
      }
    }
  }
}

.static {
  position: static;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
