
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    justify-content: center;
  }
}

.image {
  min-height: 191px;
}
.linkStyle {
  padding-top: 56.25%; /* 16:9 */
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-top: -1px;
}
