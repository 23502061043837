
          @import "src/styles/scss/index.scss";
        
.list__container {
  max-width: 260px;
}

.btns_wrapper {
  padding: 20px 0;
  border-top: 1px solid var(--borderGray);
  background: var(--lightGray);
  border-radius: 0 0 10px 10px;

  @include max-mobile {
    padding: 15px;
  }
}

.link {
  &_btn {
    max-width: 300px;
    width: 100%;
    display: flex;
    margin: 0 auto;

    @include max-mobile {
      max-width: none;
    }
    svg path {
      transition: fill 0.3s;
    }

    &:hover {
      svg path {
        fill: var(--orange);
      }
    }
  }
}
.title {
  align-items: center;

  .icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;

    &:hover path {
      fill: var(--orange);
    }

    path {
      transition: all 0.3s;
    }
  }

  .icon:hover + .tooltip {
    opacity: 1;
    visibility: visible;
  }
}

.tooltip {
  position: absolute;
  z-index: 10;
  text-align: left;
  left: 0;
  top: calc(100% + 10px);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  max-width: 500px;
  width: max-content;
  font-size: 12px;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: var(--main-static-white);
  line-height: 110%;
  text-transform: none;

  @include max-mobile {
    max-width: 100%;
    right: 0;
    left: auto;
  }
}
@include max-tablet {
  .list__container {
    padding: 0;
    border: none;
    background: transparent;
    max-width: none;
  }

  .date {
    width: 100%;
    display: none;
  }
}

.date {
  & > * {
    margin: 0;
    height: 40px;
    min-width: 300px;
  }
}
.cardHeader {
  @include max-tablet {
    border-bottom: none;
    padding-bottom: 0;
    & > span {
      padding: 20px 15px;
    }
  }
}
.header {
  display: flex;
  gap: 20px;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  @include max-tablet {
    padding: 0 15px;
    margin-bottom: 10px;
    width: 100%;
  }
  & > div {
    min-width: 200px;
    @include max-tablet {
      width: 100%;
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.total_mob {
  margin-left: 10px;
  font-size: 20px;
  @media (min-width: 1024px) {
    display: none;
  }
}
.noSwitch {
  @include max-tablet {
    margin-bottom: 0;
  }
}
