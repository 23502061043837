
          @import "src/styles/scss/index.scss";
        
.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;

  &__dark {
    .title {
      color: var(--primary-white);
    }

    .text {
      color: var(--textGray2);
    }
  }

  a {
    text-decoration: none;
  }
}

.icon {
  svg {
    display: block;
    margin: 0 auto 20px;
  }
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
  color: var(--black);
  position: relative;
  z-index: 2;
}

.text {
  font-size: 18px;
  line-height: 28px;
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  color: var(--darkGray);
  position: relative;
  z-index: 2;
}

.title,
.text {
  a {
    font-weight: bold;
    color: var(--blue);
    transition: color 0.3s;
    text-decoration: none;

    &:hover {
      color: var(--orange);
    }
  }
}

@media (max-width: 768px) {
  .icon {
    svg {
      height: 70px;
      width: auto;
    }
  }

  .title {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
