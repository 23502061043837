
          @import "src/styles/scss/index.scss";
        
@mixin respondTo($size) {
  @if $size == small {
    @media (max-width: 374px) {
      @content;
    }
  } @else if $size == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $size == desktop {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $size == full {
    @media (max-width: 1400px) {
      @content;
    }
  } @else if $size == additional {
    @media (max-width: 700px) {
      @content;
    }
  }
}

.root {
  z-index: 10000;
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

.root::before {
  z-index: -1;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
}

.frame {
  position: relative;
  width: 100%;
  max-width: 700px;
  overflow: auto;
  border-radius: 6px;
  background-color: #ffffff;

  @include respondTo(additional) {
    & {
      position: static;
      height: 100%;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .mobileHeader {
    display: none;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    padding: 16px;
    @include respondTo(additional) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      min-height: 54px;
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--theme-color-gray-5);
    cursor: pointer;
    @media (max-width: 700px) {
      display: none;
    }
  }
}

.view {
  @include respondTo(mobile) {
    height: 100%;
    background: var(--theme-color-gray-8);
  }

  .main {
    padding: 40px 30px 50px 30px;
    background: var(--theme-color-white);

    .icon {
      width: fit-content;
      margin: 0 auto;
    }

    .title {
      margin-top: 20px;
      line-height: 40px;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      color: #000000;
    }

    .text {
      margin-top: 15px;
      line-height: 26px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #494949;
      word-break: break-word;
    }
  }

  .footer {
    padding: 20px;
    background-color: #f7f7f7;
    border-top: 1px solid #d6d6d6;
    text-align: center;

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }
  }
}

.headerCloseIcon {
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  color: var(--static-coolor-blue);
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  inset: 0;
  padding: 20px;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  z-index: 10000;

  @include respondTo(mobile) {
    padding: 0;
    overflow: hidden;
  }
}

.modal {
  position: relative;
  background-color: var(--theme-color-white);
  max-width: 700px;
  width: 100%;
  margin: auto;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  overflow: auto;

  @include respondTo(mobile) {
    height: 100vh;
    background: var(--theme-color-gray-8);
    overflow-x: auto;
    border-radius: 0;
  }

  &_second {
    @include respondTo(mobile) {
      height: auto;
      overflow: visible;
    }
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: var(--theme-color-gray-5);

  @include respondTo(mobile) {
    top: 17px;

    right: auto;

    color: var(--static-coolor-blue);
  }

  &:hover {
    color: var(--static-coolor-orange);
  }

  &:active {
    background: var(--theme-color-gray-8);
    color: var(--theme-color-gray-4);
  }

  &.second {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--theme-color-gray-8);
    color: var(--theme-color-gray-4);

    @include respondTo(mobile) {
      top: 15px;
      right: 15px;
      left: auto;
    }

    .closeIcon {
      width: 14px;
      height: 14px;

      @include respondTo(mobile) {
        display: block;
      }
    }

    .closeArrow {
      @include respondTo(mobile) {
        display: none;
      }
    }
  }
}

.closeIcon {
  @include respondTo(mobile) {
    display: none;
  }
}

.closeArrow {
  display: none;
  @include respondTo(mobile) {
    display: block;
    transform: rotate(180deg);
  }
}

.title {
  color: var(--theme-color-black);

  &.xs {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  &.s {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px; /* 135.714% */

    @include respondTo(mobile) {
      font-size: 24px;
      line-height: 32px; /* 133.333% */
    }
  }

  &.m {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    @include respondTo(mobile) {
      text-align: center;
      font-size: 24px;
      line-height: 20px;
    }
  }
}

.description {
  margin-top: 15px;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  color: var(--theme-color-gray-2);
  word-break: break-word;
}

.header,
.content,
.footer {
  position: relative;
  padding: 20px 30px 20px;
  border-bottom: 1px solid var(--theme-color-gray-6);

  @include respondTo(mobile) {
    background: var(--color-static-white);
    padding: 20px 15px;
  }
}

.icon {
  margin-bottom: 15px;
}

.header {
  @include respondTo(mobile) {
    padding: 18px 0;
    box-shadow: 0 1px 3px 0 rgba(#000, 0.3);
    background: var(--theme-color-white);
    min-height: 54px;
  }

  &_image {
    padding: 0;
  }

  &.hiddenDesktop {
    display: none;
    @include respondTo(mobile) {
      display: block;
    }
  }
}

.footer {
  border-bottom: none;
  text-align: center;
}

.content {
  &.s {
    padding: 20px 30px;

    @include respondTo(mobile) {
      padding: 20px 15px;
    }
  }

  &.m {
    padding: 40px 30px;

    @include respondTo(mobile) {
      background: var(--theme-color-white);
      padding: 30px 15px 50px;
    }
  }
}

.gray {
  background-color: var(--theme-color-gray-7);
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.outside {
  position: fixed;
  left: 0;
  right: 0;
  color: var(--theme-color-gray-5);
  margin-top: 20px;
  text-align: center;
  user-select: none;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.04px;

  @include respondTo(mobile) {
    position: absolute;
    margin-top: 0;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.checkbox {
  width: 0;
  height: 14px;
  margin-right: calc(14px + 8px);
  vertical-align: baseline;
  appearance: none;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid var(--theme-color-gray-5);
    border-radius: 3px;
  }

  &:checked:before {
    border-color: transparent;
    background-color: var(--static-coolor-blue);
    background-image: url('data:image/svg+xml;utf8,<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.899902 4.90874L3.00148 7.29424L7.23239 0.759766" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-position: center;
    background-repeat: no-repeat;
  }
}

.button {
  all: unset;
  color: var(--static-coolor-white);
  border-radius: 6px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-transform: uppercase;
  transition: background-color 300ms ease, color 300ms ease, border-color 300ms ease;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:hover {
    background: var(--static-coolor-orange);
    border-color: var(--static-coolor-orange);
  }

  &.disabled {
    cursor: not-allowed;
    background: var(--color-static-gray-6);
    color: var(--static-coolor-white);
    border-color: var(--static-coolor-white);

    &:hover {
      background: var(--color-static-gray-6);
      color: var(--static-coolor-white);
      border-color: var(--static-coolor-white);
    }
  }
}

.xs {
  padding: 0 8px;
  height: 40px;
  min-width: 40px;
  font-size: 14px;
  line-height: 16px;

  @include respondTo(mobile) {
    border-radius: 20px;
  }

  &.rounded {
    border-radius: 20px;
  }
}

.s {
  padding: 0 14px;
  height: 50px;
  min-width: 50px;
  font-size: 14px;
  line-height: 16px;

  @include respondTo(mobile) {
    border-radius: 25px;
  }

  &.rounded {
    border-radius: 25px;
  }
}

.m {
  padding: 0 16px;
  height: 56px;
  min-width: 56px;
  font-size: 16px;
  line-height: 20px;

  @include respondTo(mobile) {
    border-radius: 30px;
  }

  &.rounded {
    border-radius: 30px;
  }
}

.l {
  padding: 0 18px;
  height: 60px;
  min-width: 60px;
  font-size: 16px;
  line-height: 20px;

  @include respondTo(mobile) {
    height: 56px;
    min-width: 56px;
    padding: 0 16px;
    border-radius: 30px;
  }

  &.rounded {
    border-radius: 30px;
  }
}

.xl {
  padding: 0 8px;
  height: 70px;
  min-width: 70px;
  font-size: 18px;
  line-height: 22px;

  @include respondTo(mobile) {
    border-radius: 40px;
  }

  &.rounded {
    border-radius: 40px;
  }
}

.fullWidth {
  width: 100%;
}

.wide {
  min-width: 320px;
}

.outline {
  background: var(--static-coolor-white);
  border-color: var(--color-static-gray-6);

  &.blue {
    color: var(--static-coolor-blue);
    &:hover {
      color: var(--static-coolor-orange);
      background: var(--static-coolor-white);
      border-color: var(--static-coolor-orange);
      svg path[fill] {
        fill: var(--static-coolor-orange);
      }
    }
  }

  &.orange {
    color: var(--static-coolor-orange);
  }

  &.green {
    color: var(--static-coolor-green);
  }

  &.red {
    color: var(--static-coolor-red);
  }

  &.black {
    color: var(--static-color-black);
  }

  &.inherit {
    color: inherit;
  }

  &:hover {
    border-color: var(--static-coolor-orange);
    color: var(--static-coolor-white);
  }

  &.disabled {
    background: var(--color-static-gray-6);
    color: var(--static-coolor-white);
    border-color: var(--static-coolor-white);

    &:hover {
      background: var(--color-static-gray-6);
      color: var(--static-coolor-white);
      border-color: var(--static-coolor-white);
    }
  }
}

.filled {
  color: var(--static-coolor-white);

  &.blue {
    background: var(--static-coolor-blue);
  }

  &.green {
    background: var(--static-coolor-green);
  }

  &.red {
    background: var(--static-coolor-red);
  }

  &.black {
    background: var(--static-color-black);
  }

  &.orange {
    background: var(--static-coolor-orange);

    &:hover {
      background: var(--static-coolor-blue);
    }
  }

  &.inherit {
    color: inherit;
  }

  &:hover {
    background: var(--static-coolor-orange);
    color: var(--static-coolor-white);
  }

  &.disabled {
    background: var(--color-static-gray-6);
    color: var(--static-coolor-white);
    border-color: var(--static-coolor-white);

    &:hover {
      background: var(--color-static-gray-6);
      color: var(--static-coolor-white);
      border-color: var(--static-coolor-white);
    }
  }
}

.text {
  background: none;
  border-color: transparent;

  &.blue {
    color: var(--static-coolor-blue);
  }

  &.red {
    color: var(--static-coolor-red);
  }

  &.orange {
    color: var(--static-coolor-orange);
  }

  &.green {
    color: var(--static-coolor-green);
  }

  &.black {
    color: var(--theme-color-black);
  }

  &.inherit {
    color: inherit;
  }

  &.disabled {
    background: var(--color-static-gray-6);
    color: var(--static-coolor-white);
    border-color: var(--static-coolor-white);

    &:hover {
      background: var(--color-static-gray-6);
      color: var(--static-coolor-white);
      border-color: var(--static-coolor-white);
    }
  }

  &:hover {
    border-color: transparent;
    background: transparent;
    color: var(--static-coolor-orange);
  }
}

.image {
  width: 100%;
  object-fit: cover;
}

.imageWrapper {
  width: 100%;
  height: 100%;
}
