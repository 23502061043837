
          @import "src/styles/scss/index.scss";
        
.wrapper {
  margin-bottom: 20px;
  border-radius: 10px;

  @include max-mobile {
    margin-bottom: 15px;

    .section__mob_container .row {
      :global(.recharts-wrapper) {
        width: 100px !important;
        svg {
          width: 100px !important;
        }
      }
    }
  }
}
.section {
  display: block;
  background-color: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 15px;

  @include max-tablet {
    border: none;
    padding: 0 15px;
    box-shadow: none;
    border-radius: 15px 15px 0 0;
  }

  &__header {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: space-between;
    padding: 16px 20px 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    margin-bottom: 30px;

    @include max-tablet {
      padding: 17.5px 0;
      position: relative;
      z-index: 2;
      background: var(--white);
      border-radius: 10px 10px 0 0;
      margin-bottom: 0;
    }

    & > span {
      color: var(--black);

      @include max-tablet {
        font-size: 14px;
      }
    }

    &_link {
      text-decoration: none;
      color: var(--blue);
      transition: all 0.3s;
      display: flex;
      align-items: center;

      @include max-tablet {
        display: none;
        margin-right: 0;
      }

      svg {
        fill: var(--blue);
        margin-right: 10px;
        transition: all 0.3s;

        @include max-tablet {
          width: 21px;
          height: 18px;
        }
      }
      span {
        @include max-tablet {
          width: 21px;
          height: 18px;
        }
      }

      &:hover {
        color: var(--orange);

        svg {
          fill: var(--orange);
        }
      }
    }

    .label {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &__mob_container {
    margin-bottom: 17px;

    @include max-tablet {
      color: var(--middleGray);
      border: 1px solid var(--borderGray);
      border-radius: 10px;
      margin-bottom: 14px;
      overflow: clip;
    }
  }
}

.col {
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 22px;
  height: 60px;

  @include max-tablet {
    padding: 0 15px 0 15px;
    height: 50px;
    border-top: 1px solid var(--borderGray);
    background: var(--lightGray);
  }
}

.info {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--borderGray);

  @include max-tablet {
    border: none;
  }

  &__coin {
    margin-bottom: 8px;

    @include max-tablet {
      margin-bottom: 0;
    }

    .coin {
      &__container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }

      &__svg {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
      }

      &__balance {
        font-size: 22px;
        font-weight: 700;
        line-height: 26.6px;
        letter-spacing: 0.06px;
        white-space: nowrap;
        display: block;
        color: var(--black);
        text-align: left;
        top: 0;
        left: 0;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include max-mobile {
          font-size: 10px;
          transform: translate(0, 0);
          max-width: calc(100vw - 120px);
        }
        @include max-tablet {
          font-size: 18px;
          font-weight: 700;
          line-height: 21.78px;
          letter-spacing: 0.06px;
        }
      }

      &__currencie {
        @include max-tablet {
          font-weight: 400;
        }
      }

      &__increment {
        margin-left: 5px;
        border-radius: 20px;
        padding: 6px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.06px;
        text-align: left;
        position: absolute;
        bottom: 14px;
        right: 0;

        z-index: 10;
        background-color: var(--success);
        color: var(--white);
        opacity: 0;
        transition: opacity 0.3s;
        text-transform: uppercase;

        font-size: 10px;

        height: 20px;
        padding: 2px 7px;

        @include max-tablet {
          bottom: 0;
          top: -7px;
        }
        &--show {
          opacity: 1;
        }
      }
    }
  }

  &__label {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    position: relative;
    line-height: 13px;
    text-transform: uppercase;
    color: var(--gray-3);
    font-weight: bold;
    margin-bottom: 11px;
    letter-spacing: 0.03px;

    @include max-tablet {
      margin-bottom: 6px;
    }
  }

  &__mob_row {
    @include max-tablet {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 5px;
      position: relative;
    }
  }

  &__mob_container {
    width: 100%;
    display: flex;
    letter-spacing: 0.03px;

    @include max-tablet {
      display: block;
      border: 1px solid var(--borderGray, #d6d6d6);
      border-radius: 10px;
      width: 100%;
      margin-bottom: 14px;
      border-radius: 10px;
    }
  }

  &__prompt {
    position: relative;

    & > * {
      text-transform: none;
    }

    .prompt {
      &__svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          color: var(--orange);
        }

        &:hover + .prompt__container {
          opacity: 1;
        }
      }

      &__container {
        border-radius: 6px;
        padding: 2px 8px;
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        position: absolute;
        left: 4px;
        transform: translateX(10%);
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        color: var(--white);
        background: var(--gray1);
        transition: 0.3s opacity;
        z-index: 1;
        pointer-events: none;

        &--opacity {
          opacity: 0;
        }

        @include max-tablet {
          transform: translateX(-100%);
        }
      }
      &__next {
        color: var(--orange);
      }
    }
  }

  &__btc {
    display: flex;
    align-items: center;
    color: var(--black);
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 10px;
    white-space: nowrap;

    @include max-tablet {
      font-size: 18px;
      line-height: normal;
    }
  }

  &__usd {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 11px;
    line-height: 11px;
    color: var(--middleGray);
    font-weight: bold;

    @include max-tablet {
      padding-bottom: 3px;
      width: max-content;
    }

    .usd {
      &__rate {
        display: block;
      }

      &__locked {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 10px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 0.034px;
        text-align: left;
        cursor: pointer;
        transition: all 0.3s;

        @include max-tablet {
          display: none;
        }

        &:hover {
          color: var(--orange);
        }
      }

      &__svg {
        width: 16px;
        height: 16px;
        fill: orange;
      }
    }
  }

  &__item {
    flex: 1 auto;
    padding: 10px 20px;
    display: flex;
    width: 33.5%;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid var(--borderGray);

    @include max-tablet {
      border: none !important;
      display: block;
      width: 100%;
      background: var(--white);
      padding: 15px;
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:not(:first-child) {
        border-top: 1px solid var(--borderGray) !important;
      }
      &:nth-child(6),
      &:last-child,
      &[data-hook='nft'] {
        border-radius: 0 0 10px 10px;
      }
    }

    &mob {
      display: none;
    }

    &_withdraw {
      padding: 10px 20px;
      flex: inherit;
      max-width: 311px;
      width: 100%;
      border-right: none;
      border-left: 1px solid var(--borderGray);

      @include max-tablet {
        display: none;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
      }

      &_mob {
        display: none;
      }
    }

    &_withdraw_mob {
      @include max-tablet {
        background: var(--alpha-grey-065);
        display: flex;
        padding: 15px;
        border: none !important;
        max-width: none;

        .btn {
          flex-grow: 1;
          border-color: var(--blue);
          background-color: var(--blue);
          color: var(--primary-white);
        }
      }
    }
  }

  .btn {
    min-width: 0;
    max-width: 270px;
    width: 100%;
    height: 50px;
  }

  @include max-tablet {
    flex-wrap: wrap;
    justify-content: center;

    &__item {
      &:nth-last-child(2) {
        border-right: none;
      }
    }
  }
}

.balance {
  margin-bottom: 5px;
  text-align: center;

  @include max-tablet {
    margin-bottom: 0;
    padding-top: 30px;
    padding-bottom: 19px;
    background: var(--white);
  }

  &__btc {
    display: flex;
    justify-content: center;
    font-size: 74px;
    line-height: 90px;
    font-weight: bold;
    color: var(--black);
    margin-bottom: 15px;
    height: 90px;

    @include max-tablet {
      margin-bottom: 10px;
      height: 31px;
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: left;
    }

    img {
      margin-top: 4px;
      margin-right: 20px;

      @include max-tablet {
        margin-top: 0;
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }

      @include max-mobile {
        margin: auto 5px auto 0;
      }
    }
  }

  &__currencie {
    font-weight: 400;
    @media (max-width: 370px) {
      margin-top: 3px;
    }
  }

  &__usd {
    color: var(--middleGray);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    letter-spacing: 0.03px;

    @include max-tablet {
      font-size: 13px;
      font-weight: 600;
      line-height: 15.73px;
    }

    b {
      font-weight: bold;
    }
  }
}

.btc_chart_mob,
.linkMob {
  display: none;
}

.btc_chart_mob {
  @include max-tablet {
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 7px 15px;
    width: 100%;
    display: flex;
    background: var(--white);
    box-shadow: var(--card-shadow);
  }
}

.linkMob {
  @include max-tablet {
    display: flex;
    border-radius: 10px;
    background: var(--white, #fff);
    width: 50px;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    svg {
      margin-right: 0;
    }
  }
}

[dark-theme='true'] {
  .prompt__container {
    color: var(--dark);
  }
}
.coin__balanceWrapper {
  position: relative;
  width: 100%;
  @include max-tablet {
    position: static;
  }
}
