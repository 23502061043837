
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  gap: 10px;

  & > * {
    color: var(--black);
    width: 100%;
    max-width: 180px;

    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  @media (max-width: 630px) {
    flex-direction: column;
  }
}
