
          @import "src/styles/scss/index.scss";
        
@mixin themeColor($color) {
  background-color: $color;
}
.main {
  &__bg-black {
    @include themeColor(#000);
  }

  &__bg-white {
    @include themeColor(#fff);
  }

  &__bg-purple {
    @include themeColor(#a033cc);
  }

  &__bg-blue {
    @include themeColor(#4285f4);
  }

  &__bg-green {
    @include themeColor(#37cc33);
  }

  &__bg-cyan {
    @include themeColor(#1ab9e5);
  }

  &__bg-mint {
    @include themeColor(#1dc886);
  }

  &__bg-yellow {
    @include themeColor(#f7c21a);
  }

  &__bg-pink {
    @include themeColor(#fc4bac);
  }

  &__bg-pistacho {
    @include themeColor(#c9f85d);
  }

  &__bg-red {
    @include themeColor(#f74249);
  }

  &__bg-success {
    @include themeColor(#17ef46);
  }

  &__bg-indigo {
    @include themeColor(#3f3da5);
  }

  &__bg-grass {
    @include themeColor(#3ca867);
  }

  &__bg-watter {
    @include themeColor(#5d868f);
  }

  &__bg-violet {
    @include themeColor(#6b1f77);
  }

  &__bg-brown {
    @include themeColor(#826a5c);
  }

  &__bg-blood {
    @include themeColor(#791130);
  }

  &__bg-asphalt {
    @include themeColor(#455265);
  }

  &__bg-swamp {
    @include themeColor(#7a825c);
  }

  &__bg-see {
    @include themeColor(#5d88c8);
  }

  &__bg-dirty {
    @include themeColor(#437155);
  }

  &__bg-light_violet {
    @include themeColor(#9265a7);
  }

  &__bg-orange {
    @include themeColor(#f7931a);
  }
}
