
          @import "src/styles/scss/index.scss";
        
.group {
  transition-delay: 0ms;
  transition-timing-function: ease-in-out;
  transform: translate(0, 0);
}

.group .number {
  height: 1em;
  display: flex;
  justify-content: center;
}

.reel {
  height: 1em;
  display: flex;
  align-items: flex-end;
  overflow-y: hidden;

  /** CUSTOMISE BELOW */
  font-weight: 700;
  color: var(--black);
  font-size: 74px;
}

@media screen and (max-width: 1024px) {
  .reel {
    font-size: 26px;
  }
}

@media screen and (max-width: 370px) {
  .reel {
    margin-top: 3px;
    font-size: 28px;
    line-height: 33px;
  }
}
