
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 60px;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.decoration {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 1720px;
  z-index: 1;

  .animatedWrap {
    position: absolute;
    top: 0;

    &--left {
      left: 80px;
      top: 90px;

      img {
        width: 500px;
        height: 400px;

        @media (max-width: 768px) {
          top: 22px;
        }
      }
    }

    &--right {
      top: 90px;
      right: 80px;

      img {
        width: 500px;
        height: 450px;

        @media (max-width: 768px) {
          top: 0;
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 700px;
    top: -50px;
  }
}

.btc-mob {
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 546.7px;
    height: 846.534px;
    transform: translate(-50%);
  }
}

.cardWrapper {
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px !important;
  overflow: hidden;
}

.change {
  color: var(--black);
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
  padding: 0 40px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.card {
  max-width: 700px;
  // background-color: var(--white);
  border-radius: 15px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;

  &__header {
    padding: 25px 20px;
    font-weight: bold;
    font-size: 32px;
    color: var(--black);
    line-height: 30px;
    border-bottom: 1px solid var(--borderGray);
    background-color: var(--white);

    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
    }
  }

  &__body {
    background-color: var(--white);
  }

  &__footer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: var(--alpha-grey-065);
    border-radius: 0px 0px 15px 15px;

    & > a,
    & > button {
      max-width: none;
      width: 100%;
    }
  }

  &__items {
    padding: 30px 40px;

    @media (max-width: 768px) {
      padding: 20px 15px;
    }
  }
}

.info {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  min-height: 88px;

  span {
    font-weight: bold;
    font-size: 16px;
    color: var(--error);
  }
}

.item {
  // padding: 30px 40px;

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--borderGray);
  // }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .inner {
    display: flex;
    align-items: center;
  }

  img {
    width: 48px;
    height: 48px;
    display: block;
    margin-right: 22px;
  }

  h2 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: var(--black);
  }

  p {
    font-size: 13px;
    line-height: 15px;
    color: var(--middleGray);
    text-decoration: none;
    margin-top: 10px;
  }

  button {
    margin-left: auto;
  }

  .inner {
    flex-wrap: wrap;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.login {
  // background: rgba(23, 23, 23, 0.65);
  padding: 10px 40px 30px;
  border: none;
  z-index: 2;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 15px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 300px;
    width: 100%;
    text-decoration: none;
    height: 50px;

    border-radius: 10px;
    border: 1px solid var(--borderGray);
    background: var(--white);

    &:hover {
      span {
        color: var(--color-static-white);
      }
    }

    img {
      width: 24px;
      height: 24px;
    }

    @media (max-width: 768px) {
      max-width: none;
      width: 100%;
      flex-direction: row;
      padding: 15px;

      img {
        margin-right: auto;
        margin-bottom: 0;
      }
    }

    span {
      color: var(--black);
      transition: color 0.3s;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      color: #000;

      @media (max-width: 768px) {
        margin-top: 0;
        margin-right: auto;
      }

      &:hover {
        span {
          color: var(--blue);
        }

        border-color: var(--blue);
      }
    }

    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 15px;
    max-width: 300px;
    width: 100%;
    text-decoration: none;
    height: 50px;

    svg path {
      stroke: #4285f4;
    }

    @media (max-width: 768px) {
      max-width: none;
      width: 100%;
      flex-direction: row;
      padding: 15px;

      img {
        width: 24px;
        height: 24px;
        margin-right: auto;
        margin-bottom: 0;
      }
    }

    &:hover {
      background: #f7931a;
      color: #fff;

      .signin__title {
        color: #fff;
      }

      svg path {
        stroke: #fff;
      }
    }
  }
}

.backBtn {
  max-width: 300px !important;

  @media (max-width: 768px) {
    max-width: none !important;
  }
}

.logoutBtn {
  &:hover {
    svg path {
      fill: var(--white);
    }
  }
}

@media (max-width: 740px) {
  .card {
    &__footer {
      flex-direction: column;
      padding: 20px 15px;
    }
  }

  .login {
    &__container {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    &__item {
      max-width: none;
      width: 100%;
    }
  }
}
