
          @import "src/styles/scss/index.scss";
        
.container {
  width: 100%;
  max-width: 1100px;

  @media (max-width: 1100px) {
    padding: 0 15px;
  }
}
