
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 15px;
  background: rgba(23, 23, 23, 0.65);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 768px) {
    padding: 20px;
  }
}

.affiliateBenefits {
  //padding: 90px 0;
  //background-color: var(--orange);
  overflow: hidden;

  &__benefits {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0;

    @media screen and (max-width: 1140px) {
      margin: 0;
    }

    //@media screen and (max-width: 768px) {
    //  padding-inline: 20px;
    //}
  }

  &__benefit {
    flex: 1;
    flex-basis: 300px;
    padding: 18px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;

    color: var(--primary-white);

    > * + * {
      margin-top: 10px;
    }

    @media screen and (max-width: 760px) {
      max-width: none;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__benefit_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
