
          @import "src/styles/scss/index.scss";
        
.container {
  border-radius: 10px;
  max-width: 520px;
  border: 1px solid var(--borderGray);
  flex-grow: 0;
  flex-shrink: 1;
  width: calc(50% - 10px);

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    max-width: none;
  }
}

.body {
  padding: 15px;
}

.header {
  padding: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--borderGray);

  span {
    font-weight: bold;
    font-size: 22px;
    color: var(--black);
    margin-left: 15px;
    margin-right: auto;
  }

  &__link {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--borderGray);
    border-radius: 10px;
    transition: all 0.3s;
    svg {
      color: var(--blue);
    }

    @include max-mobile {
      border: none;
    }

    &:hover {
      background: var(--orange);
      border-color: var(--orange);
      svg {
        color: var(--primary-white);
      }
    }
  }

  @include max-mobile {
    padding: 10px 15px;

    svg {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 16px;
    }

    &__link {
      height: auto;
    }

    &__link > svg {
      width: 20px;
      height: 20px;
    }
  }
}

.text {
  padding: 10px;
  margin-bottom: 25px;
  background: var(--lightGray);
  font-size: 14px;
  line-height: 24px;
  border-radius: 10px;
  color: var(--darkGray);
}

.content {
  display: flex;
  gap: 10px;

  & > *:not(.input) {
    min-width: 50px;
    height: auto;
    max-height: none;
    width: 50px;
    flex-shrink: 0;
    gap: 0;
    padding: 0;
    z-index: 1;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    .input {
      width: 100%;
    }
    & > *:not(.input) {
      width: calc(50% - 5px);
      height: 50px;
    }
  }
}

.input {
  min-height: 50px;
  padding: 15px;
  background-color: var(--lightGray);
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  position: relative;
  width: calc(100% - 120px);

  &__active {
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: var(--gray-3);
      max-width: 90%;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      strong {
        color: var(--black);
      }
    }

    svg {
      width: 12px;
      height: 12px;
      color: var(--blue);
    }
  }

  &__label {
    position: absolute;
    left: 10px;
    top: 0;
    transform: translateY(-50%);
    border: 1px solid var(--borderGray);
    padding: 4px 8px;
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
    color: var(--middleGray);
    background-color: var(--white);
    border-radius: 50px;
  }
}

.list {
  position: absolute;
  box-shadow: 0 2px 6px 0 #00000033;
  overflow-x: scroll;
  z-index: 2;
  top: calc(100% + 10px);
  left: 0;
  max-height: 300px;
  background: var(--white);
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-moz-scrollbar {
    width: 0;
    height: 0;
  }

  &__item {
    padding: 15px;
    color: var(--black);
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: var(--primary-white);
      background: var(--blue);
    }
  }
}
