:root {
  --light-orange: #fffaf3;
  --orange: #f7931a;
  --second-orange: #0b0e10;
  --lightOrange: #ffdfb8;
  --blue: #4285f4;
  --darkBlue: #09183f;
  --white: #25272a;
  --tagGray: #3f4045;
  --black: #fff;
  --lightGray: #0b0e10;
  --secondGray: #9c9c9c;
  --darkGray: #d1d2d6;
  --gray1: #171717;
  --textGray: #b3b3b3;
  --textGray2: #898e99;
  --textGray3: #9d9d9d;
  --textGray4: #8b8a8a;
  --borderGray: #3f4045;
  --borderDark: #cecece;
  --primary-black: #000;
  --middleGray: #d1d2d6;
  --lightBlue: #0a162c;
  --error: #f74249;
  --light-red: #200909;
  --hydro: #1ab9e5;
  --purple: #a033cc;
  --green: #1dc886;
  --primary-white: #fff;
  --success: #37cc33;
  --additional-blue: #172f55;
  --additional-success: #134712;
  --additional-warning: #564409;
  --additional-error: #56171a;
  --pergament: #25272a;
  --static-dark-gray: #494949;
  --static-transparent-bg: #171717a6;
  --card-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  --alpha-grey: rgba(23, 23, 23, 0.65);
  --alpha-grey-static: rgba(117, 117, 117, 0.7);
  --alpha-black-015: rgba(0, 0, 0, 0.15);
  --alpha-gray-static-015: rgba(0, 0, 0, 0.15);
  --news-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) -9.62%, #25272a 73.08%);
  --main-static-white: #fff;
  --alpha-grey-065: rgba(23, 23, 23, 0.65);
  --gray-1: #e3e3e3;
  --gray-2: #d1d2d6;
  --gray-3: #bec1c8;
  --gray-4: #898e99;
  --gray-5: #737781;
  --gray-6: #3f4045;
  --gray-7: #303137;
  --gray-8: #0b0e10;
  --gray-0: #ededed;
  --Red_BG: #401111;
  --Green_BG: #0d340d;
  --Blue_BG: #0a162c;
  --Orange_BG: #362209;
}

:root [data-no-theme='true'],
:root [dark-theme='false'] {
  --light-orange: #fffaf3;
  --orange: #f7931a;
  --second-orange: #fff5e9;
  --lightOrange: #ffdfb8;
  --blue: #4285f4;
  --darkBlue: #09183f;
  --white: #fff;
  --black: #000;
  --lightGray: #f7f7f7;
  --secondGray: #9c9c9c;
  --darkGray: #494949;
  --tagGray: #494949;
  --gray1: #212121;
  --textGray: #b3b3b3;
  --textGray2: rgb(142, 142, 142);
  --textGray3: #9d9d9d;
  --textGray4: #8b8a8a;
  --primary-black: #000;
  --secondary-black: #141721;
  --borderGray: #d6d6d6;
  --borderDark: #cecece;
  --middleGray: #757575;
  --lightBlue: #f0f6ff;
  --error: #f74249;
  --light-red: #401111;
  --hydro: #1ab9e5;
  --purple: #a033cc;
  --green: #1dc886;
  --primary-white: #fff;
  --success: #37cc33;
  --pergament: #fffdfd;
  --static-dark-gray: #494949;
  --additional-blue: #172f55;
  --additional-success: #134712;
  --additional-warning: #564409;
  --additional-error: #56171a;
  --static-transparent-bg: #171717a6;
  --card-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  --alpha-grey-065: rgba(23, 23, 23, 0.65);
  --alpha-grey: rgba(117, 117, 117, 0.7);
  --alpha-black-015: rgba(0, 0, 0, 0.15);
  --warning: #f7c21a;
  --news-gradient: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  --main-static-white: #fff;
  --gray-1: #212121;
  --gray-2: #494949;
  --gray-3: #757575;
  --gray-4: #8e8e8e;
  --gray-5: #b3b3b3;
  --gray-6: #d6d6d6;
  --gray-7: #ebebeb;
  --gray-8: #f7f7f7;
  --gray-0: #171717;
  --Red_BG: #fff3f4;
  --Green_BG: #ecffec;
  --Blue_BG: #f0f6ff;
  --Orange_BG: #fff5e9;
}
