
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: center;
  background-color: var(--white);
  position: relative;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 80px;
  &__sticky {
    position: sticky;
    top: 0;
  }
}

.wrap {
  [data-item='menu'] {
    display: none;
  }
}

.switcher {
  padding: 8px 9px;
  border-radius: 100px;
  height: 40px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  min-width: 200px;
  justify-content: space-between;
  font-weight: bold;
  font-size: 12px;
  color: var(--white);

  div {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  svg {
    color: var(--middleGray);
    cursor: pointer;
    width: 12px;
    height: 12px;
  }

  &__container {
    display: flex;
    align-items: center;
  }
}

.mob {
  &_container {
    position: sticky;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    background: var(--white);
    padding: 0 15px;
    height: 54px;
    display: flex;
    box-shadow: 0 1px 3px 0 #0000004d;
    justify-content: space-between;
    gap: 10px;

    &_simple {
      justify-content: center;
      align-items: center;
      svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        color: var(--blue);
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      span {
        font-weight: bold;
        font-size: 16px;
        color: var(--black);
      }
    }
  }

  &_logo {
    align-self: center;
    width: 32px;
    height: 32px;
  }
}

.menu {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 30px;
}

.inner {
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}

.nav {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--blue);
  text-decoration: none;
  transition: color 0.3s;

  svg path {
    transition: fill 0.3s;
    fill: var(--middleGray);
  }

  &:hover,
  &__active {
    color: var(--black);

    svg path {
      fill: var(--orange);
    }
  }

  &__active {
    pointer-events: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.burger {
  display: flex;
  transition: all 0.3s;
  cursor: pointer;
  z-index: 200;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  span {
    width: 18px;
    height: 2px;
    background: var(--middleGray);
    border-radius: 2px;
    position: relative;
    transition: all 0.3s;
    display: block;

    &::after,
    &::before {
      transition: all 0.3s;
      position: absolute;
      content: '';
      width: 18px;
      height: 2px;
      background: var(--middleGray);
      border-radius: 2px;
    }

    &::after {
      top: -5px;
    }

    &::before {
      top: 5px;
    }
  }

  svg path {
    fill: var(--middleGray);
    transition: fill 0.3s;
  }

  @media (min-width: 768px) {
    &:hover {
      svg path {
        fill: var(--orange);
      }
    }
  }

  &.active {
    svg path {
      fill: var(--blue);
      transition: fill 0.3s;
    }

    span {
      background: none;
      &::after {
        top: 0;
        transform: rotate(-225deg);
      }

      &::before {
        top: 0;
        transform: rotate(225deg);
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    width: 54px;
    height: 54px;
    position: absolute;
    right: 0px;
    top: 0;
  }
}

.logo {
  height: 70px;
  position: relative;
  top: -1px;

  &__link {
    margin-right: auto;
  }

  svg {
    width: 319px !important;
    height: 67px !important;
    top: 2px;
    transform: none !important;
    position: relative;
    left: -9px;
  }
}

.header__drop {
  position: absolute;
  z-index: 30;
  right: 10px;
  top: 60px;
  width: 270px;
  background: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  transition: all 0.7s;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .drop {
    &__item {
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      border-bottom: 1px solid var(--borderGray);
      padding: 15px;

      &:first-child {
        border-radius: 6px 6px 0 0;
      }

      svg {
        margin-right: 15px;
      }

      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: var(--black);
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        background-color: var(--blue);
        svg path {
          fill: var(--white);
        }

        color: var(--primary-white);
        border-bottom-color: var(--blue);
        margin-top: -1px;
        padding-top: 16px;
      }

      &_mob {
        display: none;

        @media (max-width: 768px) {
          display: flex;
        }
      }
    }

    &__footer {
      padding: 15px;
      background: var(--lightGray);
      border-radius: 0 0 6px 6px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0 0 6px 6px;
    right: 0;
  }
}

@media (max-width: 1023px) {
  .container {
    [class*='subheader__toggleitem'] {
      display: none;
    }
  }

  .wrap {
    [data-item='menu'] {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
    }

    [data-item='burger'] {
      position: relative;
      width: 60px;
      height: 70px;
      z-index: 200000;
    }
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
  }

  .wrap [data-item='burger'] {
    height: 60px;
  }

  .logo {
    max-height: 60px;
    min-height: auto;
    width: 250px;

    svg {
      max-height: 60px;
      left: -5px;
      top: 0;
    }
  }
}

.login_icon {
  transform: rotate(-180deg);
}
