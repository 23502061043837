
          @import "src/styles/scss/index.scss";
        
.card {
  overflow: hidden;
}

.container {
  max-width: 1080px;
  padding: 40px 30px 0;
  margin: 0 auto;
}

.ui {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 60px;
}

.icon {
  display: block;
  margin: 0 auto 20px;
}

.title {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;

  width: 100vw;
  max-width: 1140px;
  position: relative;
  color: var(--black);
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
}

.text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;

  text-align: center;
  max-width: 1024px;
  margin: 0 auto 60px;
  color: var(--darkGray);

  b {
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: var(--blue);
    font-weight: bold;
    transition: color 0.3s;

    &:hover {
      color: var(--orange);
    }
  }
}

.pool__image {
  width: auto;
  margin: 0 -40px;

  @media screen and (max-width: 640px) {
    margin: 0 -15px;
  }

  img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .icon {
    width: 80px;
    height: 80px;
  }

  .container {
    padding: 30px 15px 0;
  }
  .title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 15px;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
  }

  .ui {
    max-width: 800px;
    margin-top: 40px;
  }
}
