
          @import "src/styles/scss/index.scss";
        
.box {
  max-width: 1110px;
  padding: 30px 15px 15px 15px;
  background: var(--white);
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  @media (min-width: 1200px) {
    margin: 20px auto 60px;
    padding: 40px 15px 20px 15px;
  }
}
