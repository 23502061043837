
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--blue);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  transition: color 0.3s;

  svg {
    margin-right: 10px;

    path {
      transition: fill 0.3s;
    }
  }

  &:hover {
    color: var(--orange);

    svg path {
      fill: var(--orange);
    }
  }
}
