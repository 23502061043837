
          @import "src/styles/scss/index.scss";
        
.root {
  position: relative;
  z-index: 9;
  padding: 40px 50px;
  background: linear-gradient(180deg, #494949 7.55%, #000000 100%) !important;
}

.subtitle {
  text-align: center;
  margin-top: 90px;
  color: var(--orange);
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}

.arrow {
  margin: 50px auto 76px;
  position: relative;
  z-index: 20;

  &__mob {
    display: none;
  }
}

.decoration {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  max-height: 211px;
  background: var(--white);
  z-index: 1;
}

@media (max-width: 768px) {
  .root {
    padding: 20px;
  }

  .decoration {
    display: none;
  }

  .subtitle {
    margin-top: 30px;
    font-size: 24px;
    line-height: 34px;
  }

  .arrow {
    display: none;
    margin-top: 30px;
    margin-bottom: 60px;

    &__mob {
      display: block;
    }
  }
}
