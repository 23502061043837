
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 40px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 30px 20px 20px;
  }
}
