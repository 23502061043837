
          @import "src/styles/scss/index.scss";
        
.root {
  max-height: calc(100% - 16px);
  display: grid;
  grid-template-rows: 1fr;
  border-radius: 15px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%);
  opacity: 0;
  transition: opacity 0.1s ease-in;
  position: relative;
  max-width: 700px;

  @include max-mobile {
    margin: 0 15px;
    max-height: calc(100% - 150px);
  }
}

.root.rendered {
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: var(--alpha-grey-065);
  backdrop-filter: blur(10px);

  &__default {
    background-color: var(--alpha-grey-065);
  }

  &__dark {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.overlay.opened {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  overflow-y: auto;
  overflow-x: inherit;
  border-radius: 15px;

  @include max-mobile {
    border-radius: 10px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
  z-index: 2;

  svg {
    color: var(--textGray3);

    @include max-mobile {
      width: 18px;
      height: 18px;
    }
  }

  &:hover {
    svg {
      color: var(--orange);
    }
  }

  @include max-mobile {
    top: 6.5px;
    right: 6px;
  }
}
