
          @import "src/styles/scss/index.scss";
        
// @import '@shared/Button/Button.module.scss';

.root {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.body {
  padding: 30px 20px;
  background-color: var(--white);
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.091px;
  color: var(--black);
  margin-top: 20px;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
  color: var(--darkGray);

  &:last-child {
    margin-bottom: 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: var(--alpha-grey-065);
}

.btn {
  min-width: 300px;
  padding-block: 16px;
}

.comment {
  position: relative;

  textarea {
    width: 100%;
    min-height: 90px;
    resize: none;
    padding: 20px 15px;
    border-radius: 10px;
    font-family: inherit;
    border: 1px solid var(--borderGray);
    background: var(--white);
    color: var(--darkGray);

    &::placeholder {
      color: var(--textGray);
    }

    &.error {
      border: 1px solid var(--error);
    }
  }

  label {
    position: absolute;
    left: 10px;
    top: -10px;

    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--middleGray);

    border-radius: 20px;
    border: 1px solid var(--borderGray);
    background: var(--white);
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 2px;
  }

  span {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 6px 10px;

    font-size: 12px;
    font-weight: 700;
  }
}

.length {
  color: var(--darkGray);
}

.empty {
  color: var(--success);
}
.positive {
  color: var(--warning);
}
[dark-theme='true'] .positive {
  color: var(--darkGray);
}
.equals {
  color: var(--middleGray);
}
.negative {
  color: var(--error);
}
