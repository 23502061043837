
          @import "src/styles/scss/index.scss";
        
.scrollToTop {
  opacity: 0;
  background: rgba(247, 147, 26, 1);
  color: rgba(247, 147, 26, 1);
  width: 50px !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 80px;
  right: 0;
  border-radius: 6px 0 0 6px;
  position: fixed;
  cursor: pointer;
  transition: opacity 1s;
  z-index: 1000;
  margin-right: -1px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 6px 0 0 6px;
    border: 1px solid var(--alpha-grey-065);
    pointer-events: none;
    box-sizing: border-box;
  }
}

.scrollToTop:hover {
  background: var(--blue);
  color: var(--blue);

  &::before {
    border: 1px solid var(--blue);
  }
}

.scrollToTopShow {
  opacity: 1;
}
