
          @import "src/styles/scss/index.scss";
        
.notFound__container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 266px);
  margin-top: 30px;
  p {
    letter-spacing: 0.08px;
    color: var(--darkGray);
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.notFound__text {
  margin-bottom: 52px;
  padding: 100px 20px 0 20px;
}
.notFound__card {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1100px;
  width: 100%;
}

.notFound__body {
  > * + * {
    display: block;
    margin-top: 20px;
  }
  img {
    max-width: 100%;
  }
}

.notFound__heading {
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.17px;
  font-weight: 700;
  color: var(--black);
}

.notFound__status {
  color: var(--orange);
}

.notFound__btn {
  max-width: 220px;
  margin: 80px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
