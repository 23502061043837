
          @import "src/styles/scss/index.scss";
        
.arrow {
  max-width: 55px;

  &__left {
    transform: rotate(90deg);
  }

  &__right {
    transform: rotate(-90deg);
  }

  &__top {
    transform: rotate(180deg);
  }
}
