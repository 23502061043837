
          @import "src/styles/scss/index.scss";
        
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  &__item {
    width: 50px;
    font-size: 16px;
    border: none;
    // color: var(--main-static-white);
    // border-radius: 10px;
    // border: 1px solid var(--alpha-grey-static);
    // background: var(--alpha-grey-static-015);

    &--active {
      color: var(--main-static-white);
      background: var(--blue);
      pointer-events: none;
    }

    &_dot {
      width: 50px;
      height: 50px;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      pointer-events: none;
      color: var(--textGray);
    }

    &_svg {
      padding: 0;
      // color: var(--main-static-white);

      &:disabled {
        border: 1px solid;
        border-color: var(--alpha-grey-static);
        color: var(--alpha-grey-static);

        background: transparent;

        svg {
          color: var(--alpha-grey-static);
        }
      }
    }
  }
}
