
          @import "src/styles/scss/index.scss";
        
.arrow {
  transform-origin: center center;
  color: var(--blue);
  margin-left: auto;
  transition: all 0.3s;
}

.top {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s;

  .user__icon {
    color: var(--middleGray);
    transition: color 0.3s;
  }
}

.account__copy {
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: var(--black);

  &:hover {
    svg {
      color: var(--orange);
    }
  }

  svg {
    margin-left: 10px;
    vertical-align: middle;
    color: var(--blue);
  }
}

.container {
  background: var(--white);
  border-radius: 10px;
  button {
    width: 100%;
  }
  &__active {
    .arrow {
      transform: rotate(-180deg);
    }

    .list {
      grid-template-rows: 1fr;
    }
  }
}

.title {
  font-size: 14px;
  font-style: normal;
  transition: color 0.3s;
  font-weight: 700;
  line-height: 24px;
  color: var(--black);
  text-transform: uppercase;
}

.body {
  margin: 0 15px 15px;
  button {
    border-radius: 6px;
  }
}

.email {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  background: var(--lightGray);
  text-transform: uppercase;
  color: var(--black);
  font-weight: bold;
  font-size: 12px;
  position: relative; // is used to position content over the list

  &__required {
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 20px;
    gap: 10px;
    background: var(--Red_BG);
    color: var(--error);

    svg {
      flex-shrink: 0;
    }
  }
}

.list {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s;
  padding-top: 15px;
  margin-top: -15px;
  border: 1px solid var(--borderGray);
  border-top: none;
  border-radius: 0 0 10px 10px;
  overflow: hidden;

  & > div {
    overflow: hidden;
  }

  &__item {
    padding: 15px;
    transition: background 0.3s;
    text-decoration: none;
    &:not(:last-child) {
      border-bottom: 1px solid var(--borderGray);
    }

    display: flex;
    align-items: center;
    gap: 15px;

    svg {
      color: var(--middleGray);
      transition: color 0.3s;
    }

    span {
      transition: color 0.3s;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--black);
      font-size: 13px;
    }

    &:hover {
      background: var(--second-accent);

      svg,
      span {
        color: var(--orange);
      }
    }
  }
}

.open {
  border: none;
}
