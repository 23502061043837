
          @import "src/styles/scss/index.scss";
        
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &__product {
    display: flex;
    align-items: center;
    font-size: 32px;
    line-height: 40px;
    color: var(--black);
    gap: 15px;
    font-weight: bold;
    transition: color 0.3s;

    svg {
      width: 20px;
      color: var(--blue);
      transition: color 0.3s;
    }

    @media (max-width: 1024px) {
      button {
        display: none;
      }
    }

    &:hover,
    &:hover {
      color: var(--orange);

      svg {
        color: var(--main-static-white);
      }

      button {
        background-color: var(--orange);
        border-color: var(--orange);
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 13px 15px;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;

    &__product {
      font-size: 14px;
      line-height: 14px;

      & + div {
        max-width: none;
      }
    }
  }
}

.body {
  padding: 20px;
  gap: 20px;
  display: flex;
  max-width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.btn {
  width: 100%;
  max-width: none;
  margin-top: 15px;
  @include max-mobile {
    margin-top: 10px;
  }
}

.sliderControls > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topCard {
  > div {
    border-bottom: none;
  }
}
