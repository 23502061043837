
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  max-width: 340px;

  svg {
    transition: all 0.3s;
    color: var(--blue);
  }

  &:hover {
    .title,
    .select {
      border-color: var(--orange);
      cursor: pointer;
    }

    .title {
      color: var(--orange);
    }

    svg {
      color: var(--orange);
    }
  }
}

.title {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  padding: 8px 12px;
  background: var(--lightGray);
  color: var(--darkGray);
  border-radius: 10px 0 0 10px;
  transition: all 0.3s;
  border: 1px solid var(--borderGray);
  border-right: none;
  font-weight: bold;
  span {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.select {
  cursor: pointer;
  height: 40px;
  width: 100%;
  background-color: var(--white);
  border-radius: 0 10px 10px 0;
  border: 1px solid var(--borderGray);
  font-size: 14px;
  color: var(--black);
  padding-left: 15px;
  line-height: 34px;
  position: relative;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 25px;
  transition: border-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__list {
    position: absolute;
    z-index: 11;
    width: 100%;
    max-width: 340px;
    right: 0;
    top: calc(100% + 10px);
    background-color: var(--white);
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    max-height: 340px;
    overflow-y: auto;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #b3b3b3;
  }

  span {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 15px;
    max-width: none;
    border-top: 1px solid var(--borderGray);
    background-color: var(--lightGray);
    border-radius: 6px;
  }

  .select {
    max-width: none;

    &__list {
      max-width: none;
      width: auto;
      z-index: 20;
      left: 15px;
      right: 15px;
    }
  }
}
