
          @import "src/styles/scss/index.scss";
        
.body {
  padding: 30px 20px;
  border-radius: 0 0 10px 10px;

  &__title {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    color: var(--darkGray);
    margin-bottom: 40px;

    b {
      font-weight: bold;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  color: var(--black);
  gap: 10px;

  i {
    margin-top: 5px;
    color: var(--middleGray);
  }

  @media (max-width: 768px) {
    i {
      margin-top: 0;
    }
  }
}

.count {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: var(--lightGray);
  font-size: 12px;
  font-weight: bold;
  color: var(--middleGray);
}

.promos {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.add {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;
  max-width: 797px;

  &__title {
    margin: 20px 0 15px;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: var(--black);
    text-align: center;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
    text-align: center;
    color: var(--darkGray);
  }

  &__btn {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
    width: 100%;
    svg path {
      transition: fill 0.1s;
    }

    &:hover {
      svg path {
        fill: var(--orange);
      }
    }
  }
}

.empty {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;

  .img {
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 15px;
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
  }

  .desc {
    font-size: 18px;
    line-height: 28px;
    color: var(--middleGray);
  }

  .btn {
    margin: 30px auto 0;
    max-width: 300px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    border: 1px solid var(--borderGray);
    background-color: var(--white);
    height: 50px;
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-align: center;
    transition: all 0.3s;

    &:hover {
      color: var(--white);
      border-color: var(--blue);
      background: var(--blue);

      svg path {
        fill: var(--white);
      }
    }

    svg {
      margin-right: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .empty {
    padding-top: 20px;

    .btn {
      border: none;
      border-radius: 0px 0px 10px 10px;
      max-width: none;
      border-top: 1px solid var(--borderDark);
    }
  }
}

@media (max-width: 768px) {
  .body__adapt {
    padding: 30px 0 0;

    .add {
      padding: 0 20px 0;

      &__btn {
        margin: 0 -20px;
        width: calc(100% + 40px);
        max-width: none;
        border-radius: 0px 0px 6px 6px;
        border-bottom: 0px;
        border-right: 0px;
        border-left: 0px;
      }
    }

    .body__title {
      padding: 0 15px;
    }
  }
}
.top {
  background: var(--lightGray);
}
