
          @import "src/styles/scss/index.scss";
        
.Earnings_Graph {
  margin-bottom: 20px;
}

.top {
  font-size: 32px;
  font-weight: bold;
  color: var(--black);
}

.footer {
  background: var(--alpha-grey-065);
  border-top: 1px solid var(--borderGray);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;

  & > * {
    max-width: 300px;
    width: 100%;
  }
}

.earnings {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 32px;
  color: var(--black);
}

@media (max-width: 1024px) {
  .top {
    padding: 15px;
    font-size: 14px;
    & + div {
      margin: 0 15px 15px;
      min-width: 300px;
      width: calc(100% - 30px);
    }
  }

  .footer {
    padding: 15px;

    & > * {
      max-width: none;
    }
  }
}
.graph {
  box-shadow: none;
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
