
          @import "src/styles/scss/index.scss";
        
#all_share {
  .social-btns {
    flex-wrap: nowrap !important;
    max-width: 100% !important;
    gap: 15px;
    width: 100%;
    & > .social-btn,
    .social-btns__more {
      height: 72px;
      margin: 0;
      width: 100%;
      flex-shrink: 1;
      border-radius: 36px;
    }

    .social-btns__more {
      background-color: var(--black);
      box-shadow: none;
      border: 1px solid var(--darkGray);
    }

    .social-btn {
      &_fb {
        background: #1877f2;

        &:before {
          width: 40px;
          height: 40px;
        }
      }

      &:before {
        width: 40px;
        height: 40px;
        @media (min-width: 768px) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .social-btns {
      flex-wrap: wrap !important;

      & > .social-btn,
      .social-btns__more {
        width: calc((100% - 30px) / 3);
        margin: 0 !important;
      }
    }
  }
}
