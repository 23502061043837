
          @import "src/styles/scss/index.scss";
        
.container {
  overflow: hidden;
  background: var(--white);
  padding-top: 80px;

  div[class*='_icon'] {
    svg {
      display: block;
      height: 90px;
      width: auto;
    }
  }
}

.card {
  padding: 15px;
  @media (min-width: 1024px) {
    padding: 40px;
  }
}

.title {
  text-decoration: none;
  transition: color 0.3s;
  color: var(--black) !important;

  &:hover {
    color: var(--blue) !important;
  }
}

.primary {
  display: flex;
  max-width: 320px;
  max-height: 60px;
  height: 60px;
}

.default {
  display: flex;
  max-width: 320px;
  max-height: 60px;
  height: 60px;

  svg rect {
    transition: fill 0.3s;
  }
}

.img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.divider {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: 2000px;
  margin-top: 60px;
}

.btns {
  display: flex;
  gap: 10px;
  margin: 40px auto;
  @media (min-width: 768px) {
    gap: 20px;
  }
  justify-content: center;
  & > * {
    max-width: 320px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;

    div[class*='_icon'] {
      svg {
        display: block;
        height: 70px;
        width: auto;
      }
    }

    div[class*='_text'] {
      margin-bottom: 30px;
    }
  }

  .btns {
    flex-direction: column;
    margin: 0px auto;

    & > * {
      max-width: 100%;
      width: 100%;
    }
  }

  .divider {
    margin-top: 30px;
  }

  .default {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .default,
  .primary {
    max-width: none;
  }

  .img {
    max-width: 100%;

    &__nft_mob {
      width: 100vw;
      max-width: none;
      margin-left: -20px;
    }
  }
}
