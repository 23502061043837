
          @import "src/styles/scss/index.scss";
        
.bot {
  margin: 0 -25px -40px;

  @media (max-width: 768px) {
    position: relative;
    width: 550px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 0 -35px 0;
  }
}

.desc {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  @media (max-width: 768px) {
    font-size: 15px;
    font-style: normal;
    line-height: 22px;
  }
}

.title {
  @media (max-width: 768px) {
    text-align: center;

    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

.desc {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.button {
  min-width: 320px;
  margin-top: 20px;
  margin-bottom: -20px;
  background-color: var(--white);
}
