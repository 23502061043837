.rangeslider {
  margin: 0;
  box-shadow: none;
  background-color: var(--borderGray);
}

.rangeslider-horizontal {
  height: 8px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: var(--blue);
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle {
  background-color: var(--white);
  border: 6px solid var(--blue);
  box-shadow: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}
