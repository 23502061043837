
          @import "src/styles/scss/index.scss";
        
.overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  z-index: 10000;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
}

.container {
  animation: fadeIn 0.3s ease-out;
  margin-top: auto;
  margin-bottom: auto;
}

.content {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.body {
  position: relative;
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px 15px;

  &:has(+ .footer) {
    border-radius: 10px 10px 0 0;
  }

  @include max-mobile {
    padding: 30px 15px;
  }
}

.icon {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  height: 80px;
  > * {
    width: 100%;
    height: 100%;
  }
}

.innerContent {
  max-width: 500px;
  margin: 0 auto;
}

.titleDescription {
  color: var(--black);
  .title {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.09142857044935226px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 10px;

    @include max-mobile {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .description {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.footer {
  display: flex;
  gap: 15px;
  border-top: 1px solid var(--borderGray);
  background-color: var(--lightGray);
  padding: 20px 15px;
  border-radius: 0 0 10px 10px;

  > * {
    flex-grow: 1;
  }

  // ensure there are exactly two children
  &:not(:has(:nth-child(2):last-child):has(:nth-child(3))) {
    > * {
      max-width: 320px;
      width: 100%;
      margin: 0 auto;

      @include max-mobile {
        max-width: none;
      }
    }
  }

  @include max-mobile {
    padding: 15px;
    flex-direction: column-reverse;
    background: rgba(23, 23, 23, 0.65);
    border-top: none;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
  z-index: 2;

  svg {
    color: var(--textGray3);

    @include max-mobile {
      width: 18px;
      height: 18px;
    }
  }

  &:hover {
    svg {
      color: var(--orange);
    }
  }

  @include max-mobile {
    top: 6.5px;
    right: 6px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
