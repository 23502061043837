
          @import "src/styles/scss/index.scss";
        
.container {
  background: var(--white);
  border-radius: 10px;
}

.cell {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--borderGray);
  }
}

.url {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;

  & > * {
    transition: all 0.3s;
  }

  svg {
    color: var(--middleGray);
    flex-shrink: 0;
  }

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: var(--middleGray);
  }

  &:hover {
    svg,
    span {
      color: var(--orange);
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-weight: bold;
    color: var(--black);
    font-size: 16px;
  }
}

.version {
  font-size: 14px;
  font-weight: bold;
  color: var(--black);
  span {
    color: var(--textGray2);
  }
}

span.bonus {
  margin-left: 10px;
  padding: 2px 6px;
  width: max-content;
  text-transform: uppercase;
  color: var(--primary-white) !important;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  height: 16px;
  font-weight: 700;
  border-radius: 12px;

  &__orange {
    background: var(--orange);
  }

  &__blue {
    background: var(--hydro);
  }

  &__red {
    background: var(--error);
  }

  &__purple {
    background: var(--purple);
  }
}
