
          @import "src/styles/scss/index.scss";
        
.tabs {
  background-color: var(--white);
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 auto 20px;
  width: 100%;
  justify-content: space-between;
  box-shadow: var(--card-shadow);

  &__title {
    color: var(--black);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  &__switch {
    display: flex;
    max-width: 400px;
    width: 100%;

    &_item {
      flex: 1;
      height: 40px;
      gap: 8px;
      padding-inline: 0;
      color: var(--black);

      &:nth-child(1) {
        border-radius: 6px 0 0 6px;
        border-right: 0;
      }

      &:nth-child(2) {
        border-left: 0;
        border-radius: 0 6px 6px 0;
      }

      &_active {
        cursor: default;
        color: var(--white);
      }
    }
  }
}

[dark-theme='true'] .tabs__switch_item {
  color: var(--main-static-white);
}

.promos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

.stats {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
}

.row {
  max-width: none;
  flex: 1;
  div {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .tabs {
    border: none;
    padding: 10px 15px;
    &__title {
      display: none;
    }

    &__switch {
      max-width: none;
    }
  }

  .stats {
    flex-direction: column;
  }
}
