
          @import "src/styles/scss/index.scss";
        
@keyframes countingBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.Overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f7931a;
  overflow: hidden;
  user-select: none;

  &__container {
    display: flex;
    flex-direction: column;
    width: 840px;
    height: 690px;
    position: relative;
    z-index: 2;
    border-radius: 10px 10px 0 0;
    &__second {
      height: 600px;
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &__slideEntryForward {
      left: -1240px;
      transition: 0.3s;
    }
    &__slideBeforeForward {
      left: 1240px;
    }
    &__slideEntryBack {
      left: 1240px;
      transition: 0.3s;
    }
    &__slideBeforeBack {
      left: -1240px;
    }
    &__slideExit {
      left: 0;
      transition: 0.1s;
    }
  }
  &__textBlock {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 740px;
    padding-top: 60px;
  }
  &__pagination {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 90px;
    background-color: rgba(23, 23, 23, 0.65);
    border-radius: 0 0 10px 10px;
  }
  &__title {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
  }
  &__text {
    color: #494949;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    & a {
      color: #4285f4;
      text-decoration: none;
      font-weight: bold;
    }
  }
  &__picture {
    display: flex;
    align-self: flex-end;
    width: 100%;
    pointer-events: none;
  }
  &__links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
  &__link {
    display: flex;
    column-gap: 12px;
    border-radius: 10px;
    border-color: transparent;
    background: #fff;
    width: 300px;
    height: 50px;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-decoration: none;
    color: #4285f4;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    &__colorSvg {
      & path {
        fill: #4285f4;
      }
      &:hover {
        path {
          fill: #fff;
        }
      }
    }
    &__icon {
      width: 20px;
      height: 20px;
    }
    &__icon2 {
      width: 12px;
      height: 12px;
    }
    &__blue {
      background-color: #4285f4;
      color: #fff;
    }
    &__black {
      color: #000;
      &:hover {
        background-color: #4285f4 !important;
        color: #fff;
        border-color: transparent;
      }
    }
    &:hover {
      background-color: #f7931a;
      color: #fff;
      border-color: transparent;
      &:hover > path {
        fill: #fff;
      }
    }
  }
  & .close {
    position: absolute;
    top: 15.8px;
    cursor: pointer;
    right: 15.8px;
    height: 15px;
    transition: all 0.3s;
    z-index: 10;
    border: none;
    background-color: transparent;

    padding: 0;

    &:hover path {
      fill: #f7931a;
      opacity: 1;
    }
  }
  & .sliderButton {
    display: flex;
    width: 50px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    border: none;
    cursor: pointer;

    &__hidden {
      visibility: hidden;
    }

    &:disabled {
      background: #d6d6d6;
      path {
        fill: #8e8e8e;
        opacity: 1;
      }
      &:hover {
        background: #d6d6d6;
        path {
          fill: #8e8e8e;
          opacity: 1;
        }
      }
    }
    &:hover {
      background: #f7931a;
      path {
        fill: #fff;
      }
    }
  }

  & .sliderDots {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }

  & .dot {
    margin: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(117, 117, 117, 0.7);
    cursor: pointer;
    // Transition for smooth background color change
    //transition: background-color 0.3s, width 0.3s, height 0.3s;
    &:hover {
      background-color: #f7931a;
    }
  }

  & .activeDot {
    width: 15px;
    height: 15px;
    background-color: #4285f4;
    &:hover {
      background-color: #4285f4;
    }
  }
  & .linkIcon {
    margin-right: 8px;
  }
  & .parallax-scene {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }
  & .parallax-scene img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
  }
}

@media screen and (min-width: 1024px) and (max-height: 720px) {
  .Overview {
    height: auto;
  }
}

@media screen and (max-width: 1024px) {
  .Overview {
    min-width: 380px;
    padding: 0 20px;
    &__container {
      width: 100%;
    }
    &__textBlock {
      padding: 60px 20px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .Overview {
    min-width: 280px;
    padding: 0;
    justify-content: flex-start;
    height: auto;
    &__container {
      width: 100%;
      height: 100dvh;
      border-radius: 0;
      background-color: #fff;
      &__second {
        top: 58px;
        height: calc(100dvh - 58px);
        background-color: #fff;
      }
    }
    &__pagination {
      display: flex;
      justify-content: center;
      top: 0;
      border-radius: 0;
      padding: 0;
      padding-top: 15px;
      height: 5px;
      background-color: transparent;
    }
    &__textBlock {
      padding: 20px 15px 0;
      row-gap: 15px;
    }
    &__title {
      font-size: 26px;
      line-height: 36px;
    }
    &__text {
      font-size: 15px;
      line-height: 22px;
    }
    &__link {
      width: 280px;
    }

    & .sliderDots {
      padding: 0 15px;
    }

    & .dot {
      position: relative;
      width: 100%;
      height: 3px;
      border-radius: 4px;
      background-color: #d6d6d6;
      &:hover {
        background-color: #d6d6d6;
      }

      i {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 3px;
        background-color: var(--theme-color-grey9);
        border-radius: 4px;
      }

      b {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 0;
        height: 3px;
        background-color: #4285f4;
        border-radius: 4px;
      }
    }

    & .activeDot {
      background-color: #d6d6d6;

      b {
        animation-name: countingBar;
        animation-duration: 7s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-direction: alternate;
        animation-fill-mode: forwards;
      }
    }

    & .filledDot {
      background-color: #4285f4;
      width: 100% !important;
    }

    & .close {
      top: 28px;
    }

    & .sliderButton {
      display: none;
    }
  }
}
