
          @import "src/styles/scss/index.scss";
        
.title {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  &__link {
    text-decoration: none;
    color: var(--black);
    font-size: 32px;
    line-height: 37px;
    transition: color 0.3s;
    font-weight: bold;
  }

  &__count {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: var(--lightGray);
    font-size: 12px;
    font-weight: bold;
    color: var(--middleGray);
  }
}
.wrapper {
  margin-bottom: 20px;

  @include max-tablet {
    margin-bottom: 15px;
  }
}
.row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 17px 18px 17px 20px;
  text-decoration: none;
  gap: 20px;
  color: var(--black);
  border-bottom: 1px solid var(--borderGray);
  transition: background 0.3s;
  max-height: 60px;

  & > *:nth-child(1) {
    max-width: 120px;
  }

  & > *:nth-child(2) {
    max-width: 80px;
    text-align: center;
  }

  & > *:nth-child(3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
    flex-shrink: 0;
    max-width: 236px;
  }

  & > *:last-child {
    width: 110%;
  }

  & > *:not(:first-child, :last-child) {
    text-align: center;
  }

  &__header {
    background-color: var(--lightGray);
    padding: 17px 20px;

    @include max-tablet {
      display: none;
    }
    span {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
    }
  }
  &__nameText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left !important;
    font-size: 14px;
    line-height: 18px;
    color: var(--orange);
    font-weight: 600;
  }
  & > div {
    width: 100%;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &_id {
      font-size: 16px;
      color: var(--black);
      font-weight: 700;
      width: 100px;
    }

    &_text {
      overflow: hidden;
      color: var(--orange);
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 120%;
    }
  }

  &__copy {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &_tooltip {
      position: absolute;
      top: 100%;

      color: var(--main-static-white);
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px;
      border-radius: 3px;
      width: max-content;
      font-size: 12px;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      transition: all 0.1s;
      right: 0;
      @include max-tablet {
        right: auto;
        left: 30%;
      }
    }

    &_area {
      cursor: pointer;
      padding: 10px;

      svg {
        width: 20px;
        height: 20px;
      }

      path {
        transition: fill 0.3s;
      }
      &:hover {
        path {
          fill: var(--orange);
        }

        & + .row__copy_tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__num {
    font-weight: bold;
    font-size: 16px;
    color: var(--black);
    @include max-tablet {
      font-size: 14px;
    }
  }

  &__btc {
    &_balance {
      display: flex;
      gap: 5px;
      font-size: 16px;
      color: var(--black);
      @include max-tablet {
        font-size: 14px;
      }
      span {
        font-weight: bold;
      }
    }

    &_rate {
      font-size: 10px;
      margin-top: 5px;
      color: var(--middleGray);
    }
  }

  &:hover {
    background: var(--lightGray);
    .row__name_id {
      color: var(--orange);
    }
  }

  &__comm {
    width: 140px;

    color: var(--gray-3);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include max-tablet {
      font-size: 12px;
    }
    @include max-tablet {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: end;
    }
    &_cb {
      width: 180% !important;
      max-width: 240px !important;
      text-align: right;
      color: var(--middleGray);
      font-size: 12px;
      line-height: 1;
    }
  }
}

.body {
  max-width: 100%;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    background-color: #ebebeb;
    margin-top: 15px;
    height: 7px;
    border-radius: 50px;
    padding: 0 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--orange);
    border-radius: 50px;
  }
}
.footer {
  border-top: 1px solid var(--borderGray);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: -20px;
  background-color: var(--alpha-grey-065);
  border-radius: 0 0 10px 10px;

  @include max-tablet {
    margin-top: -15px;

    a {
      width: 100%;
    }
  }

  &_show_viewall_btn {
    justify-content: space-between;
  }

  a:first-child {
    margin-right: auto;
  }

  a:last-child {
    margin-left: auto;
  }
}

.card {
  border-radius: 10px 10px 0 0;
}
.link {
  &_btn {
    max-width: 300px;
    width: 100%;
    display: flex;
    border: none;

    svg path {
      transition: fill 0.3s;
    }
  }
}
.refresh {
  cursor: pointer;
  color: var(--blue);
  margin-left: 5px;

  @include max-tablet {
    margin-left: auto;
  }
}

.refresh:hover {
  color: var(--orange);
}

.loading {
  pointer-events: none;
  color: var(--gray-3);
  background-color: var(--gray-8);

  svg {
    animation: rotate 1s linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

[dark-theme='true'] .total {
  background: var(--lightGray);

  @include max-tablet {
    background: var(--lightGray);
  }
}
.total {
  background: var(--second-orange);
  .row__name_id {
    font-size: 20px;
    line-height: 24px;
  }
  .row__num {
    font-size: 18px;
  }
  @include max-tablet {
    background: var(--second-orange);
  }
  @include max-tablet {
    border: 1px solid var(--borderGray);
    border-radius: 10px;
  }
  .name {
    color: var(--black);
    height: 50px;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  .line {
    &:nth-child(2) {
      border-top: 1px solid var(--borderGray);
      border-radius: 10px 10px 0 0;
    }
  }
}
.table {
  display: table;
  width: 100%;
  max-width: 1100px;
  @include max-tablet {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }
  @include max-tablet {
    overflow: hidden;
  }
}

@include max-tablet {
  .title {
    padding: 7px 15px 0;
    & > a {
      font-size: 14px;

      text-transform: uppercase;
      line-height: 100%;

      & + svg {
        width: 22px;
        height: 20px;
      }
    }

    & > span {
      font-size: 14px;

      text-transform: uppercase;
      line-height: 100%;

      & + svg {
        width: 22px;
        height: 20px;
      }
    }

    & + div {
      margin-right: 15px;
      margin-left: 15px;
      margin-top: 5px;
      width: calc(100% - 30px);
      min-width: 300px;
      @include max-tablet {
        text-wrap: wrap;
      }
    }
  }
}
.card__header {
  @include max-tablet {
    border: none;
  }
}
@include max-tablet {
  .footer {
    border-top: none;
    padding: 15px;
    background: var(--alpha-grey-065);
    flex-wrap: wrap;
    gap: 15px;
  }

  .link_btn {
    border: none;

    max-width: none;
    border-radius: 10px;
  }
}
.row_mob {
  background: var(--lightBlue);
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  margin-bottom: 10px;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 50px;
  border-radius: 10px 10px 0 0;
  padding: 5px 10px 5px 10px;

  .row__name {
    display: flex;
    align-items: center;
    svg {
      color: #757575;
      margin-right: 10px;
    }
  }
}
.line {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background: var(--white);
  height: 50px;
  gap: 30px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--borderGray);
  }
  &:first-child {
    border-top: 1px solid var(--borderGray);
    border-radius: 10px 10px 0 0;
  }
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
  .heading {
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
  }
}
[dark-theme='true'] .sectionHeader {
  background: var(--lightGray);
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-bottom: 2px solid var(--orange);
  background: var(--second-orange);
  color: var(--black);
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;

  @include max-tablet {
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
    padding: 10px;
  }
}
