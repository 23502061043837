
          @import "src/styles/scss/index.scss";
        
.wrapper {
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
    padding: 16px 0;
  }

  &__icon {
    width: 80px;
    height: 80px;
  }

  &__title {
    margin-top: 20px;
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
    color: var(--black);

    @media (max-width: 640px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
    }
  }

  &__description {
    margin-top: 8px;
    color: var(--middleGray);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;

    @media (max-width: 640px) {
      padding: 1px;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}
