@mixin max-laptop {
  @media (max-width: $max-laptop) {
    @content;
  }
}

@mixin max-tablet {
  @media (max-width: $max-tablet) {
    @content;
  }
}

@mixin max-mobile {
  @media (max-width: $max-mobile) {
    @content;
  }
}

@mixin max-small-mobile {
  @media screen and (max-width: $max-small-mobile) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
