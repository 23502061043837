
          @import "src/styles/scss/index.scss";
        
.container {
  padding: 40px 20px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  position: relative;
}

.decoration {
  position: absolute;
  width: 100vw;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  height: 300px;
  background: var(--orange);
  z-index: 0;
}

.card {
  max-width: 700px;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  color: var(--black);
  padding: 20px 30px;
  border-bottom: 1px solid var(--borderGray);
  background: var(--white);
  border-radius: 10px 10px 0 0;
}

.body {
  padding: 20px 30px 0px;
  background: var(--white);
}

.content {
  max-width: 500px;
  margin: 0 auto;
}

.text {
  font-size: 15px;
  line-height: 24px;
  color: var(--darkGray);
  margin-bottom: 20px;
}

.subtitle {
  font-size: 16px;
  color: var(--error);
  font-weight: 700;
  margin-bottom: 40px;
}

.captcha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: flex-end;
  padding: 20px 30px;
  background: var(--alpha-grey-065);
  border-radius: 0px 0px 10px 10px;

  button {
    display: flex;
    width: 100%;
    border: none;
  }
}

.error {
  padding-bottom: 15px;
  text-align: center;
  font-size: 12px;
  color: var(--error);
  min-height: 15px;
}

.checkbox {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  cursor: pointer;
}

.checkboxRed {
  color: var(--error);
}

.checkbox input {
  margin-top: 6px;
  width: 20px;
  height: 20px;
  background: transparent;
  color: var(--black);
}

@media (max-width: 768px) {
  .card {
    min-height: 540px;
  }

  .title {
    padding: 15px 20px;
  }

  .decoration {
    top: 0;
  }

  .body {
    padding: 20px 20px 0px;
  }

  .buttons {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .container {
    padding: 20px;
  }
}
