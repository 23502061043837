
          @import "src/styles/scss/index.scss";
        
.container {
  margin-bottom: 30px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 15px;
  color: var(--black);
}

.error {
  margin-top: 5px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  color: var(--error);
}

.input {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--borderGray);
  padding-bottom: 15px;
  font-size: 16px;
  background: transparent;
  color: var(--black);

  &::placeholder {
    color: var(--borderGray);
  }
}

.textarea {
  display: block;
  width: 100%;
  outline: none;
  border-radius: 6px;
  border: 1px solid var(--borderGray);
  padding: 10px 15px;
  min-height: 130px;
  background: transparent;
  color: var(--black);
  max-height: 200px;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    border-color: var(--blue);
  }
}

.messageLength {
  margin-top: 20px;
  text-align: right;
}
