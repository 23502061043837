
          @import "src/styles/scss/index.scss";
        
.address {
  margin-top: 20px;
  width: 100%;
  padding: 14px 13px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  display: flex;
  align-items: center;
  position: relative;
  transition: border 0.3s;

  @media (max-width: 768px) {
    padding: 12px 13px;
  }

  &:has(input:disabled) {
    background-color: var(--lightGray);
  }

  &:has(input:focus) {
    border: 1px solid var(--blue);
    background-color: var(--white);
  }

  &__input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    background: transparent;
    color: var(--black);
    line-height: 18px;

    &:disabled {
      background-color: var(--lightGray);
    }

    &::placeholder {
      color: var(--textGray);
      font-weight: normal;
    }
  }

  &__logo {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}
