
          @import "src/styles/scss/index.scss";
        
.calcResultItem {
  display: flex;
  padding: 10px 0;

  &__total {
    background-color: var(--orange);
    margin-top: -1px;
  }

  &__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &__wide {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 640px) {
        flex: 1;
      }
    }
  }

  &__value {
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
    text-align: center;

    @media screen and (max-width: 640px) {
      font-size: 20px;
      line-height: 22px;
    }

    &__small {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.206px;
      text-align: center;

      @media screen and (max-width: 640px) {
        font-size: 12px;
        line-height: 16px;
      }

      b {
        font-weight: 700;
      }

      span {
        display: block;
        color: #a4a4a4;

        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.176px;
        padding-top: 6px;

        > b {
          color: var(--orange);
        }
      }

      &_orange {
        color: var(--lightOrange) !important;
      }
    }
  }
}
