
          @import "src/styles/scss/index.scss";
        
.btc_chart {
  width: 190px;

  &__container {
    display: flex;
    align-items: center;
  }

  &__rates {
    border-left: 2px solid var(--borderGray);
    padding-left: 16px;
    margin-left: 15px;

    b {
      display: block;
      font-size: 12px;
      line-height: 14.5px;
      letter-spacing: 00.03px;
      font-weight: bold;
      color: var(--gray-2);
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    span {
      color: var(--gray-3);
      font-size: 12px;
      line-height: 14px;
    }
  }

  .recharts-tooltip-wrapper {
    top: -30px !important;
    transform: translate(-50%, 0) !important;
    left: 50% !important;
  }
}
@media (max-width: 1024px) {
  .btc_chart {
    &__container {
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    &__rates {
      border: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;

      b {
        margin-bottom: 0;
        margin-right: 10px;
        font-size: 13px;
        line-height: 15px;
        color: var(--black);
      }

      span {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
.btc_chart {
  :global(.recharts-tooltip-wrapper) {
    top: -30px !important;
    transform: translate(-50%, 0) !important;
    left: 50% !important;
    @media (max-width: 1024px) {
      left: auto !important;
      transform: none !important;
      right: -10px !important;
    }
  }
}
