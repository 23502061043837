
          @import "src/styles/scss/index.scss";
        
.parallaxBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 1240px;
  margin: 0 auto;

  > div {
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
  }

  &__particle_1 {
    top: 90px;
    left: 224px;
  }

  &__particle_2 {
    top: 68px;
    right: 398px;
  }

  &__particle_3 {
    top: 128px;
    right: 111px;
  }

  &__particle_4 {
    left: 40px;
    top: 268px;
  }

  &__particle_5 {
    bottom: 337px;
    left: 175px;
  }

  &__particle_6 {
    bottom: 300px;
    right: 281px;
  }

  &__particle_7 {
    right: 24px;
    bottom: 385px;
  }

  &__particle_8 {
    left: -21px;
    bottom: 200px;
    //bottom: 300px;
    //right: 281px;
  }

  @media (max-width: 1500px) {
    max-width: 100vw;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    display: none;
  }
}
