
          @import "src/styles/scss/index.scss";
        
.tab {
  border-radius: 6px;
  max-width: 300px;
  margin: 0 auto 30px;
  background-color: var(--white);
  border: 1px solid var(--borderGray);
  display: flex;
  align-items: center;

  &__title {
    padding: 12px 20px;
    border-radius: 6px 0px 0px 6px;
    background-color: var(--lightGray);
    border-right: 1px solid var(--borderGray);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  &__list {
    margin: 0 auto;
  }

  &__promo {
    padding: 5px 10px;
    transition: color 0.3s;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: var(--blue);
    }
  }

  &__land {
    display: flex;
    align-items: center;
    padding: 10px;
    transition: background 0.3s;
    cursor: pointer;
    img {
      width: 68px;
      height: 42px;
      margin-right: 15px;
    }

    span {
      font-weight: bold;
      font-size: 14px;
    }

    &:hover {
      background: var(--blue);

      span {
        color: var(--white);
      }
    }
  }
}

@media (max-width: 768px) {
  .tab {
    flex-wrap: wrap;

    &__title {
      width: 100%;
      border-radius: 6px 6px 0px 0px;
      text-align: center;
      padding: 10px;
      border-right: none;
      border-bottom: 1px solid var(--borderGray);
    }

    &__list {
      padding: 10px;
    }
  }
}
