
          @import "src/styles/scss/index.scss";
        
.result {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;

  @include max-mobile {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.rectangle {
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}

.square,
.storie {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @include max-mobile {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
