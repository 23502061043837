
          @import "src/styles/scss/index.scss";
        
.container {
  margin-bottom: 30px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 15px;
  color: var(--black);
}

input[type='file'] {
  display: none;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.title {
  color: var(--black);
}

.button {
  border: 1px solid var(--borderGray);
  background: transparent;
  border-radius: 6px;
  max-width: 160px;
  width: 100%;
  line-height: 36px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  transition: all 0.3s;
  color: var(--black);

  &:hover {
    border-color: var(--orange);
    background-color: var(--orange);
    color: var(--white);
  }
}

.info {
  margin-top: 15px;
  font-size: 12px;
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
}
