
          @import "src/styles/scss/index.scss";
        
.list {
  position: absolute;
  background: var(--white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 15px;
  z-index: 10;
  right: 0;

  div[class^='social-btns'] {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    & > div {
      margin: 0;
    }
  }
}

.btn {
  position: relative;
  gap: 0;
}
