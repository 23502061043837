
          @import "src/styles/scss/index.scss";
        
.container {
  background: var(--white);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  transition: background 0.3s;

  svg:first-child {
    color: var(--middleGray);
    transition: color 0.3s;
  }
}

.switch {
  margin: -5px 0;
  margin-left: auto;
}

.title {
  font-size: 14px;
  font-style: normal;
  transition: color 0.3s;
  font-weight: 700;
  line-height: 24px;
  color: var(--black);
  text-transform: uppercase;
}

.arrow {
  margin-left: auto;
  color: var(--blue);
  transition: color 0.3s;
}

.container:hover {
  background: var(--lightGray);
  .title,
  svg {
    color: var(--orange);
  }
}
