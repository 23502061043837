
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 40px 40px 0;
  overflow: hidden;
}

.img {
  margin: 40px auto 0;
  display: block;
  max-width: 100%;
}

.share {
  padding: 30px;
  background: var(--gray-1);
  border-radius: 0 65px 65px 65px;
  max-width: 711px;
  margin: 60px auto 0;
  position: relative;

  &__label {
    padding: 12px;
    background-color: var(--orange);
    width: max-content;
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: var(--primary-white);
    position: absolute;
    left: 0;
    top: -22px;
  }
}

@media (max-width: 768px) {
  .img {
    margin-top: 20px;
    min-width: 780px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .container {
    padding-top: 60px;
  }
}
