
          @import "src/styles/scss/index.scss";
        
.feedbackCard {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  min-height: 540px;
  margin: 0 auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;

  &__title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    padding: 20px 20px;
    color: var(--black);
    border-bottom: 1px solid var(--borderGray);
    background: var(--white);
  }

  &__body {
    padding: 30px 40px;
    background: var(--white);
    flex: 1;
  }

  &__description {
    margin-bottom: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: var(--darkGray);
  }

  &__subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.18px;
    color: var(--black);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 15px 0 30px;

    font-size: 15px;
    line-height: 24px;
    color: var(--gray-2);

    p {
      color: var(--gray-2);

      a {
        color: var(--blue);

        &:hover {
          color: var(--orange);
        }
      }
    }

    ul {
      list-style-position: inside;
      list-style-type: disc;
    }

    &_choices {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    &_inner {
      > * + * {
        margin-top: 20px;
      }
    }
  }

  &__choice {
    padding-block: 9px;
    padding-inline: 14px;
    border-radius: 10px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04px;
    text-transform: none;

    font-weight: 400;
    color: var(--gray-2);

    &:hover {
      background: #fff5e9;
      border-color: #ffdfb8;
    }
  }

  &__controls {
    display: flex;
    margin-top: auto;
    padding: 20px 20px;
    gap: 30px;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid var(--borderGray);
    background: var(--lightGray);
    justify-content: space-between;

    > * {
      width: 100%;
      max-width: 300px;
      height: 50px;
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    &__title {
      padding: 15px 20px;
    }

    &__body {
      padding: 20px 20px 40px;
    }

    &__controls {
      padding: 20px;
      flex-direction: column;
      gap: 20px;

      > * {
        max-width: none;
      }
    }
  }
}
