
          @import "src/styles/scss/index.scss";
        
.banner {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: linear-gradient(269deg, #194388 -2.17%, #0e203e 97.07%);
  min-height: 130px;
  display: flex;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-image: url('./assets/snow_pattern.png');
  background-size: 250px;
  background-repeat: repeat;
  background-position: center;

  img {
    height: 175px;
  }
}

.close {
  position: absolute;
  width: 12px;
  height: 12px;
  padding: 0;
  top: 8px;
  right: 8px;
  border: none;
  background-color: transparent;
  z-index: 1;

  svg {
    width: 12px;
    height: 12px;

    path {
      transition: fill 0.3s;
    }
  }

  &:hover {
    svg path {
      fill: var(--orange);
    }
  }
}

.content {
  position: relative;
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.texts {
  position: relative;
}

.title {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.137px;
}

.description {
  max-width: 500px;
  color: var(--Grey_2, #d1d2d6);

  /* New Fonts/Paragraphs/P_4 (R) */
  font-family: var(--font-family-text, Inter);
  font-size: var(--font-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-hight-s, 24px); /* 171.429% */
  margin-top: 10px;
  text-shadow: -1px -1px 1px #0b0e10, -1px 0 1px #0b0e10, -1px 1px 1px #0b0e10, 0 -1px 1px #0b0e10, 0 1px 1px #0b0e10,
    1px -1px 1px #0b0e10, 1px -0px 1px #0b0e10, 1px 1px 1px #0b0e10;
}

.candiesCollectedContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  .candiesCollectedTitle {
    color: #fff;
    /* New Fonts/Paragraphs/P_2 (B) */
    font-family: var(--font-family-text, Inter);
    font-size: var(--font-size-m, 18px);
    font-style: normal;
    font-weight: 700;
    line-height: var(--font-line-hight-l, 30px); /* 166.667% */
  }

  .candyIcon {
    width: 32px;
    height: 32px;
  }

  .candiesCollectedLabel {
    flex-shrink: 0;
    display: flex;
    height: 32px;
    padding: var(--0, 0px) var(--Spacing-Space-x3, 15px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Spacing-Space-x4, 20px);
    border: var(--0, 1px) solid #1da1f2;
    background: #172f55;
    color: #fff;
    font-family: var(--font-family-text, Inter);
    font-size: var(--font-size-m, 18px);
    font-style: normal;
    font-weight: 700;
    line-height: var(--font-line-hight-l, 30px); /* 166.667% */
  }
}

.buttons {
  .button {
    background: #4285f4;
    border-color: #4285f4;
    box-shadow: 0px 0px 40px 0px rgba(55, 204, 51, 0.4);
    min-width: 300px;
    &:hover {
      background: #fff;
      border-color: #fff;
      color: #4285f4;
    }
  }
}

.responsiveBanner {
  .background {
    flex-direction: column;

    img {
      height: auto;
      width: 100%;
    }
  }

  .description {
    max-width: 100%;
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: auto;
      height: auto;

      path {
        fill: var(--textGray);
      }
    }
  }

  .content {
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
  }

  .texts {
    padding-bottom: 50%;
  }

  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.029px;
    padding-right: 50px;
  }

  .candiesCollectedContainer .candiesCollectedTitle {
    font-style: normal;
    font-weight: 700;
  }

  .candiesCollectedLabel {
    margin-left: auto;
  }

  .button {
    min-width: auto;
    width: 100%;
  }
}
