
          @import "src/styles/scss/index.scss";
        
.withdrawalSelect {
  position: relative;
  padding: 11px 15px;
  border-radius: 6px;
  border: 1px solid var(--borderGray);
  background-color: var(--white);

  &__selected {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    cursor: pointer;

    &__date {
      font-size: 14px;
      line-height: 28px;
      color: var(--black);
    }

    &__amount {
      font-weight: 700;
      font-size: 16px;
      color: var(--black);
      line-height: 28px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 16px;
    color: var(--black);
    line-height: 28px;
    cursor: pointer;
  }

  &__list__wrapper {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 100%;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
    z-index: 10;
  }

  &__list {
    max-height: 300px;
    overflow-y: auto;

    &__header {
      padding: 12px 15px;
      background-color: var(--lightGray);
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;

      &_mobile {
        display: none;
      }

      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: var(--darkGray);

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    border-top: 1px solid var(--borderGray);
    padding: 12px 15px;
    cursor: pointer;
    transition: background 0.1s linear;

    &__date {
      font-size: 14px;
      line-height: 24px;
      color: var(--black);
    }

    &__amount {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--black);
    }

    &:hover {
      background: var(--lightGray);
    }
  }
}

@media (max-width: 700px) {
  .withdrawalSelect {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;

      &__date {
        width: 100%;
      }
    }
    &__selected {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      &__date {
        width: 100%;
      }
    }

    &__list {
      max-height: 100vh;
      padding-top: 52px;
      overflow: auto;

      &__header {
        display: none;

        &_mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          padding: 15px;
          background: var(--white);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
          z-index: 500;
          right: 0;
          left: 0;
          width: 100%;

          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
          }

          span {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }

    &__list__wrapper {
      border-radius: 0;
      position: fixed;
      top: 0;
      z-index: 400;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
}
