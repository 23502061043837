
          @import "src/styles/scss/index.scss";
        
.card {
  overflow: hidden;
  position: relative;
  padding: 40px;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
}

.icon {
  color: var(--white);
  svg {
    display: block;
    margin: 0 auto 20px;
  }
}

.title {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  color: var(--black);
  text-align: center;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 34px;
  }
}

.icon {
  margin-bottom: 20px;

  svg {
    width: 90px;
    height: 90px;
    aspect-ratio: 1/1;
  }

  @media screen and (max-width: 768px) {
    svg {
      width: 80px;
      height: 80px;
      margin-top: 10px;
    }
  }
}

.description {
  font-size: 18px;
  line-height: 28px;
  color: var(--darkGray);
  text-align: center;
  margin-bottom: 40px;

  > strong {
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

.btn {
  display: flex;
  max-width: 320px;
  padding-inline: 10px;
  margin: 0 auto;
  height: 60px;

  span {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    max-width: none;
    padding-block: 16px;
    height: 50px;

    span {
      font-size: 14px;
    }
  }

  &_extra {
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 15px;
    }
  }
}

.parallax {
  max-height: 100vh;
  img[class*='__particle_5'] {
    top: 400px;
    left: 237px;
    bottom: auto;
    right: auto;
  }
  img[class*='__particle_6'] {
    top: 380px;
    right: 80px;
    left: auto;
    bottom: auto;
  }
  img[class*='__particle_7'] {
    top: 280px;
    right: -50px;
    left: auto;
    bottom: auto;
  }
  img[class*='__particle_8'] {
    top: 380px;
    left: -76px;
    bottom: auto;
    right: auto;
  }
}
