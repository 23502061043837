
          @import "src/styles/scss/index.scss";
        
.banner {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background: var(--white, #fff);
  @media (min-width: 768px) {
    background: var(--second-orange, #fff5e9);
  }
  img {
    max-width: 100%;

    margin: 0 auto;
    display: block;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .media {
    margin: 0 auto;
  }
}
.title {
  color: var(--black);
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
}
.close {
  position: absolute;
  width: 12px;
  height: 12px;
  padding: 0;
  top: 8px;
  right: 8px;
  border: none;
  background-color: transparent;
  svg {
    width: 12px;
    height: 12px;

    path {
      transition: fill 0.3s;
    }
  }

  @media (min-width: 768px) {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    opacity: 0.6;
    svg {
      width: auto;
      height: auto;
    }
    &:hover {
      svg path {
        fill: var(--orange);
      }
    }
  }

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white, #fff);
    svg {
      width: 15px;
      height: 15px;
    }
    svg path {
      fill: var(--textGray);
    }
  }
}
.description {
  color: var(--darkGray);
  font-size: 16px;
  line-height: 162%;
}
.texts {
  @media (max-width: 768px) {
    padding: 15px;
  }
}
.buttons {
  @media (max-width: 768px) {
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
    background: var(--alpha-grey-065);

    a {
      width: 100%;
    }
  }
}
.default {
  img {
    @media (min-width: 768px) {
      max-width: 145px;
    }
  }
  @media (min-width: 768px) {
    gap: 20px;
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .content {
    @media (max-width: 768px) {
      background: var(--pergament);
    }
    @media (min-width: 768px) {
      gap: 20px;
      display: flex;
      align-items: center;
    }
  }
  .title {
    margin-bottom: 10px;
  }
}
.buttons {
  flex-shrink: 0;
  @media (min-width: 768px) {
    width: 100%;
    max-width: 270px;
  }
  a {
    width: 100%;
  }
}
.outlined {
  img {
    @media (min-width: 768px) {
      max-width: 145px;
    }
  }
  @media (min-width: 768px) {
    gap: 20px;
    padding: 15px;
    display: flex;
    border: 2px solid #f7931a;
    align-items: center;
  }
  .content {
    @media (min-width: 768px) {
      gap: 20px;
      display: flex;
      align-items: center;
    }
  }
  .title {
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 118%;
    }
  }
}
.vertical {
  @media (min-width: 768px) {
    border: 2px solid #f7931a;
    padding: 20px;
  }
  .title {
    @media (min-width: 768px) {
      font-size: 26px;
      line-height: 130%;
      text-align: center;
    }
  }
  .description {
    @media (min-width: 768px) {
      text-align: center;
    }
  }
  img {
    @media (min-width: 768px) {
      margin-bottom: 20px;
      max-width: 200px;
    }
  }
  .buttons {
    @media (min-width: 768px) {
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}
