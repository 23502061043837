
          @import "src/styles/scss/index.scss";
        
.container {
  position: relative;

  &:hover {
    .title {
      color: var(--main-static-white);
    }
  }
}

.title {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  color: var(--black);
  line-height: 16px;

  svg {
    margin-left: 7px;
  }
}

.list {
  position: absolute;
  width: 300px;
  overflow: auto;
  min-width: max-content;
  max-height: 350px;
  left: 0;
  top: calc(100% + 10px);
  padding: 10px 0;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

@media (max-width: 768px) {
  .list {
    left: auto;
    right: -8px;
    width: 250px !important;
  }
}
