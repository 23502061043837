
          @import "src/styles/scss/index.scss";
        
.androidModal {
  &__close {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 5px;
    border: none;
    background-color: transparent;

    @media (max-width: 768px) {
      display: none;
    }

    &_mobile {
      display: none;

      @media (max-width: 768px) {
        display: block;
        left: 10px;
        width: fit-content;
        top: 20px;
      }
    }

    &__icon {
      color: var(--textGray3);
    }

    &:hover {
      svg {
        color: var(--orange);
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 900px;
    width: 100%;
    min-height: 350px;
    border-radius: 10px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%);
    background-color: var(--white);
    text-align: left;

    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }

  &__card__header {
    position: relative;
    padding: 25px 30px 20px;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    color: var(--black);
    border-bottom: 1px solid var(--borderGray);

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.046px;
    }
  }

  &__card__header + &__card__body {
    border-top: 1px solid var(--borderGray);
  }

  &__description {
    color: var(--darkGray);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    @media (max-width: 768px) {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &__item {
    border-radius: 10px;
    background: var(--white);
    border: 1px solid var(--borderGray);
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      border: 1px solid transparent;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
    }

    &_header {
      padding: 15px;
    }
  }

  &__icon_big {
    width: 100%;
  }

  &__features {
    padding: 20px 15px;
    border-top: 1px solid var(--borderGray);
    border-bottom: 1px solid var(--borderGray);
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 1;
  }

  &__feature {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    color: var(--darkGray);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  &__card_wrapper {
    padding: 20px 30px 30px;

    @media (max-width: 768px) {
      padding: 15px 15px 40px;
    }
  }

  &__card_body {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 20px;
      padding: 0;
    }
  }

  &__btn_wrapper {
    padding: 15px;
  }

  &__btn {
    background-color: var(--primary-black);
    border-radius: 10px;
    width: 100%;
    max-height: 50px;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;

    &:hover {
      svg path {
        fill: var(--primary-white);
      }

      rect {
        stroke: var(--primary-white);
      }
    }
  }
}
.blue:hover {
  background-color: var(--hydro) !important;
}
.violet:hover {
  background-color: var(--purple) !important;
}
