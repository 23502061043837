
          @import "src/styles/scss/index.scss";
        
.card {
  overflow: hidden;
}

.farmAboutAccess {
  padding: 30px 0;
  overflow: hidden;
  position: relative;
  min-height: 450px;

  border-radius: 10px;
  background: linear-gradient(180deg, #000 0%, #494949 100%);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 40px 0 0 0;
    flex-direction: column;
  }

  &__content {
    text-align: left;
    position: relative;
    z-index: 2;
    padding-right: 20px;
    min-width: 560px;

    @media screen and (max-width: 768px) {
      padding-left: 20px;
      min-width: auto;
      margin-bottom: 20px;
    }
  }

  &__title {
    color: var(--primary-white);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;

    @media screen and (max-width: 768px) {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      text-align: center;
    }
  }

  &__description {
    color: var(--textGray2);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.216px;
    margin-top: 15px;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
      text-align: center;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &__btn_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 28px;
    gap: 10px;
    justify-content: flex-start;

    a {
      border-radius: 10px;
      max-width: 220px;
      width: 100%;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        flex-grow: 1;
      }

      &:nth-of-type(3) {
        flex-shrink: 0;
        width: 60px;

        &:hover svg {
          path {
            fill: #fff;
          }
        }
      }

      :disabled {
        border-radius: 10px;
        opacity: 0.5;
        background: var(--darkGray);
      }
    }

    @media screen and (max-width: 900px) {
      margin-left: auto;
      margin-right: auto;

      a {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          flex-grow: 0;
        }
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 20px;
      flex-direction: column-reverse;

      a {
        width: 100% !important;
        max-width: none !important;
        height: 50px;
      }
    }
  }

  &__btn {
    width: 320px;
    font-size: 16px;

    @media screen and (max-width: 768px) {
      max-width: none;
      width: 100%;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      padding-block: 16px;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding-right: 60px;

    @media screen and (max-width: 768px) {
      padding-right: 0;

      img {
        width: 100%;
      }
    }
  }
}
