
          @import "src/styles/scss/index.scss";
        
.root {
  padding: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subtitle {
  color: var(--darkGray);
}

.content {
  padding: 20px;
  border-radius: 10px;
  background-color: var(--lightGray);
  border: 1px solid var(--borderGray);

  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.texts {
  display: flex;
  flex-direction: column;
  flex: 1;

  gap: 15px;
}

.textarea {
  resize: none;
  height: 100%;

  @media (max-width: 768px) {
    min-height: 200px;
  }
}

.contentWrapper {
  position: relative;
  display: flex;
}

.fullWrapper {
  flex: 1;
}

.refresh {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--main-static-white);
}

.refreshTop {
  bottom: auto;
  right: 10px;
  top: 10px;
  transform: translateY(0);
}

.refreshBottom {
  right: 10px;
  bottom: 10px;
  top: auto;
  transform: translateY(0);
}

.tooltip {
  top: -20px;
  left: -20px;
}

.imageTooltip {
  // left: -50px;
  left: -60px !important;
  top: 60px !important;
}

.image,
.input,
.textarea {
  width: 100%;
  border-radius: 10px;
}

.input,
.textarea {
  font-family: inherit;
  padding: 15px 70px 15px 15px;
  border: 1px solid var(--borderGray);
  background-color: var(--white);
  color: var(--black);
}

.input {
  font-size: 20px;
  font-weight: 700;
  height: 50px;
  line-height: 1;
}

.textarea {
  font-size: 16px;
  line-height: 26px;
}

.subtitle {
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}
