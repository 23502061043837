
          @import "src/styles/scss/index.scss";
        
.root {
  display: flex;
  margin-top: 55px;

  background-color: var(--lightGray);
  border-radius: 10px;
  border: 1px solid var(--borderGray);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 15px 0;
  }
}
