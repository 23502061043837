
          @import "src/styles/scss/index.scss";
        
.container {
  margin: 0 15px 15px;
  border: 1px solid var(--borderGray);
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--white);
}

.item {
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 15px;
  transition: background 0.3s;

  &:not(:last-child) {
    border-bottom: 1px solid var(--borderGray);
  }

  span {
    font-size: 15px;
    font-weight: bold;
    color: var(--black);
    transition: color 0.3s;
  }

  svg:last-child {
    color: var(--white);
    width: 18px;
    height: 18px;
    margin-left: auto;
  }

  &:hover:not(.item__active) {
    background: var(--lightGray);
    cursor: pointer;
    span {
      color: var(--orange);
    }
    svg:last-child {
      color: var(--orange);
    }
  }

  &__active {
    background: var(--blue);
    span {
      color: var(--main-static-white);
    }
    svg:last-child {
      color: var(--main-static-white);
    }
  }
}
