
          @import "src/styles/scss/index.scss";
        
.container {
  margin-bottom: 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.15);
  padding: 40px;
  position: relative;
  overflow: hidden;
  z-index: 12;
}

.content {
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 40px 90px;
  border: 3px solid var(--primary-white);
  box-shadow: 0 4px 44px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  color: var(--primary-white);
}

.decoration {
  width: 100%;
  max-height: 172px;
  position: relative;
  z-index: 10;
}

.parallax {
  overflow: visible !important;
  img {
    filter: brightness(0%) invert(100%);
  }
  img[class*='__particle_1'] {
    top: -39px;
    left: 70px;
  }
  img[class*='__particle_2'] {
    top: -47px;
    right: -68px;
  }
  img[class*='__particle_3'] {
    top: -37px;
    right: 111px;
  }
  img[class*='__particle_4'] {
    top: 78px;
    left: -116px;
  }
  img[class*='__particle_5'] {
    top: -15px;
    left: -57px;
  }
  img[class*='__particle_6'] {
    bottom: -86px;
    right: -35px;
  }
  img[class*='__particle_7'] {
    top: 111px;
    right: -80px;
  }
  img[class*='__particle_8'] {
    left: -76px;
    bottom: -84px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
    background-image: url('../../../../../assets/aboutPage/about-banner-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .content {
    padding: 30px;
    font-size: 24px;
    line-height: 34px;
  }
}
