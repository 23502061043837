
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 15px;
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
  @media (min-width: 1024px) {
    display: none;
  }
}
