
          @import "src/styles/scss/index.scss";
        
.table_container {
  padding-bottom: 40px;
}

.balanceTable {
  &__header {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    gap: 20px;
    padding: 14px 20px;
    background: var(--lightGray);
    align-items: center;

    &_empty {
      border-bottom: 1px solid var(--borderGray);
    }

    &__cell {
      width: 135px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: var(--black);
    }

    &__cell:first-child {
      flex: 7 1;
      max-width: 240px;
    }

    &__cell:nth-child(4) {
      width: 180px;
    }

    &__cell:last-child {
      width: 85px;
    }
  }

  &__empty_data {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 270px;

    > h5 {
      font-size: 24px;
      line-height: 27px;
      color: #757575;
    }
  }

  &__empty {
    .balanceTable__body {
      border-top: none;
    }
  }

  &__body {
    border-top: 1px solid var(--borderGray);
  }

  @media (max-width: 768px) {
    &__empty {
      width: auto;
      padding: 0 10px;
    }

    &__header {
      display: none;
    }

    &__body {
      border-top: none;
    }
  }
}

@media (max-width: 768px) {
  .table_container {
    width: 100%;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 15px;

    &__hide {
      max-width: none;
    }
  }

  .balanceTable {
    &__header {
      padding: 10px;

      &__cell {
        width: auto;
        flex: none;
        max-width: none;
        flex-basis: 50%;

        &:first-child {
          flex: none;
          max-width: none;
          flex-basis: 50%;
        }

        // &:nth-child(2),
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .balanceTable__header__cell {
    &:first-child {
      flex-basis: 40%;
    }

    &:last-child {
      flex-basis: 60%;
    }
  }
}
