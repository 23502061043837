
          @import "src/styles/scss/index.scss";
        
.container {
  padding: 40px 20px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.decoration {
  height: 300px;
  background: var(--orange);
  position: absolute;
  width: 100vw;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.body {
  padding: 20px 30px 0;
}

.card {
  background: var(--white);
  max-width: 600px;
  width: 100%;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.title {
  padding: 20px 30px;
  border-bottom: 1px solid var(--borderGray);
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

.text {
  font-size: 15px;
  line-height: 24px;
  color: var(--darkGray);
  margin-bottom: 20px;
}

.subtitle {
  font-size: 16px;
  color: var(--error);
  font-weight: 700;
  margin-bottom: 40px;
}

.captcha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: flex-end;
  padding: 20px 30px;
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid var(--borderGray);
  background: var(--lightGray);

  button {
    display: flex;
    width: 100%;
  }
}

.error {
  margin-bottom: 15px;
  text-align: center;
  font-size: 12px;
  color: var(--error);
  min-height: 15px;
}

@media (max-width: 768px) {
  .title {
    padding: 15px 20px;
  }

  .body {
    padding: 20px 20px 0;
  }

  .decoration {
    top: 0;
    transform: none;
  }

  .buttons {
    padding: 20px;
    flex-direction: column;
    gap: 20px;
  }

  .container {
    padding: 20px;
  }
}
