
          @import "src/styles/scss/index.scss";
        
.brands {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  width: 100%;
  padding: 12px 20px 20px;
  max-width: 340px;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid var(--borderGray);

  &__title {
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    font-weight: 700;
    text-align: left;
  }

  &__img {
    &_container {
      height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.line {
  background: var(--borderGray);
  width: 1px;
  height: 20px;
  display: block;
  margin: 0 5px;
}
.center {
  justify-content: center;
}
.link {
  display: flex;
  align-items: center;

  font-size: 13px;
  line-height: 24px;
  // width: 100%;

  // &:first-child {
  //   justify-content: flex-start;
  // }
  // &:nth-child(2) {
  //   border-right: 1px solid var(--borderGray);
  //   border-left: 1px solid var(--borderGray);
  // }

  a {
    text-decoration: none;
    color: var(--blue);
    transition: color 0.3s;
    font-weight: bold;
    margin-right: 4px;

    &:hover {
      color: var(--orange);
    }
  }

  span {
    color: var(--middleGray);
  }
}

@media (max-width: 768px) {
  .brands {
    flex-direction: column;
    align-items: center;
  }
}
