
          @import "src/styles/scss/index.scss";
        
.wrapper {
  @media (max-width: 640px) {
    padding: 15px;
  }
}

.container {
  border-radius: 10px;
  padding: 18px 14px 14px;
  border: 1px solid var(--orange);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
  background: var(--second-orange);
  animation: show 0.3s;

  & > button {
    width: 100%;
    border-radius: 40px;
    font-size: 13px;
  }
}

.body {
  padding: 0 4px 0 4px;
  display: flex;
  align-items: flex-start;
  gap: 19px;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: var(--black);

    a {
      text-decoration: none;
      color: var(--blue);
      transition: color 0.3s;

      &:hover {
        color: var(--orange);
      }
    }
  }

  svg {
    flex-shrink: 0;
  }
}

.button {
  width: 100%;
}

@media (max-width: 640px) {
  .body {
    align-items: center;
    flex-direction: column;

    p {
      text-align: center;
    }
  }
}
