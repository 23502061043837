
          @import "src/styles/scss/index.scss";
        
.container {
  padding: 20px;
  border-radius: 6px;
  border: 1px solid var(--orange);
  background-color: var(--second-orange);
  text-decoration: none;
  position: relative;
  display: block;
  text-align: center;
  cursor: pointer;

  button {
    margin: 20px auto 0;
    max-width: max-content;
    display: flex;
    width: 100%;
    max-height: 40px;
  }
}

.cross {
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    color: var(--textGray);
    transition: color 0.3s;
  }

  &:hover svg {
    color: var(--orange);
  }
}

.title {
  font-weight: bold;
  color: var(--black);
  margin-top: 20px;
  font-size: 18px;
  line-height: 28px;
}

.text {
  font-size: 14px;
  line-height: 24px;
  color: var(--darkGray);
  margin-top: 5px;

  b {
    font-weight: bold;
  }

  a {
    font-weight: bold;
    color: var(--blue);
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: var(--orange);
    }
  }
}

.icon {
  height: 70px;
}

.btn {
  text-transform: uppercase;
  color: var(--primary-white);
  border-radius: 6px;
  transition-property: background-color, border-color, color, opacity;
  transition: 0.1s linear;
  text-align: center;
  background-color: var(--blue);
  font-weight: bold;
  font-size: 13px;
  line-height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 20px;
  margin: 20px auto 0;
  max-width: max-content;
  min-width: 200px;
  &:hover {
    background-color: var(--orange);
  }
}

@media (max-width: 640px) {
  .container {
    display: none;
  }
}
