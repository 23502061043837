
          @import "src/styles/scss/index.scss";
        
.root {
  position: relative;
  margin-top: 40px;
}

.image {
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 4;
  cursor: pointer;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }
}

.hide {
  z-index: 1;
}

.show {
  z-index: 3;
}

.video {
  position: relative;
  max-width: 860px;
  width: 100%;
  display: grid;
  margin: 0 auto;

  iframe {
    border-radius: 15.926px;
  }

  & > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 100%;
    aspect-ratio: 16/9;
    max-width: 100%;
  }

  img {
    border-radius: 15.926px;
    overflow: hidden;
    max-width: 100%;
    cursor: pointer;
    height: 100%;
  }
}
