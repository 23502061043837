
          @import "src/styles/scss/index.scss";
        
.chip {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #37cc33;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--white);
  font-weight: 700;

  &__gray {
    background: #d6d6d6;
    color: #75758f;
  }

  &__yellow {
    background-color: var(--orange);
  }

  &__red {
    background-color: var(--error);
  }

  &__blue {
    background-color: #4285f4;
    color: #fff;
  }

  &__green {
    background-color: #37cc33;
    color: #fff;
  }

  &__gold {
    background-color: #f7c21b;
    color: #fff;
  }

  &__orange {
    background-color: var(--orange);
  }
}
.blue {
  background-color: var(--blue);
  color: #fff;
}
.red {
  background-color: var(--error);
  color: #fff;
}
.gold {
  background-color: var(--orange);
  color: #fff;
}
.gray,
.default {
  background-color: var(--gray-4);
  color: #fff;
}
.success {
  background-color: var(--success);
  color: #fff;
}
