
          @import "src/styles/scss/index.scss";
        
.card {
  position: relative;
  overflow: hidden;
  padding: 40px 0;
  min-height: 450px;

  @media screen and (max-width: 768px) {
    min-height: 810px;
  }
}

.mineOnDevice {
  &__btn_group {
    display: flex;
    gap: 10px;
    margin: 30px auto 0;
    justify-content: center;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 640px) {
      margin: 30px 20px 0;
      flex-direction: column;
    }
  }
  &__title {
    padding: 0 20px;
    position: relative;
    z-index: 1;
    color: var(--secondary-black);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px; /* 135% */

    @media screen and (max-width: 640px) {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      padding: 10px 20px 0;
    }
  }

  &__description {
    color: var(--secondary-black);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    opacity: 0.7;
    margin: 0 auto;
    max-width: 1000px;
    padding: 0 20px;
    position: relative;
    z-index: 1;

    strong {
      font-weight: 700;
    }

    @media screen and (max-width: 640px) {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
  }

  &__image {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-width: none;
    height: 100%;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  a,
  button {
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
    border: none;
    background-color: var(--darkBlue);

    @media screen and (max-width: 640px) {
      height: 50px;
      max-width: none;
    }
  }
}
