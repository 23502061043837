
          @import "src/styles/scss/index.scss";
        
.pageContainer {
  padding: 60px 20px;
  @include max-tablet {
    padding: 15px;
  }
}

.container {
  max-width: 700px;
  width: 100%;
  margin: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  color: var(--black);
}

.contentContainer {
  padding: 30px 15px;
  background-color: var(--white);
}

.innerContainer {
  max-width: 500px;
  margin: 0 auto;
  @include max-tablet {
    svg {
      display: none;
    }
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.09142857044935226px;
  text-align: center;
  margin-top: 20px;
  @include max-tablet {
    font-size: 24px;
    line-height: 34px;
  }
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  max-width: 620px;
  color: var(--darkGray);
  margin: 10px auto 0;
}

.additionalInfo {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.13341109454631805px;

  padding: 10px 15px;
  border: 1px solid var(--gray-6);
  background: var(--gray-8);
  border-radius: 10px;
}

.inputContainer {
  margin-top: 30px;
  display: grid;
  gap: 10px;
  position: relative;

  label {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
  }

  input {
    border: 1px solid var(--gray-6);
    background: var(--white);
    width: 100%;
    height: 50px;
    padding: 13px 15px 13px 40px;
    border-radius: 10px;
    color: var(--black);

    @include max-tablet {
      padding-left: 15px;
    }
    &:focus {
      border: 1px solid var(--blue);
    }
  }

  svg {
    position: absolute;
    bottom: 17px;
    left: 12px;
    color: #f7931a;
  }
}

.footer {
  padding: 20px;
  background-color: var(--alpha-grey-065);
  display: flex;
  align-items: center;
  justify-content: center;
  @include max-tablet {
    padding: 15px;
    background-color: var(--alpha-grey-065);
  }
}

.buttonsGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  flex-grow: 1;
  button {
    width: 100%;
  }
}

.button {
  border-radius: 10px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.buttonSmall {
  @media (max-width: 640px) {
    width: 50px;
    height: 50px;
    .buttonText {
      display: none;
    }
  }
}
