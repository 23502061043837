
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.item {
  &:hover {
    svg :not(path) {
      fill: var(--orange);
    }
  }
}

.fb {
  &:hover {
    svg path:first-of-type {
      fill: var(--orange) !important;
    }
  }
}

.tg {
  &:hover {
    svg path:first-of-type {
      fill: var(--orange) !important;
    }
  }
}

.inst {
  &:hover {
    g path {
      fill: var(--orange) !important;
    }
  }
}

.tw {
  svg rect {
    fill: var(--black);
  }

  svg path {
    fill: var(--white);
  }

  &:hover {
    svg path {
      fill: var(--main-static-white);
    }
  }
}
