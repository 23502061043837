
          @import "src/styles/scss/index.scss";
        
.container {
  display: none;
  padding: 15px;
  background: var(--static-transparent-bg);
  border-radius: 0 0 10px 10px;

  button {
    border-radius: 10px;
    font-size: 15px;
    width: 100%;
  }

  @media (max-width: 768px) {
    display: block;
  }
}
