
          @import "src/styles/scss/index.scss";
        
.container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--error);
  background: var(--Red_BG);

  &__checked {
    padding: 15px 20px 15px 10px;
    border: 1px solid var(--success);
    background: var(--Green_BG);
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    // border: none;
    // border-top: 1px solid var(--borderGray);

    .btn {
      width: 100%;
      max-width: none;
    }
  }
}

.text {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: var(--middleGray);

  &__checked {
    color: var(--success);
  }
}

.btn {
  max-width: 170px;
  width: 100%;
  margin-left: auto;

  &:hover {
    // svg path {
    //   fill: var(--white);
    // }
  }
}

.check {
  margin-left: auto;
}
