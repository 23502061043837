
          @import "src/styles/scss/index.scss";
        
.root {
  padding: 20px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.gridRoot {
  padding: 20px;
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}

.content {
  text-align: left;
}

.choices {
  width: 380px;
}

.linkWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.linkWrapper:hover {
  color: var(--black);
}

.link {
  color: inherit;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.link:hover {
  color: var(--black);
}

.item {
  padding: 15px 10px;
  display: grid;
  gap: 15px;
  grid-template-columns: 114px auto;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
}

.img {
  display: block;
  width: 100%;
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--borderGray);
}

.button {
  padding-block: 14px;
}

.referal {
  grid-column: span 2;
}

.referalText {
  font-size: 14px;
  font-weight: bold;
}

.comment {
  color: var(--gray-2);
  text-align: left;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
