
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: center;
  background: var(--pergament);
  box-shadow: var(--card-shadow);
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  path {
    fill: var(--textGray);
    transition: fill 0.3s;
  }

  @media (min-width: 768px) {
    &:hover {
      path {
        fill: var(--orange);
      }
    }
  }
}

.content {
  padding: 50px 30px 50px 50px;
  max-width: 660px;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 10px;
  width: 100%;
  display: block;
  text-decoration: none;
  color: var(--black);
  transition: color 0.3s;

  @media (max-width: 768px) {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  &:hover {
    color: var(--blue);
  }
}

.text {
  font-size: 16px;
  line-height: 26px;
  color: var(--darkGray);
  margin-bottom: 30px;
  width: 100%;
}

.button {
  max-width: 300px;
  display: flex;
}

.block {
  display: flex;
}

.image {
  max-width: 100%;
  align-self: flex-end;

  &_link {
    display: block;
    align-self: flex-end;
    text-decoration: none;
    margin-left: auto;
    max-width: 440px;
    width: 100%;
    flex-shrink: 0;

    @media (max-width: 1024px) {
      margin-left: 0;
      max-width: none;
    }
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .content {
    padding: 30px 20px 20px;
    max-width: none;
  }

  .button {
    max-width: none;
  }

  .image {
    margin: 0 auto;
    display: block;

    margin: 0 auto;
    display: block;
    min-width: 1000px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }

  .title,
  .text {
    text-align: center;
    width: 100%;
  }

  .text {
    margin-bottom: 20px;
  }

  .title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media (max-width: 768px) {
  .title {
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  .text {
    margin-bottom: 30px;
  }

  .content {
    padding: 30px 15px 20px;
  }

  .image {
    padding-left: 0;
    width: 100%;
  }
}

.small {
  padding: 15px 20px;

  @media (max-width: 1024px) {
    flex-direction: unset;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .image {
    max-width: 100%;
    align-self: flex-end;

    &_link {
      margin: 0;
      max-width: 70px;
      align-self: center;
      margin-right: 20px;
    }
  }

  .content {
    padding: 0;
    max-width: 100%;
    align-items: center;
    display: flex;
  }

  .title {
    font-size: 16px;
    letter-spacing: 0.14px;
    margin-bottom: 5px;
    line-height: 1.375;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.14px;
    margin-bottom: 0;
  }

  .close {
    width: 12px;
    height: 12px;
    right: 8px;
    top: 8px;
  }

  .button {
    min-width: 180px;
  }

  .wrapper {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    & {
      padding: 0;
      background: transparent;
    }

    .image_link {
      max-width: 100%;
      margin: 0;
    }

    img {
      width: 100%;
      padding: 0;
    }

    .content {
      padding: 15px;
      flex-direction: column;
    }

    .block {
      display: block;
      background: var(--pergament);
    }

    .buttons {
      padding: 15px;
      width: 100%;
      background: var(--alpha-grey-065);
    }

    .title,
    .text {
      text-align: start;
    }

    .title {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;
    }

    .text {
      line-height: 24px;
    }

    .close {
      border-radius: 20px;
      background: var(--white, #fff);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      width: 20px;
      height: 20px;
      padding: 4px;
      right: 14px;
      top: 14px;
    }
  }
}
