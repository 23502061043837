
          @import "src/styles/scss/index.scss";
        
.tabs {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 10px 10px 20px;

  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--borderGray);
  margin: 0 auto 20px;
  width: 100%;
  justify-content: space-between;

  &__title {
    color: var(--black);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  &__switch {
    display: flex;
    max-width: 400px;
    width: 100%;

    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex: 1;
      height: 40px;
      text-transform: uppercase;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      transition: all 0.3s;
      color: var(--black);
      border: 1px solid var(--borderGray);
      gap: 8px;

      &:nth-child(1) {
        border-radius: 6px 0 0 6px;
        border-right: 0;
      }

      &:nth-child(2) {
        border-left: 0;
        border-radius: 0 6px 6px 0;
      }

      &:hover {
        background-color: var(--orange);
        color: var(--white);
        border-color: var(--orange);
      }

      &_active {
        cursor: default;
        color: var(--white);
        background-color: var(--blue);
        border-color: var(--blue);

        &:hover {
          background-color: var(--blue);
          border-color: var(--blue);
        }
      }
    }
  }
}

[dark-theme='true'] .tabs__switch_item {
  color: var(--main-static-white);
}

@media (max-width: 768px) {
  .tabs {
    padding: 9px;
    &__title {
      display: none;
    }

    &__switch {
      max-width: none;
    }
  }
}
