
          @import "src/styles/scss/index.scss";
        
.container {
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  padding: 40px 20px 30px;
  position: relative;
}

.img {
  display: block;
  width: 90px;
  margin: 0 auto 20px;

  @media (max-width: 768px) {
    width: 80px;
  }
}

.title {
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 44px;
  color: var(--black);
  text-align: center;
  font-weight: bold;
}

.text {
  max-width: 700px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto 30px;
  color: var(--middleGray);
}

.btn {
  display: flex;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  height: 50px;

  svg path {
    transition: fill 0.3s;
  }

  &:hover {
    svg path {
      fill: var(--orange);
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 30px 15px 20px;
  }

  .title {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .text {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .btn {
    width: 100%;
    max-width: none;
    border-radius: 10px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
  z-index: 2;

  svg {
    color: var(--textGray3);
  }

  &:hover {
    svg {
      color: var(--orange);
    }
  }
}
