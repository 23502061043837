
          @import "src/styles/scss/index.scss";
        
.sidebar {
  overflow: hidden;

  &__panel {
    width: 350px;
    transition: all 0.7s;
    padding: 15px;
    transform: translateX(100%);
    background-color: rgba(23, 23, 23, 0.9);
    position: fixed;
    top: 70px;
    right: -1px;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-moz-scrollbar {
      width: 0;
      height: 0;
    }

    @media (max-width: 769px) {
      top: 54px;
      height: calc(100vh - 54px);
    }

    @include max-mobile {
      right: 0;
      width: 100%;
      background: var(--orange);
    }
  }

  &__switch {
    top: 60px;
    height: calc(100vh - 60px);

    @media (max-width: 769px) {
      top: 54px;
      height: calc(100vh - 54px);
    }
  }

  &_active {
    transform: translateX(0px);
    @include max-mobile {
      right: 0;
    }
  }
}
