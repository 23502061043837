
          @import "src/styles/scss/index.scss";
        
.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global(.social-btns_size_s .social-btn) {
    width: 50px;
    height: 50px;
    border-radius: 35px;
  }
  :global(.social-btn_fb:before) {
    width: 50px;
    height: 39px;
  }
  :global(.social-btns_size_s .social-btn:before) {
    width: 28px;
    height: 28px;
  }
  :global(.social-btns .social-btn:not(:last-child)) {
    margin-right: 20px;
  }
}

div.socials {
  max-width: none;
  margin: 0;

  & > div {
    margin: 0;
    width: 50px;
    height: 50px;
  }
}

.button {
  width: 50px;
  height: 50px;
}
