
          @import "src/styles/scss/index.scss";
        
.root {
  position: relative;
  display: inline-block;
}

.container {
  display: inline-block;
}

.text {
  visibility: visible;

  text-align: center;
  padding: 2px 8px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0%;
  transform: translateX(-50%);

  background-color: var(--black);
  color: var(--white);
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;

  @media (max-width: 768px) {
    display: none;
  }
}
