
          @import "src/styles/scss/index.scss";
        
.box {
  display: flex;
  position: relative;
  padding: 10px 15px;
  border-radius: 10px;
  background: var(--lightGray);
  border: 1px solid var(--borderGray);
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &_blue {
    .text {
      color: var(--blue);
    }
  }

  &_edit {
    background: var(--white);
    border: 1px solid var(--blue);
    .label {
      border: 1px solid var(--blue);
    }
  }
  input {
    border: none;
    width: 100%;
    background: var(--white);
  }
}
.label {
  position: absolute;
  transition: all 0.3s ease;
  left: 10px;
  top: -12px;
  border-radius: 20px;
  border: 1px solid var(--borderGray);
  background: var(--white);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 2px;
  color: var(--middleGray);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.icon {
  color: var(--blue);
  cursor: pointer;
  display: flex;
  gap: 20px;

  &:hover {
    color: var(--orange);

    svg path {
      fill: var(--orange);
    }
  }
}

.close {
  color: var(--textGray);
}

.text {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  letter-spacing: 0.05px;
  text-align: left;
  word-break: break-all;

  @media (max-width: 500px) {
    font-size: 14px;
  }
}
.text,
.input {
  color: var(--black);
  padding: 0;
}
