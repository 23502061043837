
          @import "src/styles/scss/index.scss";
        
.root {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1000px;
  background-color: var(--white);
}
