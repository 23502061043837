
          @import "src/styles/scss/index.scss";
        
.card {
  overflow: hidden;
}

.affiliatePromoAccess {
  padding-top: 40px;
  overflow: hidden;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }

  &__border {
    border-bottom: 8px solid var(--orange);
  }

  &__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    color: var(--black);
    max-width: 1140px;
    padding: 0 20px;
    position: relative;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }
  }

  &__description {
    width: 100%;
    max-width: 1024px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    color: var(--darkGray);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 0 20px;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &__btn_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding: 0 20px;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }

    > * + * {
      margin-top: 20px;
    }
  }

  &__btn {
    width: 320px;
    font-size: 16px;
    padding-block: 16px;
    height: 50px;

    @media screen and (max-width: 768px) {
      max-width: none;
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }

  &__btn_group_hint {
    font-size: 11px;
    line-height: 12px;
    color: var(--middleGray);
  }

  &__image {
    margin: 40px auto 0;

    img {
      max-width: 100%;
    }

    @media screen and (max-width: 768px) {
      max-width: 100vw;
      margin: 20px auto 0;

      img {
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
