
          @import "src/styles/scss/index.scss";
        
.item {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  color: var(--darkGray);
  &:hover {
    background-color: var(--orange);
    color: var(--white);
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--borderGray);
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 15px 20px;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
    color: var(--blue);
    text-transform: uppercase;
    text-align: center;
  }
}

.switcher {
  background-color: var(--white);
  @media (max-width: 1024px) {
    border: none;
    padding: 0 0 10px 0;
    // & > div:first-child {
    //   display: none;
    // }
    // & > div:last-child {
    //   border-radius: 10px;
    // }
  }
}
