
          @import "src/styles/scss/index.scss";
        
.calcWrapper {
  position: relative;

  > img {
    position: absolute;
    top: -73px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    pointer-events: none;
  }
}

.calc {
  position: relative;
  margin: 0 auto 15px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }

  & > *::selection {
    background-color: transparent !important;
  }

  & > *::-moz-selection {
    background-color: transparent !important;
  }

  &__top {
    text-align: center;
  }

  &__decoration {
    background: var(--orange);
    border-radius: 30px;
    max-width: 630px;
    width: 108%;
    height: 100%;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 0;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__decoration_mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      width: initial;
      margin: 0 -20px;

      img {
        width: 100%;
      }
    }
  }

  &__left_decoration {
    position: absolute;
    left: -168px;
    top: 5px;
  }

  &__right_decoration {
    position: absolute;
    right: -168px;
    top: 5px;
  }

  &__title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    color: var(--black);

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }
  }

  &__body {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: 30px auto 0;
    position: relative;
    border-radius: 20px;

    @media (max-width: 768px) {
      margin: 20px auto 0;
      border-radius: 10px;
      box-shadow: none;
      display: flex;
      border: 1px solid var(--borderGray);
    }

    > * + * {
      margin-top: 40px;
    }
  }

  &__body_inner {
    position: relative;
    z-index: 1;
    background-color: var(--white);
    border-radius: 20px;

    margin: 0 auto;
    display: block;
    padding: 40px 50px 30px;
    border: 1px solid var(--gray-7);

    @media (max-width: 768px) {
      padding: 60px 15px 30px;
    }
  }

  &__top + &__body {
    //border-top: 1px solid var(--borderGray);
  }

  &__btn {
    max-width: none;
    width: 100%;
    margin: 10px auto 0;

    color: var(--primary-white);

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;

    @media (max-width: 768px) {
      max-width: none;

      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      padding-block: 16px;
    }
  }

  &__slider {
    display: flex;
    justify-content: center;
    text-align: left;
    margin-bottom: 50px;

    @media (max-width: 640px) {
      &:last-of-type {
        margin-bottom: 40px;
      }
    }
  }
}
