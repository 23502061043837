
          @import "src/styles/scss/index.scss";
        
.container {
  @media (min-width: 1024px) {
    padding-top: 20px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @media (min-width: 1024px) {
    &__ids {
      max-width: 200px;
    }
  }
  @media (max-width: 1024px) {
    padding: 0 15px 15px;

    &__ids {
      padding: 0;
      border: none;
      background: transparent;
    }

    & > div {
      margin: 0;
    }
  }
}
.title {
  align-items: center;
  position: relative;
  svg {
    margin-left: 8px;
  }
  svg:hover + .tooltip {
    opacity: 1;
    visibility: visible;
  }
}
.tooltip {
  position: absolute;
  z-index: 10;
  text-align: left;
  right: 0;
  top: calc(100% + 10px);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  max-width: 260px;
  color: var(--white);
  font-size: 12px;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: var(--main-static-white);
  line-height: 110%;
  text-transform: none;
}
.date {
  @media (max-width: 1024px) {
    display: none;
  }
}
.cardHeader {
  @media (max-width: 1024px) {
    border-bottom: none;
    padding-bottom: 0;
    & > span {
      padding: 20px 15px;
    }
  }
}
.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 1024px) {
    gap: 10px;
  }
  &__item {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      background-color: var(--colorLvl);
      width: 23px;
      height: 32px;
      border-radius: 39px;
      justify-content: center;
    }
    i {
      @media (min-width: 768px) {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: var(--colorLvl);
      }
    }
    p {
      font-size: 14px;
      color: white;
      @media (min-width: 768px) {
        margin-left: 7px;
        font-size: 13;
        font-weight: 700;
        color: var(--darkGray);
      }
      b {
        font-weight: bold;
      }

      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
}
.header {
  display: flex;
  gap: 20px;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    padding: 0 15px;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
  }
  & > div {
    min-width: 260px;
  }
}
.legendContainer {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  margin-top: 15px;
  width: 100%;
  gap: 5px;
  div {
    color: var(--textGray2);
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.127px;
    text-transform: uppercase;
    margin-right: 30px;
  }
}
.total_mob {
  color: var(--black);
  font-size: 20px;
  margin-left: 10px;
  @media (min-width: 1024px) {
    display: none;
  }
}
.total_pc {
  color: var(--black);
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    font-size: 32px;
  }
}
.graphContainer {
  position: relative;
}
.shadowOverlayLeft,
.shadowOverlayRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.shadowOverlayLeft {
  left: 0;
  background: linear-gradient(90deg, #fff 24.24%, rgba(255, 255, 255, 0) 87.9%);
}
[dark-theme='true'] {
  .shadowOverlayLeft {
    background: linear-gradient(90deg, #25272a 34.54%, rgba(37, 39, 42, 0) 87.9%);
  }
  .shadowOverlayRight {
    background: linear-gradient(270deg, #25272a 34.54%, rgba(37, 39, 42, 0) 87.9%);
  }
}
.shadowOverlayRight {
  right: 0;
  background: linear-gradient(270deg, #fff 24.24%, rgba(255, 255, 255, 0) 87.9%);
}
.visible {
  opacity: 1;
}
.scrollable {
  overflow-x: visible;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable::-webkit-scrollbar {
  display: none;
}
.scrollable {
  @media (max-width: 1024px) {
    :global(.recharts-wrapper) {
      position: static !important;
    }
    :global(.recharts-tooltip-wrapper) {
      top: 0px !important;
      transform: translate(-50%, 0) !important;
      left: 50% !important;
    }
  }
}
