
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 40px;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
}

.tableImage {
  width: 100%;

  img {
    width: 100%;
  }

  @media (max-width: 640px) {
    margin: 0 auto;
    max-width: 400px;
  }
}

.tableInfo {
  display: flex;
  gap: 40px;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 640px) {
    flex-direction: column-reverse;
    margin-bottom: 7px;
  }
}

.levelInfo {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  background: var(--white);
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    padding: 18px;
    gap: 15px;
  }

  p {
    color: var(--black);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    padding: 0 20px;

    @media (max-width: 768px) {
      text-align: left;
    }
  }
}

.arrow {
  position: absolute;
  bottom: -55px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 44px;

  @media (max-width: 768px) {
    display: none;
  }
}

.description_note {
  font-weight: 700;
  font-size: 28px;
  line-height: 31px;
  color: var(--orange);
  margin: 40px auto 0;

  @media screen and (max-width: 640px) {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
}

.titleWrapper {
  grid-column: 2/-1;
  width: 81%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.title {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  color: var(--black);

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }
}

.description {
  margin-top: 20px;
  font-size: 20px;
  line-height: 32px;
  color: var(--darkGray);

  @media (max-width: 768px) {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  strong {
    font-weight: 700;
  }
}
