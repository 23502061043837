
          @import "src/styles/scss/index.scss";
        
.section {
  &:last-child {
    margin-bottom: 60px;
  }

  &__hide {
    .container {
      overflow: hidden;
    }
  }

  &.with_footer {
    .container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.body {
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px 15px 15px;
  }
}

.container {
  max-width: 1100px;
  margin: 20px auto 0;
  background: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 10px;
}
.isFooterOpacity {
  .container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.footer {
  background-color: var(--alpha-grey-065);
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: var(--card-shadow);
  @include max-tablet {
    padding: 15px;
  }
}
.title {
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  color: var(--black);
}

.top {
  padding: 20px;
  border-bottom: 1px solid var(--borderGray);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: nowrap;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.text {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 20px;
  color: var(--darkGray);
}

@media (max-width: 768px) {
  .title {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }

  .top {
    border-bottom: none;
    padding: 15px;
    gap: 15px;
  }
}
