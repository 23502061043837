
          @import "src/styles/scss/index.scss";
        
.icon {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 44px;
  border: 1px solid var(--static-dark-gray);
  margin-right: 30px;
  flex-shrink: 0;
}
:global(.ios),
:global(.android) {
  .subheader {
    padding: 8px 20px;
  }

  .subheader__menu_children {
    padding: 0;
  }
}

.subheader {
  position: sticky;
  top: -1px;
  padding: 0 20px;
  background: var(--gray1);
  min-height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 20;
  scrollbar-width: none;
  position: -webkit-sticky;

  [data-item='menu'] {
    margin-left: auto;
  }

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
  }

  & > *::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & > *::-moz-scrollbar {
    width: 0px;
    height: 0px;
  }

  &__toggle {
    display: none;
    align-items: stretch;
    text-transform: uppercase;
    max-width: 100%;

    &_scroll {
      display: flex;
      align-items: stretch;
      text-transform: uppercase;
    }

    &item {
      padding: 10px 15px;
      background: var(--tagGray);
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      gap: 8px;
      transition: background 0.3s;
      text-decoration: none;
      border: none;
      outline: none;
      width: max-content;
      cursor: pointer;

      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: var(--textGray);
        transition: color 0.3s;
        text-transform: uppercase;
      }

      &:hover {
        background: var(--middleGray);

        span {
          color: var(--primary-white);
        }
      }

      &.active {
        background: var(--white);

        span {
          color: var(--black);
        }
      }

      &_white {
        margin-right: 0;
        margin-left: 10px;

        background: var(--white);
        border: 1px solid var(--borderGray);
        span {
          color: var(--black);
        }

        &.active {
          background: var(--blue);
          border-color: var(--blue);
          span {
            color: var(--primary-white);
          }
        }

        &:hover {
          background: var(--Orange_BG);
          border-color: var(--orange);
          span {
            color: var(--black);
          }
        }
      }
    }

    @media (max-width: 768px) {
      &_scroll {
        grid-template-areas: 'browser farm ctp nft';
        display: grid;
        column-gap: 10px;
        & > a {
          margin-right: 0;
          padding: 10px 15px;
          &:nth-child(1) {
            grid-area: browser;
          }

          &:nth-child(2) {
            grid-area: farm;
          }

          &:nth-child(3) {
            grid-area: nft;
          }

          &:nth-child(4) {
            grid-area: ctp;
          }
        }
      }
    }
  }

  &__menu {
    position: relative;
  }

  &__menu,
  .hide_menu {
    &:not(:last-child) {
      margin-right: 40px;

      @media (max-width: 768px) {
        margin-right: 0;
      }
    }
    display: flex;
    align-items: center;
    &_scroll {
      display: flex;
      align-items: center;
      row-gap: 10px;
    }
    a {
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      text-decoration: none;
      width: max-content;
      color: var(--blue);
      text-transform: uppercase;

      @media (max-width: 768px) {
        display: none;
      }

      &:hover {
        svg {
          color: var(--orange);
        }
        color: var(--orange);
      }

      &.active {
        color: var(--main-static-white);
        pointer-events: none;
        position: relative;
        border-radius: 30px;
        @media (max-width: 768px) {
          display: block;
          margin: 17px auto 0;
          background: var(--alpha-grey-065);
          padding: 10px;
        }

        svg {
          color: var(--orange);

          @media (max-width: 768px) {
            display: none;
          }
        }

        @media (min-width: 768px) {
          &:after {
            content: '';
            display: block;
            width: calc(100% - 32px);
            height: 2px;
            border-radius: 6px;
            background-color: var(--orange);
            position: absolute;
            bottom: -8px;
            right: 0;
          }
        }
      }
    }
  }
}
:global(.ios),
:global(.android) {
  .subheader {
    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 8px 0px;

      &__toggle {
        display: flex;
      }

      [data-item='menu'],
      .hide_menu {
        display: none;
      }

      &__menu {
        flex-wrap: nowrap;
        overflow-x: scroll;
        max-width: 100%;
        scrollbar-width: none;

        @media (max-width: 768px) {
          margin-bottom: -5px;
        }

        &::-moz-scrollbar {
          width: 0px;
          height: 0px;
        }

        &_scroll {
          padding: 0 10px;
          min-width: -webkit-max-content;
        }
      }

      &__toggle {
        overflow-x: scroll;
        scrollbar-width: none;
        padding: 0 10px;
        &_scroll {
          min-width: -webkit-max-content;
        }
        &::-moz-scrollbar {
          width: 0px;
          height: 0px;
        }
      }
    }
  }
}
.subheader__menu_children {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 25px;
  padding: 20px 0;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.withHelp {
  font-weight: bold;
  display: block;
  font-size: 14px;
  text-decoration: none;
  width: max-content;
  color: var(--blue);
  text-transform: lowercase;
  position: relative;
  cursor: pointer;

  &__help {
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    font-size: 12px;
    color: var(--primary-white);
    text-transform: lowercase;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    border-radius: 3px;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition: 0.3s linear;
  }

  &:hover {
    .withHelp__help {
      opacity: 1;
      visibility: visible;
    }
  }
}
