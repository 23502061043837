
          @import "src/styles/scss/index.scss";
        
.title {
  margin-top: 20px;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0.09px;
  text-align: center;
  color: var(--black);

  @include max-mobile {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
  }

  &--network {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    color: var(--black);
    letter-spacing: 0.16px;
    text-align: center;
    line-height: 30px;

    p {
      margin-top: 20px;
      border-radius: 6px;
      border: 1px solid var(--borderGray);
      background: var(--lightGray);
      padding: 10px 15px;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;

      a {
        font-weight: bold;
      }
    }
  }
}
