
          @import "src/styles/scss/index.scss";
        
.suspendCard {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-height: 540px;
  margin: 0 auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: var(--white);

  &__title {
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    padding: 20px 30px;
    color: var(--black);
    border-bottom: 1px solid var(--borderGray);
  }

  &__body {
    padding: 20px 30px 40px;
  }

  &__description {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 24px;
    color: var(--darkGray);
    font-weight: 400;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--black);

    &__sub {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 10px;
      color: var(--black);
    }
  }

  &__container__sub {
    & label:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__checkbox {
    display: flex;
    column-gap: 10px;
    max-width: 90%;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
    align-items: baseline;
    cursor: pointer;
    color: var(--black);

    & input {
      cursor: pointer;
    }

    &__sub {
      display: inline-flex;
      column-gap: 10px;
      max-width: 90%;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 20px;
      align-items: baseline;
      cursor: pointer;
      color: var(--black);

      & input {
        cursor: pointer;
      }
    }
  }

  &__url {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    color: var(--blue);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 15px 0 30px;
    font-size: 15px;
    line-height: 24px;
    color: #494949;

    ul {
      list-style-position: inside;
      list-style-type: disc;
    }

    &_choices {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    &_inner {
      > * + * {
        margin-top: 20px;
      }
    }
  }

  &__choice {
    padding-block: 8px;
    padding-inline: 10px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    color: #494949;

    &:hover {
      background: #fff5e9;
      border-color: #ffdfb8;
    }
  }

  &__controls {
    display: flex;
    margin-top: auto;
    padding: 20px 30px;
    gap: 30px;
    border-top: 1px solid var(--borderGray);
    background: var(--lightGray);
    border-radius: 0px 0px 6px 6px;

    > * {
      width: 100%;
      max-width: 300px;
      height: 50px;
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    &__title {
      padding: 15px 20px;
    }

    &__body {
      padding: 20px 20px 40px;
    }

    &__controls {
      padding: 20px;
      flex-direction: column;
      gap: 20px;

      > * {
        max-width: none;
      }
    }
  }
}
