
          @import "src/styles/scss/index.scss";
        
.calcModal {
  &__frame {
    border-radius: 10px;
    border: 1px solid var(--orange);
    background: var(--second-orange);
    padding: 15px 20px;
  }

  &__card__body {
    flex: 1 0 auto;
    font-size: 16px;
    background-color: var(--white);
    line-height: 26px;
    border-radius: 0px 0px 10px 10px;
    color: var(--darkGray);
    margin-top: 20px;

    @media screen and (max-width: 640px) {
      display: flex;
      flex-direction: column;
    }

    > * + * {
      margin-top: 20px;
    }

    p {
      text-align: center;
    }

    h5 {
      font-weight: 700;
      color: var(--orange);
      margin-bottom: 10px;
    }

    ul {
      > * + * {
        margin-top: 10px;
      }
    }

    b {
      font-weight: 700;
    }

    li {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        margin-right: 10px;
        border: 1px solid var(--orange);
        border-radius: 50%;
      }

      > b {
        margin-right: 5px;
      }
    }
  }

  &__card_note {
    text-align: left !important;
    margin-top: 20px;
  }
}
