
          @import "src/styles/scss/index.scss";
        
.steps {
  margin-bottom: -70px;

  @media (max-width: 768px) {
    margin-bottom: -90px;
  }
}

.previewImg {
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}
