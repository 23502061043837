
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 60px 0 0 0;
  background: linear-gradient(180deg, #000000 0%, #494949 100%);

  @media screen and (max-width: 768px) {
    padding: 30px 0 0 0;
  }
}

.earn {
  &__btn_group {
    display: flex;
    gap: 10px;
    margin: 40px auto 0;
    justify-content: center;

    @media screen and (max-width: 640px) {
      margin: 20px 20px 0;
      flex-direction: column;
    }
  }
  &__title {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    color: var(--primary-white);
    text-align: center;
    padding: 0 20px;

    @media screen and (max-width: 640px) {
      font-size: 28px;
      line-height: 40px;
    }
  }

  &__description {
    color: var(--textGray);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
    max-width: 1000px;
    padding: 0 20px;

    strong {
      font-weight: 700;
    }

    @media screen and (max-width: 640px) {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &__image {
    width: 100%;
    margin-top: -90px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }

    @media screen and (max-width: 640px) {
      margin-top: -305px;
    }

    img {
      width: 100%;
    }
  }

  a,
  button {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    border: 1px solid rgba(117, 117, 117, 0.7);

    @media screen and (max-width: 640px) {
      height: 50px;
      max-width: none;
    }
  }
}
