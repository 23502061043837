
          @import "src/styles/scss/index.scss";
        
.modalHSH {
  &__unshow {
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    font-family: Arial, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04px;
    text-align: left;
    color: var(--textGray);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 20px;
    background: rgba(23, 23, 23, 0.65);

    @media (max-width: 768px) {
      bottom: -50px;
      gap: 7px;
    }

    & > * {
      cursor: pointer;
    }

    input[type='checkbox'] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: transparent;
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: currentColor;
      width: 16px;
      height: 16px;
      border: 1px solid currentColor;
      border-radius: 4px;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
    }

    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--textGray);
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
      box-shadow: inset 1em 1em var(--white);
    }

    input[type='checkbox']:checked,
    input[type='checkbox']:checked + label {
      color: var(--white);
    }

    input[type='checkbox']:checked {
      border: none;
      background-color: var(--blue);
    }
  }

  &__decoration {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;

    & > * {
      pointer-events: none;
    }

    .decoration {
      &__left,
      &__right {
        width: 450px;
        height: 450px;
        position: absolute;
        top: -192px;
      }

      &__left {
        right: 264px;
      }

      &__right {
        left: 226px;
      }

      &__mobile {
        width: 510px;
        height: 900px;
        position: absolute;
        top: -460px;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}

[dark-theme='true'] {
  .modalHSH {
    &__unshow {
      input[type='checkbox']:checked::before {
        box-shadow: inset 1em 1em var(--black);
      }

      input[type='checkbox']:checked,
      input[type='checkbox']:checked + label {
        color: var(--black);
      }
    }
  }
}
