
          @import "src/styles/scss/index.scss";
        
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    max-width: 300px;
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;

    button {
      max-width: none;
    }
  }
}
