
          @import "src/styles/scss/index.scss";
        
.title {
  color: var(--primary-white);
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 2px 1px rgba(0, 0, 0, 0.25);
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.137px;
  z-index: 2;

  @media (max-width: 640px) {
    color: var(--primary-white);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 2px 1px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
}

.text {
  color: var(--primary-white);
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 2px 1px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  z-index: 2;
  margin-top: 10px;

  @media (max-width: 640px) {
    display: none;
  }
}

.slide {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overlay {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: none;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    radial-gradient(85.11% 60.87% at 47.45% 39.12%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.08) 91.04%);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  height: 100%;
}

.btn {
  max-width: 320px;
  width: 100%;
  z-index: 2;
  margin: 28px auto 0;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;

  @media screen and (max-width: 640px) {
    display: none;
  }
}

.image {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: none;
  height: 100%;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.circular_progress {
  --circular-value: 0%;
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-image: conic-gradient(#fff var(--circular-value), #aacaff 0deg);
  background-color: var(--blue);
  z-index: 10;

  border: 2px solid var(--blue);
  right: 10px;
  bottom: 12px;

  @media (max-width: 640px) {
    display: flex;
  }

  &__inner {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-51%, -51%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: var(--blue);
  }
}
