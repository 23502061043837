
          @import "src/styles/scss/index.scss";
        
.root {
  display: block;
  height: 100%;
}

.title {
  max-width: 1100px;
  margin: 0 auto 20px;
  font-size: 48px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
  color: var(--black);
  padding: 0 15px;
}

.description {
  color: var(--darkGray);
  max-width: 980px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto 30px;
  padding: 0 15px;
}

.body {
  padding-top: 40px;
  padding-bottom: 40px;
}

.sliderControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: var(--blue);
    border-radius: 10px;
    background: var(--alpha-black-015);
    border: 1px solid var(--alpha-grey);
    transition: 0.2s;

    &:hover {
      color: var(--white);
      background-color: var(--orange);
      border-color: var(--orange);
    }
  }
}

.productFixed {
  max-width: 335px;
}

.divider {
  margin-bottom: 20px;
  width: 100%;
}

.container {
  width: 100%;
  box-shadow: 0 2px 8px 0 #00000033;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--black);
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;

  padding: 40px 20px 20px;

  & > svg {
    display: block;
    margin: 0 auto 20px;
  }

  &__title {
    color: var(--primary-white);
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    align-self: center;

    &:hover {
      color: var(--orange);
    }
  }

  &__text {
    color: #d1d2d6;
    text-align: center;
    font-size: 18px;
    line-height: 28px;
  }
}

.btnWrapper {
  padding: 0 20px;
}

.btn {
  width: 100%;
}

.img {
  margin-top: 20px;
  display: block;
  height: 300px;
  object-fit: cover;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
}

@media (max-width: 900px) {
  .products {
    flex-direction: column;
  }

  .title {
    font-size: 28px;
    line-height: 31px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .divider {
    display: none;
  }

  .sliderControls {
    margin: 0;

    button {
      min-width: 40px;
    }
  }
}
