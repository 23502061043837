
          @import "src/styles/scss/index.scss";
        
.loader_dash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s 0s, opacity 0.25s 0s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 120000000;
  background-color: var(--white);

  &.hide {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  &_spinner {
    margin: 30px auto;
    text-align: center;

    & > i {
      width: 16px;
      height: 16px;
      background-color: var(--orange);
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.7s infinite ease-in-out both;
      margin-right: 3px;
      margin-left: 3px;

      &:first-child {
        animation-delay: -0.6s;
      }
      &:nth-child(2) {
        animation-delay: -0.4s;
      }
      &:nth-child(3) {
        animation-delay: -0.2s;
      }
    }
  }
}

.loader_error {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--white);
  position: fixed;
  padding: 20px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 110;

  p {
    font-weight: bold;
    font-size: 24px;
    color: var(--error);
  }

  &.show {
    display: flex;
  }

  &__btn {
    border: none;
    margin-top: 30px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 50px;
    max-width: 300px;
    text-align: center;
    color: var(--white);
    background: var(--blue);
    border-radius: 30px;
    transition: background 0.3s;
    font-weight: bold;
    font-size: 14px;
    width: 100%;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background: var(--orange);
    }
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}
