
          @import "src/styles/scss/index.scss";
        
.withdrawals {
  margin-top: 15px;
  margin-bottom: auto;

  &__list {
    margin-top: 15px;
    > * + * {
      margin-top: 10px;
    }
  }

  &__error {
    margin-top: 5px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 400;
    color: var(--error);
    letter-spacing: 0.03px;
  }
}

.withdrawal {
  display: flex;
  justify-content: space-between;
  padding: 7px 14px;
  border-radius: 3px;
  border: 1px solid var(--borderGray);
  color: var(--darkGray);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  &__selected {
    border-color: var(--orange);
  }

  &:hover {
    color: var(--orange);
  }
}
