
          @import "src/styles/scss/index.scss";
        
.buttonGroup {
  display: inline-flex;
  border-radius: 6px;
  border: 1px solid var(--borderGray);

  > *:first-child {
    border-radius: 6px 0px 0px 6px !important;
  }

  > *:last-child {
    border-radius: 0px 6px 6px 0px !important;
  }

  > * {
    border-radius: 0 !important;
    border: none !important;
  }

  > *:not(:last-child) {
    border-right: 1px solid var(--borderGray) !important;
  }

  > [class*='active'] {
    border-right-color: var(--blue) !important;
    z-index: 1;

    &:hover {
      border-right-color: var(--orange) !important;
    }
  }
}
