
          @import "src/styles/scss/index.scss";
        
.banner {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%);
  background-color: var(--white);
  overflow: hidden;

  &::before {
    content: '';
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: #f7931a;
  }

  &__media {
    width: 170px;
    height: 140px;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: auto;
    }

    img,
    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 40px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding-right: 0;
    }
  }

  &__close {
    position: absolute;
    width: 12px;
    height: 12px;
    padding: 0;
    top: 8px;
    right: 8px;
    border: none;
    background-color: transparent;
    svg {
      width: 12px;
      height: 12px;

      path {
        transition: fill 0.3s;
      }
    }

    @media (min-width: 768px) {
      &:hover {
        svg path {
          fill: var(--orange);
        }
      }
    }

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #212121;
      opacity: 0.6;

      svg path {
        fill: var(--white);
      }
    }
  }

  &__content {
    flex: 2;
    padding: 10px 20px;

    @media screen and (max-width: 786px) {
      padding: 20px 15px;
    }
  }

  &__content_title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }

  &__content_description {
    margin-top: 6px;
    padding: 0;
    color: var(--darkGray);
    font-size: 18px;
    line-height: 1.3;
    b {
      font-weight: bold;
    }
  }

  &__action {
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-block: 25px;
      border-radius: 0;
      border: none;
      border-top: 1px solid var(--borderGray);
    }
  }
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  a {
    text-decoration: none;
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cecece;
    border-radius: 6px;
    transition: all 0.3s;

    svg path {
      transition: fill 0.3s;
    }

    &:hover {
      border-color: var(--orange);
      background-color: var(--orange);

      svg path {
        fill: var(--white);
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding: 0 20px;

    a {
      width: 100%;
    }

    margin-bottom: 20px;
  }
}
