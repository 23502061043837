
          @import "src/styles/scss/index.scss";
        
.container {
  margin: 0 15px 10px;
}

.colors {
  padding: 15px;
  border: 1px solid var(--borderGray);
  margin-bottom: 5px;
  border-radius: 10px;
  background: var(--white);

  &__text {
    color: var(--middleGray);
    margin-bottom: 15px;
    line-height: 24px;
    font-size: 14px;
  }

  &__kinds {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 8px;
    column-gap: 5px;
    margin-bottom: 5px;
  }
}

.item {
  width: 39px;
  height: 39px;
  border-radius: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    color: transparent;
  }

  &:before {
    content: '';
    position: absolute;
    width: 37px;
    height: 37px;
    border-radius: 37px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &__active {
    .active {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: var(--blue);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: var(--primary-white);
        width: 12px;
        height: 12px;
      }
    }
  }
}
[dark-theme='true'] {
  .item {
    &:before {
      border: none;
    }
  }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;

    svg {
      color: var(--middleGray);
    }

    span {
      text-transform: uppercase;
      color: var(--black);
      font-weight: bold;
      font-size: 13px;
      line-height: 23px;
    }
  }
}
