
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 40px;

  @media (max-width: 768px) {
    padding: 30px 20px 20px;
  }
}

.start {
  &__title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    color: var(--black);
    text-align: center;

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 40px;
    }
  }

  &__description {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 20px auto 55px;
    color: var(--darkGray);
    text-align: center;

    @media (max-width: 768px) {
      font-size: 15px;
      line-height: 155%;
      margin-top: 15px;
    }
  }
}
