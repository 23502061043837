
          @import "src/styles/scss/index.scss";
        
.spinner {
  margin: 0;
  padding: 15px 0;

  i {
    background-color: var(--blue);
  }
}

.history {
  &__empty {
    padding: 111px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 270px;

    .empty {
      &__svg {
        margin-bottom: 20px;
        width: 80px;
        height: 80px;
        color: var(--black);
      }

      &__title {
        margin-bottom: 10px;
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0.09142857044935226px;
        text-align: center;
        color: var(--black);

        @media (max-width: 768px) {
          margin-bottom: 5px;
          font-size: 24px;
          line-height: 32px;
        }
      }

      &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: var(--darkGray);

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }

  &__list {
    > * {
      border-bottom: 1px solid var(--borderGray);
    }
  }

  .addr {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  &__list__item {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;
    align-items: center;
    gap: 20px;
    padding: 13px 20px;
    transition: background-color 0.3s;
    text-decoration: none;
    color: var(--black);

    &:hover {
      background-color: var(--lightGray);

      svg path {
        color: var(--orange);
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 10fr 12fr;
      grid-template-areas:
        'adr amount'
        'status amount';

      gap: 10px;
      padding: 10px;
      justify-content: space-between;
    }

    @media (max-width: 520px) {
      grid-template-columns: 10fr max-content;
    }

    &__wrap {
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        flex-direction: row;
      }
    }

    &__icon__info {
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }

    &__priority {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.0495238px;
      color: var(--textGray2);
      position: relative;

      @media (max-width: 768px) {
        margin-left: 5px;
      }

      svg {
        display: none;
      }

      @media (max-width: 768px) {
        span {
          visibility: hidden;
          opacity: 0;
          position: absolute;
        }

        svg {
          display: block;

          &:hover {
            + span {
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              width: 200px;
              max-width: 230px;
              padding: 5px 10px;
              border-radius: 2px;
              background-color: rgba(0, 0, 0, 0.8);
              color: var(--primary-white);
              font-size: 12px;
              font-weight: normal !important;
              text-transform: none !important;
              white-space: pre-wrap;
              line-height: 14px;
              z-index: 2;
              visibility: visible;
              opacity: 1;
              transition: visibility 0.1s ease-in-out, opacity 0.1s ease-in-out;
            }
          }
        }
      }
    }

    &__icon {
      stroke: var(--darkGray);

      &__gray:hover {
        stroke: var(--borderGray);
      }

      &__yellow:hover {
        stroke: var(--orange);
      }

      &__red:hover {
        stroke: var(--error);
      }

      &__blue:hover {
        stroke: #4285f4;
      }

      &__green:hover {
        stroke: #37cc33;
      }
    }

    &__cell {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 13px;
      line-height: 14px;
      flex-shrink: 0;
      font-weight: 400;

      &_amount {
        display: flex;
        align-items: center;
        gap: 10px;

        b {
          font-size: 18px;
          line-height: 22px;
        }

        svg:last-child {
          margin-left: auto;
          color: var(--blue);
        }
      }

      b {
        font-weight: 700;
      }
    }

    @media (max-width: 768px) {
      &__cell {
        width: fit-content;
        flex: none;
        max-width: none;

        &_amount {
          b {
            font-size: 16px;
          }

          svg:last-child {
            display: none;
          }
        }

        &:first-child {
          grid-area: adr;
        }

        &:nth-child(2) {
          grid-area: status;
        }

        &:last-child {
          grid-area: amount;
        }
      }
    }

    @media (max-width: 450px) {
      &__cell {
        &:first-child {
          flex-basis: 30%;
        }

        &:nth-child(4) {
          flex-basis: 60%;
          margin-left: auto;
        }
      }
    }
    @media (max-width: 370px) {
      &__cell {
        gap: 5px;
      }
    }
    @media (max-width: 340px) {
      &__cell {
        &__icon {
          display: none;
        }
      }
    }

    @media (max-width: 768px) {
      &:last-child {
        border-bottom: 1px solid var(--borderGray);
      }
    }
  }
  [dark-theme='true'] & {
    .history__chip__gray {
      color: #fff;
    }
  }
  &__chip {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    background-color: #37cc33;
    color: var(--primary-white);

    &:hover &_info {
      opacity: 1;
      visibility: visible;
    }

    &_info {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      max-width: 230px;
      padding: 5px 10px;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.8);
      color: var(--primary-white);
      font-size: 12px;
      font-weight: normal !important;
      text-transform: none !important;
      white-space: pre-wrap;
      line-height: 14px;
      z-index: 2;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.1s ease-in-out, opacity 0.1s ease-in-out;

      @media (max-width: 768px) {
        left: 0;
        transform: translateX(-4%);
      }
    }

    &__gray {
      background: var(--gray-4);
      color: var(--white);
    }

    &__yellow {
      background-color: var(--orange);
    }

    &__red {
      background-color: var(--error);
    }

    &__blue {
      background-color: #4285f4;
      color: #fff;
    }

    &__green {
      background-color: #37cc33;
      color: #fff;
    }

    &__gold {
      background-color: #f7c21b;
      color: #fff;
    }

    @media (max-width: 1000px) {
      font-size: 1.2vw;
    }
    @media (max-width: 768px) {
      font-size: 12px;
    }
    @media (max-width: 520px) {
      font-size: 2.3vw;
    }
    @media (max-width: 370px) {
      font-size: 2vw;
    }
  }
}

[lang*='de'] {
  .history__list__item__btn_details {
    padding: 9px 5px;
  }
}
