
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: stretch;
  border: 1px solid var(--borderGray);
  position: relative;
  border-radius: 10px;
  transition: border-color 0.3s;
  max-width: max-content;
  overflow: visible;

  @include max-mobile {
    min-width: 100%;
  }

  &:hover {
    border-color: var(--orange);
    cursor: pointer;

    .title {
      border-color: var(--orange);
      color: var(--orange);
    }

    .arrow {
      color: var(--orange);
    }
  }

  &--open {
    border-color: var(--blue) !important;

    .title {
      border-color: var(--blue) !important;
      color: var(--blue) !important;
    }

    .arrow {
      color: var(--blue) !important;
      transform: rotate(180deg);
    }
  }
}

.title {
  border-right: 1px solid var(--borderGray);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 10px 0 0 10px;
  background-color: var(--lightGray);
  font-size: 14px;
  line-height: 15px;
  color: var(--blue);
  font-weight: bold;
  transition: color 0.3s, border-color 0.3s;

  svg {
    color: inherit;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    span {
      display: none;
    }
  }
}

.select {
  display: flex;
  align-items: center;
  position: relative;
  background: var(--white);
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  padding: 6px 12px 6px 15px;
  width: 100%;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: var(--black);
    line-height: 15px;
    font-weight: bold;
    white-space: nowrap;

    @media (max-width: 768px) {
      white-space: initial;
    }
  }
}

.arrow {
  color: #b3b3b3;
  margin-left: 12px;
  transition: all 0.3s;
}
.list {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  z-index: 10;
  box-shadow: 0 7px 20px rgb(0 0 0 / 20%);
  background-color: var(--white);
  border-radius: 6px;
  overflow: hidden;

  [class='rdrDateRangePickerWrapper'] * {
    font-weight: 600;
  }

  [class='rdrInputRanges'] {
    display: none;
  }

  button.list__btn {
    display: block;
    max-width: 300px;
    width: 100%;
    margin: 15px;
    margin-left: auto;

    span {
      display: flex;
      justify-content: center;
    }
  }
}

@include max-tablet {
  .container {
    max-width: none;
    width: 100%;
    margin: 0 0 10px;
  }

  .select {
    width: 100%;
    justify-content: space-between;
  }

  .list {
    & > div {
      & > div:first-child {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .list {
    left: 0;
    width: 100%;
    [class='rdrDateRangePickerWrapper'],
    [class^='rdrCalendarWrapper'],
    [class='rdrMonth'] {
      width: 100%;
    }

    button.list__btn {
      max-width: calc(100% - 30px);
      margin: 15px;
    }
  }
}

[class='rdrStaticRangeLabel'] {
  color: var(--static-dark-gray);
}

[class='rdrMonthAndYearWrapper'] {
  button {
    &:hover {
      background-color: var(--orange);
    }

    &:first-child {
      &:hover {
        i {
          border-color: transparent var(--primary-white) transparent transparent;
        }
      }
    }

    &:last-child {
      &:hover {
        i {
          border-color: transparent transparent transparent var(--primary-white);
        }
      }
    }
  }
}

[class='rdrStaticRange'] {
  &:hover span {
    background: var(--orange) !important;
    color: var(--primary-white);
  }
}
