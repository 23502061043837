
          @import "src/styles/scss/index.scss";
        
.banned {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 60vh;
  padding: 50px 20px;
}

.userBanned {
  max-width: 600px;
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background: var(--white);
  overflow: hidden;

  &__title {
    padding: 20px 30px;
    color: var(--black);
    border-bottom: 1px solid var(--borderGray);
    line-height: 40px;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 20px;
    text-align: center;

    &__title {
      margin-top: 20px;
      font-size: 26px;
      line-height: 36px;
      font-weight: 700;
    }

    &__description {
      color: var(--secondGray);
      line-height: 26px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    background: var(--lightGray);
    border-top: 1px solid var(--borderGray);

    &__btn {
      min-width: 240px;
    }
  }
}
