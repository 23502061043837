
          @import "src/styles/scss/index.scss";
        
.suspend {
  padding: 50px 0;
  min-height: 60vh;
  position: relative;

  &__choice {
    padding-block: 8px;
    padding-inline: 10px;
    border-radius: 3px;
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    color: #000;
  }

  &__decoration {
    background: var(--orange);
    right: 0;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 50%;
    width: 100%;
    height: 100vh;
    transform: translateY(-50%);

    & + div {
      position: relative;
      z-index: 1;
    }
  }

  @media (max-width: 768px) {
    &__decoration {
      top: 0;
      transform: none;
    }

    padding: 20px 0 40px;
  }
}
