
          @import "src/styles/scss/index.scss";
        
.container {
  min-height: 345px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  background-color: var(--white);
  max-width: 520px;
  display: flex;
  flex-direction: column;
  animation: fadeIn 1s;

  &:last-of-type {
    @media (max-width: 768px) {
      .share {
        &__list {
          top: calc(100% - 330px);
        }
      }
    }
  }

  &__show {
    height: auto;

    .text {
      max-height: none;
    }
  }

  @media (max-width: 768px) {
    max-width: none;
  }

  @media (max-width: 560px) {
    height: auto;
  }
}

.more {
  font-weight: bold;
  color: var(--blue);
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 8px;
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: var(--orange);
  }
}

.active {
  transition: all 0.3s;
  transform: rotate(180deg);
  transform-origin: center center;
}

.text {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: var(--blue);

    &:hover {
      color: var(--orange);
    }
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  gap: 10px;
  flex-wrap: wrap;

  button {
    height: 40px;
    max-width: none;
    font-size: 14px;
    padding: 0 10px;
    flex: 1;
    position: relative;

    &:not(.btn__text):active {
      opacity: 2;
    }
  }
}

.share {
  position: relative;

  button {
    width: 100%;
  }

  &__list {
    border-radius: 10px;
    z-index: 10;
    padding: 20px;
    position: absolute;
    background: var(--white);
    border: 1px solid var(--borderGray);
    top: calc(100% + 10px);
    width: 300px;
    left: 0;

    div[class*='social-btns'] {
      justify-content: space-between;
      gap: 20px;

      @media (max-width: 1100px) {
        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 560px) {
  .row {
    gap: 10px;
    margin-top: 20px;

    & > * {
      flex: none;
      width: 100%;
    }
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.row {
  padding: 15px;
}
.body {
  padding: 15px;
  background: var(--lightGray);
  border-bottom: 1px solid var(--borderGray);
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
