
          @import "src/styles/scss/index.scss";
        
.container {
  background-color: var(--white);
  border-radius: 6px;
  border: 1px solid var(--borderGray);
  margin-bottom: 20px;
  padding: 40px 20px 50px;
}

.text {
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin: 0 auto 40px;
  font-size: 18px;
  line-height: 1.4;
  color: var(--middleGray);
}

.img {
  display: block;
  margin: 0 auto 20px;
}

.title {
  max-width: 800px;
  text-align: center;
  margin: 0 auto 15px;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.4;
  color: var(--black);
}

.inner {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.list {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--borderGray);
  transition: all 0.3s;
  cursor: pointer;
  // min-width: 330px;

  &__img {
    display: block;
    width: 68px;
    height: 42px;
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 4px;

    &_item {
      font-size: 10px;
      line-height: 11px;
      font-weight: 700;
      border-radius: 8.5px;
      padding: 3px 6px;
      text-transform: uppercase;
      background-color: var(--borderGray);
    }
  }

  &__info {
    margin-left: 10px;
    overflow: hidden;
    width: 100%;

    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    &_id {
      font-size: 16px;
      font-weight: bold;
      color: var(--black);
    }

    &_link {
      font-size: 11px;
      color: var(--black);
    }
  }

  &:hover {
    background-color: var(--blue);
    .list__new_tab {
      color: #aacaff !important;
      svg path {
        fill: #aacaff !important;
      }
      &:hover {
        color: #172f55 !important;

        svg path {
          fill: #172f55 !important;
        }
      }
    }
    .list__info {
      &_id,
      &_link {
        color: var(--white);
      }
    }
  }
}

.input {
  display: flex;
  align-items: stretch;
  margin-top: 10px;
  margin-bottom: 30px;

  &__text {
    padding: 15px;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid var(--borderGray);
    border-right: none;
    background-color: var(--white);
    font-size: 20px;
    line-height: 23px;
    color: var(--black);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: text;
  }

  & + div {
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
}

.copy {
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 6px 6px 0px;
  color: var(--primary-white);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: var(--blue);
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: var(--orange);
  }
}

@media (max-width: 768px) {
  .input {
    flex-direction: column;

    &__text {
      border-radius: 6px 6px 0px 0px;
      border-bottom: none;
      border-right: 1px solid var(--borderGray);
      min-height: 60px;
    }
  }

  .copy {
    border-radius: 0px 0px 6px 6px;
    line-height: 60px;
  }
}

.lists {
  display: flex;
  align-items: center;

  & > div {
    display: block;
    margin-right: 10px;
  }
}

.listId {
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
  transition: color 0.3s;
  cursor: pointer;
  color: var(--black);

  &:hover {
    color: var(--blue);
  }
}

.list__new_tab {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4285f4;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
