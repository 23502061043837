
          @import "src/styles/scss/index.scss";
        
.slice {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;

  &__less {
    svg {
      transform: rotate(180deg);
    }
  }

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: var(--blue);
    margin-right: 8px;
    transition: color 0.3s;
  }

  &:hover {
    span {
      color: var(--orange);
    }
  }
}
