
          @import "src/styles/scss/index.scss";
        
.container {
  width: 50px;
  height: 30px;
  padding: 3px 4px;
  background: var(--borderGray);
  transition: background 0.3s;
  border-radius: 15px;
  flex-shrink: 0;
}

.active {
  background: var(--blue);

  .circle {
    transform: translateX(18px);
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.8;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: var(--white);
  transition: all 0.7s;
}
