
          @import "src/styles/scss/index.scss";
        
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 266px);
  p {
    letter-spacing: 0.08px;
    color: var(--darkGray);
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.text {
  margin-bottom: 52px;
  padding: 0 20px;
}
.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1100px;
  width: 100%;
  padding: 100px 0;
  .icon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}

.body {
  > * + * {
    display: block;
    margin-top: 20px;
  }
}

.heading {
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.17px;
  font-weight: 700;
  color: var(--black);
}

.status {
  color: var(--orange);
}

.btn {
  max-width: 220px;
  margin: 40px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    fill: currentColor;
  }
}
