
          @import "src/styles/scss/index.scss";
        
.searchInput {
  height: 50px;

  input {
    height: 100%;
    border: none;
    outline: none;
    flex-grow: 1;
    width: 100%;
    margin-right: 36px;
    margin-left: 10px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    color: var(--black);

    &::placeholder {
      font-weight: 400;
      color: var(--gray-4);
    }
  }
}

.searchInput {
  display: flex;
  align-items: center;
  border-radius: 10px 0 0 10px;
  position: relative;
}

.close {
  position: absolute;
  right: 10px;
  top: 17px;
  bottom: 0;
  color: var(--gray-5);
  opacity: 0;
  visibility: hidden;

  &__active {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
  }

  &:hover {
    color: var(--orange);
  }
}

.searchIcon {
  display: flex;
  align-items: center;
  background: var(--gray-8);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 50px;
  flex-shrink: 0;
  justify-content: center;
  flex-grow: 1;

  border-left: 1px solid var(--borderSearch);
  color: var(--blue);

  cursor: pointer;
}

.search {
  --borderSearch: var(--gray-6);
  border: 1px solid var(--borderSearch);
  border-radius: 10px;
  position: relative;

  &__focused {
    --borderSearch: var(--blue);
  }

  &:hover {
    --borderSearch: var(--orange);
    background-color: var(--second-orange);

    .searchIcon {
      background-color: var(--lightOrange);

      color: var(--orange);

      &:hover {
        background-color: var(--orange);
        color: var(--main-static-white);
      }
    }
  }
}

.container {
  position: relative;
  width: 220px;
}

.wrapper {
  display: flex;

  &:hover {
    .searchInput {
      background-color: var(--Orange_BG);
    }

    .searchIcon {
      background-color: #563309;
    }
  }
}

.dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 10;
  background: var(--white);
  border: 1px solid var(--gray-6);
  border-radius: 10px;
  overflow: hidden;
  color: var(--black);
  height: 250px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;

  &__item {
    padding: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    border-bottom: 1px solid var(--gray-6);

    &:hover {
      color: var(--main-static-white);
      background: var(--blue);
    }
  }
}
