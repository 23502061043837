
          @import "src/styles/scss/index.scss";
        
.tabs {
}

.graphBar {
  &:hover {
    fill: var(--orange);
  }
}

.top {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__tab {
    margin-left: auto;
  }

  & > div[class^='DatePicker'] {
    margin: 0;
  }
}

.tooltip {
  width: 200px;
  text-align: left;
  padding: 10px;
  color: var(--primary-white);
  border-radius: 6px;
  background: var(--alpha-grey-065, rgba(23, 23, 23, 0.65));
  backdrop-filter: blur(10px);
  //   display: flex;
  // align-items: center;

  &__date {
    font-size: 12px;
    color: var(--textGray);
  }

  &__head {
    font-size: 12px;
    margin: 8px 0 8px;
    font-weight: 400;
  }

  &__value {
    font-size: 20px;
    font-weight: bold;
  }
  ul {
    column-count: 2;
    color: var(--White, #fff);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    li {
      display: flex;
      &:not(&:last-child) {
        margin-bottom: 10px;
      }
    }
    .level {
      font-size: 12px;
      width: 23px;
      height: 18px;
      border-radius: 39px;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media (max-width: 1024px) {
  .top {
    padding: 10px 15px;
    padding-top: 0;
    flex-direction: column;
    gap: 10px;
  }
}
.tabs {
  @media (max-width: 1024px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .btn:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .btn:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
  .btn:nth-child(2) {
    border-radius: 0;
    margin-left: -1px;
  }
  .btn:hover {
    color: var(--orange);
    background-color: var(--second-orange);
    border-top: 1px solid var(--orange);
    z-index: 1;
  }

  @include max-mobile {
    .btn {
      span {
        visibility: hidden;
        max-width: 1.5ch;
        &::first-letter {
          visibility: visible;
        }
      }
    }
  }
}
.total_pc {
  color: var(--black);
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    font-size: 32px;
  }
}
.totalIcon {
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    width: 100%;
    font-size: 24px;
  }
}
.graphContainer {
  position: relative;
}
.shadowOverlayLeft,
.shadowOverlayRight {
  position: absolute;
  top: 0;
  bottom: 30px;
  width: 50px;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.shadowOverlayLeft {
  left: 0;
  background: linear-gradient(90deg, #fff 24.24%, rgba(255, 255, 255, 0) 87.9%);
}
[dark-theme='true'] {
  .shadowOverlayLeft {
    background: linear-gradient(90deg, #25272a 34.54%, rgba(37, 39, 42, 0) 87.9%);
  }
  .shadowOverlayRight {
    background: linear-gradient(270deg, #25272a 34.54%, rgba(37, 39, 42, 0) 87.9%);
  }
}
.shadowOverlayRight {
  right: 0;
  background: linear-gradient(270deg, #fff 24.24%, rgba(255, 255, 255, 0) 87.9%);
}
.visible {
  opacity: 1;
}
.scrollable {
  overflow-x: visible;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable::-webkit-scrollbar {
  display: none;
}
.scrollable {
  @media (max-width: 1024px) {
    :global(.recharts-wrapper) {
      position: static !important;
    }
    :global(.recharts-tooltip-wrapper) {
      // position: fixed !important;
      top: 0px !important;
      transform: translate(-50%, 0) !important;
      left: 50% !important;
    }
  }
}
