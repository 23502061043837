
          @import "src/styles/scss/index.scss";
        
.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  [data-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--borderGray);
    background-color: var(--white);
    font-size: 14px;
    color: var(--black);
    line-height: 16px;
    border-radius: 6px;
    transition: all 0.3s;
    cursor: pointer;

    &__active {
      border-color: var(--blue);
      background-color: var(--blue);
      color: var(--primary-white) !important;
    }
  }

  &:not(.modile) {
    .pagination__item {
      &:hover {
        color: var(--blue);
        border-color: var(--blue);
      }
    }
  }
}
