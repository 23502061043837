
          @import "src/styles/scss/index.scss";
        
.affiliate__feature {
  display: flex;
  justify-content: center;
  padding: 20px;
  position: relative;
  flex: 1;
  color: var(--gray-2);

  @media (max-width: 768px) {
    padding: 35px 20px;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--borderGray);
  }

  &_body {
    text-align: center;
  }

  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  &_title {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    color: var(--black);
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    &:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid var(--borderGray);
    }
  }
}
