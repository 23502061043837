
          @import "src/styles/scss/index.scss";
        
.random {
  margin-bottom: 60px;

  &__row {
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 40px) / 3));
    gap: 20px;
  }

  &__btn {
    width: 100%;
    max-width: 300px;
    margin: 30px auto 0;
    display: flex !important;
  }
}

.label {
  text-align: center;
  margin-bottom: 30px;
  color: var(--orange);
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, calc((100% - 40px) / 3));
  gap: 20px;
}

@media (max-width: 1100px) {
  .container {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
