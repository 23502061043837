
          @import "src/styles/scss/index.scss";
        
.arrow {
  transform-origin: center center;
  color: var(--blue);
  margin-left: auto;
  transition: all 0.3s;
}

.top {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;

  svg:first-child {
    color: var(--middleGray);
    transition: color 0.3s;
  }
}

.container {
  background: var(--white);
  border-radius: 10px;

  &__active {
    .body {
      grid-template-rows: 1fr;
    }
    .arrow {
      transform: rotate(-180deg);
    }
  }
  &:not(&__active) .top:hover {
    border-radius: 10px;
  }
}

.body {
  display: grid;
  grid-template-rows: 0fr;
  // transition: grid-template-rows 0.5s;
  transition: all 0.3s;

  & > div {
    overflow: hidden;
  }
}

.title {
  font-size: 14px;
  font-style: normal;
  transition: color 0.3s;
  font-weight: 700;
  line-height: 24px;
  color: var(--black);
  text-transform: uppercase;
}

.top:hover {
  background: var(--lightGray);
  border-radius: 10px 10px 0 0;

  + .body {
    transition: all 0.3s;
    background: var(--lightGray);
    border-radius: 0 0 10px 10px;
  }

  .title,
  svg {
    color: var(--orange);
  }
}
