
          @import "src/styles/scss/index.scss";
        
.noData {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.091px;

  @media (max-width: 768px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }

  img {
    margin-bottom: 20px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -30px;
  }
}
