
          @import "src/styles/scss/index.scss";
        
.container > * + * {
  margin-top: 30px;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.section {
  padding: 25px;
  border-radius: 6px;
  border: 1px solid var(--borderGray);

  > * + * {
    margin-top: 5px;
  }
}
