
          @import "src/styles/scss/index.scss";
        
.calcSlider {
  display: flex;
  align-items: flex-end;

  & > *::selection {
    background-color: transparent !important;
  }

  &__slider_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 500px;

    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }

  &__label {
    max-width: 380px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 20px;
    color: var(--black);
    min-height: 40px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 640px) {
      max-width: 100%;
      flex: 1;
      margin-bottom: 0;
      margin-right: 10px;
      font-weight: 700;

      font-size: 16px;
      font-style: normal;
      line-height: 20px;
    }
  }

  &__tooltip {
    font-size: 14px;
    white-space: wrap;
    width: 300px;
    text-align: left;
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 70px;
    color: var(--black);
    border-radius: 10px;
    border: 1px solid var(--borderGray);
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    transform: translateY(13px);

    @media screen and (max-width: 640px) {
      text-align: center;

      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      width: 50px;
      height: 40px;
      transform: translateY(0);
    }
  }

  &__slider {
    width: 380px;
    margin-top: -7px;

    @media screen and (max-width: 640px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    width: 100%;
  }
}
