
          @import "src/styles/scss/index.scss";
        
.copy {
  background-color: var(--blue);
  border-radius: 6px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: var(--orange);
  }

  &__check {
    color: var(--success);

    svg path {
      fill: var(--success) !important;
    }
  }

  &__no {
    background-color: transparent;
    display: inline-block;
    min-width: 16px;
    &:hover {
      background: transparent;
    }

    svg path {
      fill: var(--blue);
    }
  }
}

.container {
  background: var(--lightGray);
  border: 1px solid var(--borderGray);
  border-radius: 10px;
  width: 100%;
  margin: 0 auto;
}

.tabs {
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 5px;
}

.tab {
  padding: 4px 10px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: var(--white);
  background: var(--textGray);
  transition: background 0.3s;
  cursor: pointer;

  &__active,
  &:hover {
    background: var(--blue);
  }
}

.input {
  padding: 20px;
  border-bottom: 1px solid var(--borderGray);
  color: var(--black);
  position: relative;

  &__divider {
    position: absolute;
    left: 50%;
    bottom: -8px;
    color: var(--middleGray);
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    text-transform: uppercase;
    border: 1px solid var(--borderGray);
    border-radius: 9px;
    padding: 2px 5px 1px 4px;
    transform: translateX(-50%);
    background: var(--white);
  }

  &__label {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: left;
    color: var(--black);
  }

  &__row {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  &__area {
    width: 100%;
    margin-right: 10px;
    border: 1px solid var(--borderGray);
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    padding: 0 20px;
    border-radius: 6px;
    background: var(--white);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: text;
  }
}

.input:last-child {
  border-bottom: none;
}

.info {
  margin: 20px;
  background: var(--white);
  border: 1px solid var(--borderGray);
  border-radius: 6px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    padding: 15px;
    border-bottom: 1px solid var(--borderGray);

    &_nft {
      border: none;
    }
  }

  &__bottom {
    display: flex;
    align-items: stretch;

    &_one {
      padding: 15px;
      align-items: center;
      justify-content: space-between;
    }

    &_item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      &:first-child {
        border-right: 1px solid var(--borderGray);
      }
    }
  }

  &__text {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: var(--orange);
  }

  &__balance {
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 10px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

@media (max-width: 768px) {
  .dash {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .input {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      'top'
      'middle'
      'bottom';
    &__label {
      grid-area: top;
      margin-bottom: 10px;
    }

    .tabs {
      grid-area: middle;
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__row {
      grid-area: bottom;
    }
  }
}

@media (max-width: 768px) {
  .input {
    padding: 15px;
  }

  .info {
    margin: 15px;

    &__text {
      font-size: 14px;
      line-height: 16px;
    }

    &__bottom_item {
      flex-direction: column;
      gap: 10px;
    }
  }
}
