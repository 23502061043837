
          @import "src/styles/scss/index.scss";
        
.item {
  &__all {
    padding: 20px 10px;
    border-bottom: 1px solid var(--borderGray);
    font-size: 16px;
    cursor: pointer;
    color: var(--black);
    overflow: hidden;
    &:hover {
      background: var(--orange);
      color: var(--white);
      border-radius: 10px 10px 0 0;
    }
  }

  padding: 10px 20px;
  color: var(--darkGray);
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  span b {
    font-weight: bold;
  }

  span:last-child {
    font-size: 12px;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    line-height: 14px;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  &:hover {
    background: var(--orange);
    color: var(--white);
  }

  &__id {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: var(--black);
    font-size: 14px;

    b {
      font-weight: bold;
      margin-left: 5px;
    }
  }
}

@media (max-width: 1024px) {
  .item {
    &__id {
      padding: 0px 15px 15px;
      justify-content: flex-start;
    }
  }
}
