
          @import "src/styles/scss/index.scss";
        
.list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  @media (min-width: 1200px) {
    margin-top: 40px;
    gap: 20px;
  }
}
.item {
  width: 100%;
  @media (min-width: 768px) {
    width: calc(100% / 2 - 15px);
  }
  @media (min-width: 1200px) {
    width: calc(100% / 3 - 20px);
  }
}
