
          @import "src/styles/scss/index.scss";
        
.card {
  background-color: var(--white);
  max-width: 600px;
  width: 100%;
  margin-bottom: 40px;
  border-radius: 6px;
  border: 1px solid var(--borderGray);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.body {
  padding: 20px 30px 40px;
}

.title {
  font-size: 32px;
  line-height: 24px;
  color: var(--black);
  font-weight: 700;
  padding: 25px 30px 20px;
  border-bottom: 1px solid var(--borderGray);
}

.text {
  font-size: 14px;
  line-height: 22px;
  color: var(--darkGray);
  margin-bottom: 30px;
}

.label {
  margin-bottom: 12px;
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--black);
}

.group {
  margin-bottom: 25px;
  input {
    display: block;
    width: 100%;
    background: transparent;
    color: var(--black);
    outline: none;
    padding: 15px;
    border: 1px solid var(--borderGray);
    border-radius: 6px;
    font-size: 16px;

    &::placeholder {
      color: var(--textGray4);
    }
  }

  &__subtitle {
    display: block;
    font-size: 12px;
    line-height: 14px;
    margin-top: 12px;
    color: var(--middleGray);
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: var(--lightGray);
  border-top: 1px solid var(--borderGray);
  border-radius: 0px 0px 6px 6px;

  > * {
    max-width: 300px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: none;
    }
  }
}

.back {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--white);
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--blue);
  }
}

.btn {
  max-width: 120px;
  display: flex;
  width: 100%;
  padding-block: 10px;
}

@media (max-width: 768px) {
  .card {
    max-width: none;
    border-radius: 0;
    border: none;
    margin: -20px -10px;
    width: 100vw;
  }

  .title {
    font-size: 18px;
    padding: 20px 15px;
  }

  .text {
    font-size: 14px;
    line-height: 25px;
  }

  .body {
    padding: 20px 15px 50px;
  }
}
