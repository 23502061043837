
          @import "src/styles/scss/index.scss";
        
.tabsWrapper {
  --padding: 15px;
  position: fixed;
  bottom: -10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  z-index: 5;
  width: calc(100% - var(--padding) * 2);
}

.fadeInUp {
  animation: fadeInUp 0.3s ease backwards;
}

.fadeOutDown {
  animation: fadeOutDown 0.3s ease;
}

@keyframes fadeInUp {
  0% {
    transform: translate(0px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translate(0px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 100px);
  }
}
