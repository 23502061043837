
          @import "src/styles/scss/index.scss";
        
.img {
  display: block;
  border-radius: 10px 10px 0 0;
  max-width: 100%;
  width: 100%;

  &__link {
    display: block;
    text-decoration: none;
  }
}

.imageWrapper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.body {
  background: var(--white);
  padding: 20px 30px 30px;
}

.jackbots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -208px;
  z-index: -1;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.title {
  font-size: 28px;
  line-height: 38px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  color: var(--black);
  transition: color 0.3s;

  &:hover {
    color: var(--blue);
  }
}

.text {
  font-size: 16px;
  line-height: 26px;
  color: var(--darkGray);
  margin-bottom: 40px;

  &__wall {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: var(--blue);
    transition: color 0.3s;

    &:hover {
      color: var(--orange);
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  border-radius: 0 0 10px 10px;
  justify-content: space-between;

  &__wall {
    justify-content: center;
    padding: 20px;
    background-color: var(--lightGray);
    border-top: 1px solid var(--borderGray);
  }
}

.create {
  display: flex;
  max-width: 320px;
  width: 100%;
  max-height: 60px;
  padding-block: 20px;

  &__jackbot {
    path {
      fill: var(--primary-white);
    }
  }
}

.cancel {
  border: none;
  outline: none;
  background: transparent;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--darkGray);
}

.check {
  margin: 20px auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &_input {
    width: 14px;
    height: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: 1px solid var(--textGray);
    border-radius: 3px;
    transition: all 0.3s;

    svg {
      transition: all 0.3s;
      opacity: 0;
    }
  }

  input {
    display: none;

    &:checked + div {
      background: var(--blue);
      border-color: var(--blue);

      svg {
        opacity: 1;
      }
    }
  }

  span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    color: var(--textGray);
  }
}

@media (max-width: 768px) {
  .cancel {
    display: none;
  }

  .create {
    max-width: none;
  }

  .body {
    padding: 20px 15px 15px;
  }

  .footer {
    padding: 15px;
    gap: 15px;
    background: var(--static-transparent-bg);
  }

  .title {
    font-size: 24px;
    line-height: 32px;
  }

  .text {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;

    &__wall {
      margin-bottom: 0;
    }
  }

  .close {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .jackbots {
    display: none;
  }
}
