
          @import "src/styles/scss/index.scss";
        
.card {
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    radial-gradient(85.11% 60.87% at 47.45% 39.12%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.08) 91.04%);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.nftIntro {
  position: relative;
  text-align: center;
  overflow: hidden;

  &_black {
    background: linear-gradient(180deg, #000000 0%, #494949 100%);

    .nftIntro {
      &__title {
        color: var(--primary-white);
      }

      &__description {
        color: var(--textGray);
      }
    }
  }
}

.root {
  padding: 15px 20px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100%;
}

.swiper {
  margin: 0 -20px;
  padding: 65px 20px;
  flex: 1 0 auto;
  display: flex;

  &__wrapper {
    width: 100%;
    height: auto;
  }

  &__slide {
    height: 1px;
    padding: 70px 50px;
    min-height: 400px;

    @media (max-width: 640px) {
      min-height: auto;
      padding: 60px 15px 60px;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    height: min-content;
    left: 50% !important;
    transform: translateX(-50%) !important;
    max-width: 230px;
    position: absolute;
    width: 100%;
    gap: 10px;
    bottom: 27px !important;

    @media (max-width: 640px) {
      bottom: 0 !important;
      top: auto !important;
      position: relative !important;
      gap: 20px;
      height: 40px;
      align-items: center;
    }
  }
  &__bullet {
    flex-grow: 1;
    position: relative;
    text-align: left;
    background: transparent;
    border-radius: 4px !important;
    height: 8px !important;
    opacity: 1 !important;
    max-width: 30px;
    flex-shrink: 0;
    width: 30px !important;
    background: none !important;
    margin: 0 !important;

    @media (max-width: 640px) {
      max-width: 10px !important;
      width: 10px !important;
      height: 10px !important;
    }

    i {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 8px;
      background-color: var(--primary-white);
      opacity: 0.5;
      border-radius: 4px;

      @media (prefers-color-scheme: dark) {
        background-color: var(--primary-white);
        opacity: 0.5;
      }

      @media (max-width: 640px) {
        max-width: 10px !important;
        width: 10px !important;
        height: 10px !important;
      }
    }

    b {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 0;
      height: 8px;
      background-color: var(--primary-white);
      opacity: 1;
      border-radius: 4px;

      @media (max-width: 640px) {
        display: none;
      }
    }
  }
  &__bullet_active {
    background-color: transparent;
    max-width: 60px !important;
    width: 100% !important;
    flex-grow: 1;

    @media (max-width: 640px) {
      max-width: 10px !important;
      width: 10px !important;
      height: 10px !important;
    }

    i {
      width: 100%;

      @media (max-width: 640px) {
        background-color: var(--orange) !important;
        width: 100%;
        opacity: 1;
      }
    }

    b {
      animation-name: countingBar;
      animation-duration: 10s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-direction: alternate;
      animation-fill-mode: forwards;
      width: 100%;
    }
  }
}

.button__wrapper {
  padding: 15px;
  background: rgba(29, 29, 29, 0.7);
  border-radius: 25px 25px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 145px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 3;
}

.arrow {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@keyframes countingBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
