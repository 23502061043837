
          @import "src/styles/scss/index.scss";
        
.parallax {
  max-height: 100vh;
  img[class*='__particle_5'] {
    top: 400px;
    left: 237px;
    bottom: auto;
    right: auto;
  }
  img[class*='__particle_6'] {
    top: 380px;
    right: 80px;
    left: auto;
    bottom: auto;
  }
  img[class*='__particle_7'] {
    top: 280px;
    right: -50px;
    left: auto;
    bottom: auto;
  }
  img[class*='__particle_8'] {
    top: 380px;
    left: -76px;
    bottom: auto;
    right: auto;
  }
}

.card {
  overflow: hidden;
  position: relative;
  padding: 40px;
  &__mobile {
    border-radius: 10px 10px 0 0;
    box-shadow: none;
    margin-bottom: 0;
  }
}
.cardWrapper {
  position: relative;
  box-shadow: var(--card-shadow);
  border-radius: 10px;
  margin-bottom: 20px;
}
.sliderControls {
  background: var(--alpha-grey-065);
  border-radius: 0 0 10px 10px;
  padding: 15px;
  display: flex;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.slider {
  padding: 1px !important;
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 1120px;
  width: 100%;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.container {
  overflow: hidden;
  background-color: var(--white);
  max-width: 100vw;
  position: relative;
}

.cards {
  max-width: 1120px;
  gap: 20px;
  margin: 50px auto 0;
  position: relative;

  .slider {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 11;
  }

  &__decoration {
    position: absolute;
    bottom: 207px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  & > div {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 900px) {
    margin-bottom: 30px;
  }
}

.tabs {
  margin: 40px auto;
}

.products {
  position: relative;
  z-index: 10;
  gap: 20px;
  display: flex;
  max-width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
}

.features {
  margin: 15px 0 0;
  max-width: 1120px;
  background: var(--lightGray);
  position: relative;
  z-index: 9;
  border-radius: 6px;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    left: -1px;
    bottom: -1px;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid var(--borderGray);
    border-radius: 6px;
  }

  & > div {
    border: none;
    width: 100%;
    justify-content: center;
    padding: 20px 16px;
    text-align: center;

    & > div {
      padding: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid var(--borderGray);
    }

    &:after,
    &:before {
      display: none;
    }

    @media (max-width: 900px) {
      margin: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  }
}

.scroll {
  display: block;
  margin: 0 auto 30px;
}

@media (max-width: 900px) {
  .tabs {
    max-width: none;
    margin: 20px 0;
  }
  .scroll {
    display: none;
  }
  .card {
    padding: 20px;
  }
  .features {
    & > div {
      max-width: none;
      width: 100%;
      &:not(:last-child) {
        border-bottom: 1px solid var(--borderGray);
        border-right: none;
      }
    }
  }
}
