
          @import "src/styles/scss/index.scss";
        
.container {
  padding: 20px 15px 0;
  border-bottom: none;
}

.inner {
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
}
