
          @import "src/styles/scss/index.scss";
        
.image {
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  //   padding-bottom: 73.53%;
  background-color: var(--gray-8);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
  &:hover {
    .before {
      opacity: 1;
    }
  }
}
.before {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 0.3s;
  svg {
    margin-right: 8px;
  }
}
