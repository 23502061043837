
          @import "src/styles/scss/index.scss";
        
.card {
  max-width: 600px;
  width: 100%;
  background-color: 'transparent';
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.body {
  padding: 50px 30px;
  background-color: var(--white);
  border-radius: 10px 10px 0 0;
}

.footer {
  padding: 20px 30px;
  border-top: 1px solid var(--borderGray);
  background: var(--alpha-grey-065);
  border-radius: 0px 0px 6px 6px;
}

.decoration {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 300px;
  background: var(--orange);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  padding: 50px 20px;
  min-height: 60vh;
}

.title {
  margin: 20px 0px 15px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  color: var(--black);
}

.text {
  font-size: 15px;
  line-height: 24px;
  color: var(--darkGray);
}

.back_btn {
  max-width: 300px;
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .decoration {
    top: 0px;
    transform: none;
  }

  .body {
    padding: 40px 20px 50px;
  }

  .footer {
    padding: 20px;
  }

  .back_btn {
    max-width: none;
  }

  .container {
    padding: 20px;
  }
}
