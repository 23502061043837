
          @import "src/styles/scss/index.scss";
        
.item {
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.top {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  border-bottom: 1px solid var(--borderGray);
  a {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 28px;
    color: var(--black);
    text-decoration: none;
    font-weight: 700;
    span {
      display: flex;
      margin-right: 10px;
    }
    .copy {
      color: var(--blue);
      margin-left: auto;
    }
    &:hover {
      color: var(--orange);
      .copy {
        color: var(--orange);
      }
    }
  }
}
.centerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.center {
  padding: 10px 15px 0 15px;
  font-size: 16px;
  line-height: 26px;
  color: var(--darkGray);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 24px;
  }
  img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    margin-top: 15px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    color: var(--darkGray);
    margin-bottom: 15px;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
.badge {
  border: 1px solid var(--borderColor);
  background: var(--bgColor);
  display: flex;
  border-radius: 10px;
  min-height: 40px;
  overflow: hidden;
  &__img {
    width: 44px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--borderColor);
    min-height: 100%;
    img {
      margin: 0;
    }
  }
  span {
    padding: 10px 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
  }
  &.orange {
    --borderColor: #f7931a;
    --bgColor: #fff5e9;
  }
  &.violet {
    --borderColor: #a033cc;
    --bgColor: #fbf2ff;
  }
  &.green {
    --borderColor: #37cc33;
    --bgColor: #ecffec;
  }
}
[dark-theme='true'] {
  .badge {
    color: var(--white);
  }
}
.bottom {
  display: flex;

  gap: 10px;
  border-top: 1px solid var(--borderGray);
  background: var(--lightGray);
  padding: 15px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: unset;
  }
  a {
    font-size: 16px;
    font-weight: 700;
    justify-content: center;
    line-height: 26px;
  }
  .link {
    padding: 15px 8px;
    font-size: 14px;
    line-height: 16px;
    color: var(--white);
    border-radius: 10px;
    display: flex;
    background: var(--black, #000);
    flex-grow: 1;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    min-width: calc(50% - 5px);
    text-decoration: none;
    &:hover {
      background: #f7931a;
      &::before {
        background: var(--primary-white);
      }
    }

    &.web {
      &::before {
        content: '';
        mask-image: url('../../../../../assets/colored/web.svg');
        -webkit-mask-image: url('../../../../../assets/colored/web.svg');
        mask-size: contain;
        -webkit-mask-size: contain;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;
        background: var(--blue);
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      &:hover {
        &::before {
          background: var(--primary-white);
        }
      }
    }
  }
  img {
    margin-right: 10px;
  }
}

:global(.android) {
  @media (max-width: 768px) {
    .ios {
      display: none;
    }

    .android + .web {
      display: none;
    }
  }
}
.androidForIos {
  display: none;
}
:global(.ios) {
  @media (max-width: 768px) {
    .android {
      display: none;
    }
    .ios + .web {
      display: none;
    }
    .emptyIosFallback {
      .androidForIos {
        display: flex;
      }
    }
  }
}
