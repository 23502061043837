
          @import "src/styles/scss/index.scss";
        
.body__img {
  position: relative;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  min-width: calc(100% + 40px);
  margin-top: 20px;
  border-radius: 0 0 10px 10px;
}

.form {
  padding: 20px;
  background-color: var(--lightGray);
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;

  ul {
    max-height: 200px;
  }
}

.select {
  position: relative;
  max-width: 190px;
  min-width: 90px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &__lang {
    max-width: 190px;
    min-width: 90px;
  }

  &__label {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    color: var(--black);
  }

  &__icon {
    position: absolute;
    right: 15px;
    bottom: 20px;
    color: var(--textGray);
  }

  &__list {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background: var(--white);
    color: var(--black);
    width: 100%;
    appearance: none;
    padding: 15px 25px 15px 15px;
    border-radius: 10px;
    border: 1px solid var(--borderGray);
    transition: all 0.3s;
  }

  &:hover {
    .select__list {
      border-color: var(--orange);
      background: var(--second-orange);
    }

    .select__icon {
      color: var(--orange);
    }
  }
}

.submit {
  text-align: center;
  border-radius: 10px;
  color: var(--primary-white);
  width: 100%;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: bold;
  background-color: var(--blue);
  font-size: 14px;
  transition: background-color 0.3s;
  height: 50px;

  &:hover {
    background-color: var(--orange);
  }

  &:active {
    background: var(--lightGray);
    border-color: var(--lightGray);
  }
}

.item {
  &__bottom {
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    button {
      width: 100%;
    }
  }

  &__top {
    background-color: var(--gray-8);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-bottom: 73.53%;
  }
}

.results {
  padding: 20px 0;
  row-gap: 20px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include max-mobile {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 768px) {
  .form {
    padding: 30px 20px;
    max-width: none;
    flex-direction: column;
    align-items: center;
  }

  .select,
  .submit {
    width: 100%;
    max-width: 360px;
  }
}

.link {
  padding: 15px 10px;
  display: grid;
  gap: 15px;
  grid-template-columns: 114px auto;
  font-size: 16px;
  font-weight: 700;
  color: var(--black);
  display: flex;
}

div.modal {
  background: transparent;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
  padding: 80px 20px;

  @include max-mobile {
    padding: 40px;
  }
}

.empty__text {
  font-size: 24px;
  color: var(--gray-4);
  margin-top: 10px;
  text-align: center;
}
