
          @import "src/styles/scss/index.scss";
        
.root {
  position: relative;
  padding: 15px 45px 15px 15px;
  border-radius: 10px;
  border: 1px solid var(--borderGray);
  background: var(--white);
  color: var(--black);
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--orange);
    background-color: var(--Orange_BG);

    .arrow {
      color: var(--orange);
    }
  }
}

.arrow {
  content: '';
  position: absolute;
  right: 15px;
  top: 50%;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  color: var(--gray-5);
  transition: transform 150ms linear;
}

.root.disabled {
  pointer-events: none;
  filter: grayscale(1);
}

.root.active {
  border: 1px solid var(--blue);
  background: var(--second-orange);

  .arrow {
    color: var(--gray-5);
  }
}

.root.noChoices .arrow {
  display: none;
}

.root.active .arrow {
  transform: translateY(-50%) rotate(180deg);
}

.label {
  position: absolute;
  left: 10px;
  top: -12px;
  padding: 4px 8px;
  z-index: 2;

  border-radius: 20px;
  border: 1px solid var(--borderGray);
  background: var(--white);
  color: var(--middleGray);
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
}

.root.active .label {
  border: 1px solid var(--orange);
}

.select {
  display: none;
}

.choices {
  display: none;
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  z-index: 1000;

  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
  background: var(--white);
  color: var(--middleGray);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}

.opened {
  display: block;
}

.up {
  top: auto;
  bottom: 100%;
}

.choice {
  cursor: pointer;
}

.choice:not(:last-child) {
  border-bottom: 1px solid var(--borderGray);
}

.choice:hover,
.currentChoice,
.focusedChoice {
  background-color: var(--blue);
  & * {
    color: var(--main-static-white);
  }
}

.disabled {
  pointer-events: none;
}
.current {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  svg {
    width: 28px;
    height: auto;
  }
}
