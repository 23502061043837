
          @import "src/styles/scss/index.scss";
        
.steps {
  margin-top: 50px;
  display: flex;
  gap: 10px;
  align-items: stretch;

  @media screen and (max-width: 820px) {
    flex-wrap: wrap;
    //max-width: 465px;
    margin-left: auto;
    margin-right: auto;
    gap: 15px;
    flex-direction: column;
    max-width: 100%;
  }

  &__arrow {
    flex-shrink: 0;
    align-self: center;
    stroke: var(--textGray);

    @media screen and (max-width: 820px) {
      display: block;
      transform: rotate(90deg);
      margin-bottom: 10px;
      stroke: var(--blue);
    }
  }

  > &__step + &__step {
    @media screen and (max-width: 768px) {
      padding-top: 40px;
    }
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  padding: 30px 15px 20px;
  border: 1px solid var(--borderGray);
  counter-increment: steps;
  border-radius: 10px;
  color: var(--white);

  &__title {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: var(--black);
    text-align: center;

    a {
      text-decoration: none;
      color: var(--blue);
      transition: color 0.3s;

      &:hover {
        color: var(--orange);
      }
    }
  }

  &__dark {
    .step__title,
    &::before {
      color: var(--primary-white);
    }
  }
  &::before {
    content: counter(steps);
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid var(--orange);
    font-weight: 700;
    font-size: 17px;
    color: var(--orange) !important;
    background-color: var(--black);
  }
}
