
          @import "src/styles/scss/index.scss";
        
.card {
  padding: 40px 0 0 0;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 30px 0 0 0;
  }
}

.farmAboutCode {
  text-align: center;
  overflow: hidden;
  background: var(--white);

  &__steps {
    padding: 0 40px;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    width: 100%;
    position: relative;
    color: var(--black);
    max-width: 1140px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 20px;

    @media (max-width: 1140px) {
      max-width: 100%;
      padding: 0;
    }

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }
  }

  &__description {
    margin-top: 20px;
    color: var(--darkGray);
    padding: 0 20px;
    font-size: 18px;
    line-height: 28px;

    a {
      color: var(--blue);
      text-decoration: none;
      font-weight: bold;
      transition: color 0.3s;

      &:hover {
        color: var(--orange);
      }
    }

    b {
      font-weight: bold;
    }

    @media screen and (max-width: 768px) {
      margin-top: 15px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 0;
    }
  }

  &__btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    @media (max-width: 768px) {
      div[class*='arrow'] {
        display: none;
      }

      .farmAboutCode__btn {
        min-width: auto;
        width: 100%;
        margin: 0;
      }
    }
  }

  &__btn {
    min-width: 320px;
    margin: 0 40px;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
  }

  &__section_image {
    img {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      width: auto;
      margin: 0 -20px;
    }
  }
}

.icon {
  width: 90px;
  height: 90px;
  margin: 0 auto 20px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
    margin: 0 auto 40px;
  }
}

.xsVariant {
  padding: 30px 0;
  .farmAboutCode {
    &__title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 15px;
      color: var(--black);
    }

    &__description {
      font-size: 18px;
      color: var(--darkGray);
      line-height: 28px;
    }
  }

  .icon {
    width: 70px;
    height: 70px;
  }
}
