
          @import "src/styles/scss/index.scss";
        
.textArea {
  background-color: var(--white);
  border: 1px solid var(--borderGray);
  color: var(--black);
  font-family: inherit;
  padding: 15px;
  resize: none;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  width: 100%;
}
.label {
  background: var(--white);
  border: 1px solid var(--borderGray);
  border-radius: 20px;
  color: var(--middleGray);
  font-size: 11px;
  font-weight: 700;
  left: 10px;
  padding: 4px 8px;
  position: absolute;
  text-transform: uppercase;
  top: -12px;
  z-index: 2;
}
.copy {
  position: absolute;
  right: 22px;
  bottom: 10px;
}
