
          @import "src/styles/scss/index.scss";
        
.farmCodeResult {
  padding: 20px;
  text-align: center;
  @include max-tablet {
    padding: 15px;
  }
  &__title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;

    @media screen and (max-width: 768px) {
      font-size: 36px;
      line-height: 41px;
    }
  }

  &__description {
    margin-top: 20px;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
    color: var(--darkGray);

    b {
      font-weight: 700;
    }

    a {
      font-weight: 700;
      text-decoration: none;
      color: var(--blue);
    }
  }

  &__cards {
    margin-top: 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__btn {
    margin-top: 30px;
  }
}
